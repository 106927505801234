import { Icon } from "@iconify/react";
import { Button, DatePicker, Dropdown, Input, Menu, Popconfirm } from "antd";
import i18n from "util/base/i18n";
import { ITopButtons } from "./ITopButtons";
import { CaretDownFilled } from "@ant-design/icons";
import { MultipleSearch } from "components/input/search/MultipleSearch";
import { ImportExportMenu } from "components/importExportMenu/ImportExportMenu";
import { usePermissionContext } from "context/PermissionContext";
import { useState } from "react";
import { ModalFilter } from "components/modalFilter/ModalFilter";
import { useModalFilterContext } from "context/ModalFilterContext";
import FilterSelected from "./components/FilterSelected";
import Captcha from "components/captcha/Captcha";

const { Search } = Input;

export function TopButtons({
	mainButtonIcon = <Icon icon="akar-icons:circle-plus-fill" />,
	mainButtonTitle,
	secondaryButton,
	handleNew,
	handleDelete,
	handleEdit,
	handleSearch,
	handleDeactivate,
	handleSuccessImport,
	isEditable,
	isDeletable,
	isDeactivatable,
	customButtons,
	searchPlaceholder,
	disabledMainButton,
	mainButtonStyles,
	mainButtonDropDown,
	hasCaptcha = false,
	multipleSearch,
	importExportOptions,
	moduleType,
	hasFilter = false,
	handleFilter,
	onCleanFilters,
    containerStyles,
    leftContentStyles,
}: ITopButtons) {
	const { functionalityPermissions } = usePermissionContext()
	const { setIsOpen, cleanFilters, filtersSelected } = useModalFilterContext();
	const [openDatePicker, setOpenDatePicker] = useState<boolean>(false);
	const mainButtonDropDownItems = (
		<Menu>
			{mainButtonDropDown?.map((item, index) => (
				<Menu.Item key={index} onClick={item.onClick}>
					{item.title}
				</Menu.Item>
			))}
		</Menu>
	);

	return (
        <>
            <div className="gs-top-buttons" style={containerStyles}>
                <div className="top-buttons-left-content" style={leftContentStyles}>
                    {mainButtonDropDown?.length > 0 &&
                    (functionalityPermissions.new || functionalityPermissions.undochanges) ? (
                        <>
                            <Button
                                icon={mainButtonIcon}
                                onClick={handleNew}
                                className="gs-main-button main-button-dropdown"
                                disabled={disabledMainButton}
                            >
                                {mainButtonTitle}
                            </Button>
                            <Dropdown
                                className="gs-main-button main-dropdown"
                                overlay={mainButtonDropDownItems}
                                trigger={["click"]}
                                placement="bottomLeft"
                            >
                                <Button icon={<CaretDownFilled />}></Button>
                            </Dropdown>
                        </>
                    ) : functionalityPermissions.new || functionalityPermissions.undochanges ? (
                        <Button
                            icon={mainButtonIcon}
                            onClick={handleNew}
                            className="gs-main-button"
                            disabled={disabledMainButton}
                            style={mainButtonStyles ? mainButtonStyles : null}
                        >
                            {mainButtonTitle}
                        </Button>
                    ) : null}
                    {secondaryButton && (
                        <Button
                            className={`gs-secondary-button ${secondaryButton.className}`}
                            style={secondaryButton.style}
                            icon={secondaryButton.icon}
                            onClick={secondaryButton.onClick}
                            disabled={secondaryButton.disabled}
                            title={secondaryButton.disabled ? secondaryButton.toolTip : ""}
                        >
                            {secondaryButton.title}
                        </Button>
                    )}
                    {handleEdit && functionalityPermissions.edit ? (
                        <Button
                            className="top-tool-buttons edit"
                            onClick={handleEdit}
                            icon={<Icon icon="icomoon-free:pencil" />}
                            disabled={!isEditable}
                            title={i18n.t<string>("edit")}
                        />
                    ) : null}
                    {handleDelete &&
                        functionalityPermissions.remove &&
                        (hasCaptcha ? (
                            <>
                                <Captcha
                                    tooltipTitleI18n={i18n.t<string>("delete_confirm_message")}
                                    disabledButton={!isDeletable}
                                    onSuccessfulSubmit={handleDelete}
                                    icon={<Icon icon="icomoon-free:bin" />}
                                    hasIcon={true}
                                />
                            </>
                        ) : isDeletable ? (
                            <Popconfirm
                                placement="bottom"
                                overlayClassName="popconfirm-delete"
                                title={i18n.t<string>("delete_confirm_message")}
                                onConfirm={handleDelete}
                                disabled={!isDeletable}
                                okText={i18n.t<string>("yes")}
                                cancelText={i18n.t<string>("cancel")}
                                okButtonProps={{ danger: true, className: "popconfirm-delete-button" }}
                            >
                                <Button
                                    disabled={!isDeletable}
                                    className="top-tool-buttons trash"
                                    icon={<Icon icon="icomoon-free:bin" />}
                                />
                            </Popconfirm>
                        ) : (
                            <Button
                                disabled={!isDeletable}
                                className="top-tool-buttons trash"
                                icon={<Icon icon="icomoon-free:bin" />}
                            />
                        ))}
                    {hasFilter && (
                        <>
                            <ModalFilter module={moduleType} onApply={handleFilter} filterSelected={filtersSelected} />
                            <Button
                                className="top-tool-buttons"
                                onClick={() => setIsOpen(true)}
                                icon={<Icon icon="fa6-solid:filter" />}
                            />
                        </>
                    )}
                    {handleDeactivate && (
                        <Button
                            disabled={!isDeletable}
                            className="top-tool-buttons deactivate"
                            onClick={() => setOpenDatePicker(!openDatePicker)}
                            icon={<Icon icon="fluent:presence-blocked-10-regular" />}
                            title={i18n.t("set_inactivation_date")}
                        >
                            <DatePicker
                                open={openDatePicker}
                                onChange={(data) => handleDeactivate(data)}
                                onOpenChange={() => setOpenDatePicker(false)}
                                suffixIcon={null}
                                picker="month"
                                placeholder=""
                                allowClear={false}
                            />
                        </Button>
                    )}
                    {customButtons &&
                        customButtons.map((button, index) => {
                            if (button.dropDownItens) {
                                return (
                                    <Dropdown
                                        key={index}
                                        overlay={
                                            <Menu>
                                                {button.dropDownItens?.map(
                                                    ({ title, disabled = false, onClick }, index) => (
                                                        <Menu.Item key={index} onClick={onClick} disabled={disabled}>
                                                            <span className="menu-item-title">{title}</span>
                                                        </Menu.Item>
                                                    )
                                                )}
                                            </Menu>
                                        }
                                    >
                                        <Button
                                            className={`${button.className} top-tool-buttons`}
                                            onClick={button.onClick}
                                            icon={button.icon}
                                            disabled={button.disabled}
                                            title={button.toolTip}
                                        >
                                            {button.title}
                                        </Button>
                                    </Dropdown>
                                );
                            } else {
                                return (
                                    <Button
                                        key={index}
                                        className={`top-tool-buttons ${button.className}`}
                                        onClick={button.onClick}
                                        icon={button.icon}
                                        disabled={button.disabled}
                                        title={button.toolTip}
                                        loading={button.loading}
                                    >
                                        {button.title}
                                    </Button>
                                );
                            }
                        })}
                </div>
                <div className="top-buttons-right-content">
					{multipleSearch ? (
						<MultipleSearch
							tableData={multipleSearch.tableData}
							setTableData={multipleSearch.setTableData}
							onChange={multipleSearch.onChange}
							options={multipleSearch.options}
						/>
                    ) : handleSearch ? (
                        <Search
                            placeholder={searchPlaceholder ? searchPlaceholder : i18n.t("search")}
                            onChange={handleSearch}
                        />
                    ) : null}
                    {importExportOptions && (functionalityPermissions.export || functionalityPermissions.import) ? (
                        <ImportExportMenu
                            exportGridData={importExportOptions.exportGridData}
                            importProps={importExportOptions.importProps}
                            buttonType={importExportOptions.buttonType}
                            otherFeatures={
                                importExportOptions?.otherFeatures ? importExportOptions.otherFeatures : null
                            }
                            handleSuccessImport={handleSuccessImport}
                        />
                    ) : null}
                </div>
            </div>
            {!!filtersSelected && (
                <FilterSelected
                    cleanFilters={() => {
                        cleanFilters();
                        onCleanFilters();
                    }}
                    flexFieldsSelected={filtersSelected}
                />
            )}
        </>
    );
}
