import { Col, Form, Modal, Row, Select } from 'antd';
import React from 'react'
import i18n from 'util/base/i18n';
import { ICopyGrouperModal } from '../IProjectionModeling';
import { selectProps } from 'util/props/props';
export default function CopyGrouperModal({
    openCopyModal,
    setOpenCopyModal,
    grouperData,
    accountingList,
    costCenterList,
    onFinishCopyModal,
    selectedRow
}: ICopyGrouperModal) {

    const [form] = Form.useForm();

    const filteredOptions = grouperData
        .filter(item => item.id !== selectedRow?.value)
        .map(item => ({ value: item.id, label: item.description }));

    const fieldFormatting = [
        {
            name: "DebitAcc",
            label: `${i18n.t<string>("accounting_account")} (${i18n.t<string>("debit")})`,
            options: accountingList,
            pair: "DebitCc",
        },
        {
            name: "DebitCc",
            label: `${i18n.t<string>("cost_center")} (${i18n.t<string>("debit")})`,
            options: costCenterList,
            pair: "DebitAcc",
        },
        {
            name: "CreditAcc",
            label: `${i18n.t<string>("accounting_account")} (${i18n.t<string>("credit")})`,
            options: accountingList,
            pair: "CreditCc",
        },
        {
            name: "CreditCc",
            label: `${i18n.t<string>("cost_center")} (${i18n.t<string>("credit")})`,
            options: costCenterList,
            pair: "CreditAcc",
        },
    ];

    function onCloseModal() {
        setOpenCopyModal(false);
        form.resetFields();
    }

    return (
        <Modal
            title={`${i18n.t("copy_grouper_projections")} (${selectedRow?.name})`}
            width={700}
            open={openCopyModal}
            okButtonProps={{ htmlType: "submit", form: "copy-grouper-form" }}
            className="gs-modal confirm-last-level-modal-container"
            cancelText={i18n.t<string>("cancel")}
            okText={i18n.t<string>("copy")}
            onCancel={onCloseModal}
            centered
        >
            <Form
                name="copy-grouper-form"
                className="grouper-copy-form"
                layout="vertical"
                form={form}
                requiredMark={false}
                onFinish={onFinishCopyModal}
            >
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            name="destinyGrouper"
                            label={i18n.t("select_the_new_grouper")}
                            rules={[{ required: true, message: i18n.t("required_field") }]}
                        >
                            <Select
                                options={filteredOptions}
                                placeholder={i18n.t<string>("select")}
                                allowClear
                                {...selectProps}
                            ></Select>
                        </Form.Item>
                    </Col>
                </Row>
                <h4 style={{ color: "#0065B3" }}>{i18n.t<string>("competence")}</h4>
                <div className="fields-projection">
                    {fieldFormatting.map((item) => {
                        const { name, label, options } = item;
                        return (
                            <Col span={12} key={name}>
                                <Form.Item
                                    key={`competence${name}`}
                                    name={`competence${name}`}
                                    label={label}
                                >
                                    <Select
                                        options={options}
                                        placeholder={i18n.t<string>("select")}
                                        allowClear
                                        dropdownStyle={{ maxHeight: 300 }}
                                        {...selectProps}
                                    ></Select>
                                </Form.Item>
                            </Col>
                        );
                    })}
                </div>
                <h4 style={{ color: "#0065B3" }}>{i18n.t<string>("cash")}</h4>
                <div className="fields-projection" >
                    {fieldFormatting.map((item) => {
                        const { name, label, options } = item;
                        return (
                            <Col span={12} key={name}>
                                <Form.Item
                                    key={`cash${name}`}
                                    name={`cash${name}`}
                                    label={label}
                                >
                                    <Select
                                        options={options}
                                        placeholder={i18n.t<string>("select")}
                                        allowClear
                                        {...selectProps}
                                    ></Select>
                                </Form.Item>
                            </Col>
                        );
                    })}
                </div>
            </Form>
        </Modal >
    );
}