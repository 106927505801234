import { FormInstance } from "antd";
import { IModalityFilter } from "module/budget/pages/revenue/reportView/components/Filters/IFilters";
import { IndexerType, IProjectionGrouperData } from "../groupers/IProjectionGroupers";
import { ListConditions } from "./newProjection/stepOne/IStepOne";
import { IFormula } from "./newProjection/stepTwo/IStepTwo";
import { Options } from "util/types/types";
import { Key } from "react";
import { ColumnsType } from "antd/lib/table";

export interface TableData {
    id?: number;
    key: number;
    description?: string;
    name?: string;
    event: string;
    origin?: IOriginDataResponse;
    formula?: IFormula;
    calculationOrder?: number;
    startDate?: string;
    endDate?: string;
    currencyId?: number;
    percentualBase?: number;
    cashCreditAccountingAccountId?: number;
    cashDebitAccountingAccountId?: number;
    cashCreditCostCenterId?: number;
    cashDebitCostCenterId?: number;
    accrualCreditAccountingAccountId?: number;
    accrualDebitAccountingAccountId?: number;
    accrualCreditCostCenterId?: number;
    accrualDebitCostCenterId?: number;
    scheduleDays?: number;
    schedulePayment?: string;
    flexFieldsConditions?: any;
    children?: TableData[];
    ledgerId?: number;
    grouperId?: number;
    projectionCopyType?: ICopyType;
    indexerId?: number;
    indexerType?: IndexerType;
    value?: number;
}

export interface EventData {
    value: number | string;
    label: string;
}

export interface OriginData {
    value: string;
    label: string;
}

export interface GrouperData {
    value: number | string;
    label: string;
}

export interface PlanningEventData {
    EXPENSES: IGroupList[];
    HUMAN_RESOURCES: IGroupList[];
    REVENUE: IGroupList[];
    INVESTMENT: IGroupList[];
}

export interface FieldProps {
    label: string;
    value: {
        id: number | string;
        name: string;
        type: string;
    };
}

export interface FormValues {
    description: string;
    event: string;
    origin: IOriginDataResponse;
    calculationOrder: number;
    initialDate: moment.Moment;
    endDate: moment.Moment;
    currency: number;
    base: number;
    cashCreditAcc: number;
    cashDebitAcc: number;
    cashCreditCc: number;
    cashDebitCc: number;
    competenceCreditAcc: number;
    competenceDebitAcc: number;
    competenceCreditCc: number;
    competenceDebitCc: number;
    boxCronogramWhole: boolean;
    boxCronogramInstallment: boolean;
    ledgerId: number;
    grouper: number | string;
    projectionCopyType: ICopyType;
    indexerType: IndexerType;
    indexerId: number;
    isIndexerGrouper?: boolean;
}

export interface ICostCenter {
    id: number;
    externalCode: string;
    description: string;
}

export interface IGroupList {
    id?: number;
    event: string;
    children: TableData[];
    value: number | string;
}
export interface IAccounting {
    id: number;
    externalCode: string;
    parentId: number;
    description: string;
}

export interface IFlexFieldValues {
    value: number;
    flexFieldId: number;
    label: string;
}

export interface IFieldList {
    value: {
        id: string;
        type: string;
        name: string;
    };
    label: string;
}

export interface IPeriodColumn {
    title: string;
    dataIndex: string;
    key: number;
}

export interface IPeriodValue {
    [key: string]: string;
}

export enum ICopyType {
    LAST_YEAR_ENTIRE_COPY = "LAST_YEAR_ENTIRE_COPY",
    LAST_MONTH_COPY = "LAST_MONTH_COPY",
}

export type LevelItem = {
    id: string;
    key: string | number;
    parentId: number;
    title: string;
    children: LevelItem[];
    externalCode: string;
    description?: string;
    dataTree?: LevelItem[];
    classificationId: number;
};

export enum PlanningEvent {
    EXPENSES = "expenses",
    HUMAN_RESOURCES = "human.resources",
    REVENUE = "revenue",
    INVESTMENT = "investment",
    PLANNING = "planning",
}

export enum ProjectionOrigin {
    EXPENSES = "origin.expenses",
    HUMAN_RESOURCES = "origin.human_resources",
    REVENUE = "origin.revenue",
    INVESTMENT = "origin.investment",
    PROFIT = "origin.planning",
    OTHERS = "origin.others",
    ACCOUNTING_BALANCES = "origin.accounting_balances",
    PROJECTION = "origin.projection",
    TAX = "origin.tax",
}

export const ProjectionOriginEventMap: { [key: number]: ProjectionOrigin } = {
    1: ProjectionOrigin.EXPENSES,
    2: ProjectionOrigin.HUMAN_RESOURCES,
    3: ProjectionOrigin.REVENUE,
    4: ProjectionOrigin.INVESTMENT,
    5: ProjectionOrigin.PROFIT,
    6: ProjectionOrigin.OTHERS,
    7: ProjectionOrigin.ACCOUNTING_BALANCES,
    8: ProjectionOrigin.PROJECTION,
    9: ProjectionOrigin.TAX,
};

export const PlanningEventMap: { [key: number]: PlanningEvent } = {
    1: PlanningEvent.EXPENSES,
    2: PlanningEvent.HUMAN_RESOURCES,
    3: PlanningEvent.REVENUE,
    4: PlanningEvent.INVESTMENT,
    5: PlanningEvent.PLANNING,
};

export interface IProjection {
    id: number;
    description: string;
}

export enum FormulaStaticField {
    DEVALUATION = "depreciatedValue",
    IMMOBILIZED = "totalValue",
    IMMOBILIZED_PROGRESS = "totalValue",
    PROJECTED_VALUE = "projectedValue",
    PROFIT = "profit",
    INITIAL_VALUE = "accounting_balances_opening_balance",
    DEBIT_VALUE = "accounting_balances_debit_movement",
    CREDIT_VALUE = "accounting_balances_credit_movement",
    FINAL_VALUE = "accounting_balances_final_balance",
}

export enum FormulaFieldType {
    STATIC_FIELD = "STATIC_FIELD",
    MODULAR_FIELD = "MODULAR_FIELD",
    OPERATOR = "OPERATOR",
    ABSOLUTE_VALUE = "ABSOLUTE_VALUE",
    PERCENTAGE_VALUE = "PERCENTAGE_VALUE",
    LEFT_PARENTHESIS = "OPEN_PARENTHESIS",
    RIGHT_PARENTHESIS = "CLOSE_PARENTHESIS",
    IMMOBILIZED = "totalValue",
    IMMOBILIZED_PROGRESS = "totalValue",
    PROJECTED_VALUE = "projectedValue",
    PROFIT = "profit",
    INITIAL_VALUE = "accounting_balances_opening_balance",
    DEBIT_VALUE = "accounting_balances_debit_movement",
    CREDIT_VALUE = "accounting_balances_credit_movement",
    FINAL_VALUE = "accounting_balances_final_balance",
    CREDIT_PLUS_DEBIT_VALUE = "accounting_balances_credit_plus_debit_movement"
}

export interface IProjectionModelingContext {
    form: FormInstance<FormValues>;
    costCenterList: IModalityFilter[];
    accountingList: IModalityFilter[];
    projectionsOptions: Options[];
    setProjectionsOptions: React.Dispatch<React.SetStateAction<Options[]>>;
    selectedRowKeys: Key[];
    setSelectedRowKeys: React.Dispatch<React.SetStateAction<Key[]>>;
    selectedRows: TableData[];
    setSelectedRows: React.Dispatch<React.SetStateAction<TableData[]>>;
    formula: IFormula;
    setFormula: React.Dispatch<React.SetStateAction<IFormula>>;
    ledgerList: IModalityFilter[];
    setLedgerList: React.Dispatch<React.SetStateAction<IModalityFilter[]>>;
    isInstallment: boolean;
    setIsInstallment: React.Dispatch<React.SetStateAction<boolean>>;
    isWhole: boolean;
    setIsWhole: React.Dispatch<React.SetStateAction<boolean>>;
    isEditingProjection: boolean;
    setIsEditingProjection: React.Dispatch<React.SetStateAction<boolean>>;
    tableData: IGroupList[];
    setTableData: React.Dispatch<React.SetStateAction<IGroupList[]>>;
    filterdTableData: IGroupList[];
    setFilterdTableData: React.Dispatch<React.SetStateAction<IGroupList[]>>;
    itemForEdit: TableData | null;
    isGridFetching: boolean;
    setIsGridFetching: React.Dispatch<React.SetStateAction<boolean>>;
    grouperData: IProjectionGrouperData[];
    setGrouperData: React.Dispatch<React.SetStateAction<IProjectionGrouperData[]>>;
    listTax: Options[];
    setListTax: React.Dispatch<React.SetStateAction<Options[]>>;
    getTableData: () => void;
    isNewProjection: boolean;
    setIsNewProjection: React.Dispatch<React.SetStateAction<boolean>>;
    tableColumns: ColumnsType<TableData>;
    stepOneListConditions: ListConditions[];
    setStepOneListConditions: React.Dispatch<React.SetStateAction<ListConditions[]>>;
    fieldList: IFieldList[];
    setFieldList: React.Dispatch<React.SetStateAction<IFieldList[]>>;
    eventSelected: string | number;
    setEventSelected: React.Dispatch<React.SetStateAction<string | number>>;
    projectionToSave: Record<string, any>;
    setProjectionToSave: React.Dispatch<React.SetStateAction<Record<string, any>>>;
    scheduleDays: number;
    setScheduleDays: React.Dispatch<React.SetStateAction<number>>;
    isFormulaFetching: boolean;
    setIsFormulaFetching: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface IOriginDataResponse {
    EXPENSES: string,
    HUMAN_RESOURCES: string,
    REVENUE: string,
    INVESTMENT: string,
    PROFIT: string,
    OTHERS: string,
    ACCOUNTING_BALANCES: string,
    PROJECTION: string,
    TAX: string,
}

export interface ICommomList {
    id: number;
    externalCode: string;
    description?: string;
    name?: string;
}

export interface ConfirmationModalProps {
    isModalVisible: boolean;
    setIsModalVisible: (value: boolean) => void;
    message?: string;
    handleConfirmOk?: () => void;
    handleConfirmCancel?: () => void;
}

export interface IFormulaModalFormValues {
    projectionId?: number;
    accountingId?: number;
    costCenterId?: number;
    acquisitionDate?: moment.Moment;
    rate: number;
    tax?: number;
}

export interface ICopyGrouperModal {
    openCopyModal: boolean;
    setOpenCopyModal: (b: boolean) => void;
    grouperData: IProjectionGrouperData[];
    accountingList: IModalityFilter[]
    costCenterList: IModalityFilter[];
    onFinishCopyModal: (f: CopyGrouperModalForm) => void;
    selectedRow: TableData;
}

export interface CopyGrouperModalForm {
    destinyGrouper: number;
    competenceDebitAcc: number;
    competenceDebitCc: number;
    competenceCreditAcc: number;
    competenceCreditCc: number;
    cashDebitAcc: number;
    cashDebitCc: number;
    cashCreditAcc: number;
    cashCreditCc: number;
}