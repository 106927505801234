import { Form } from "antd";
import { useContext, createContext, useEffect, useState } from "react";
import { ContractType, INewContractContextProps } from "../IRecurringContract";
import { GenericSelection } from "module/budget/pages/flexField/IFlexField";
import i18n from "util/base/i18n";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { useUserContext } from "context/UserContext";

const NewContractContext = createContext<INewContractContextProps | undefined>(null);

export const NewContractProvider: React.FC = ({ children }) => {
	const { userInfo: { groupIds } } = useUserContext();
	const [form] = Form.useForm();
	function filterFlexFieldHasValue(formValues: {}): number[] {
		return Object.keys(formValues)
			.filter(key => (key.includes("flexField") && !key.includes("SMS")) && formValues[key])
			.map(key => Number(formValues[key]))
	}
	function filterFlexFieldSmsHasValue(formValues: {}): number[] {
		return Object.keys(formValues)
			.filter(key => (key.includes("flexField") && key.includes("SMS")) && formValues[key])
			.map(key => Number(formValues[key]))
	}
	const [optionsContractType, setOptionsContractType] = useState<GenericSelection[]>([
		{
			label: i18n.t("recurrent"),
			value: "RECURRING"
		},
		{
			label: i18n.t("non_recurring"),
			value: "NOT_RECURRING"
		},
		{
			label: i18n.t("new_sales"),
			value: "NEW_SALES"
		},
		{
			label: i18n.t("churn"),
			value: "CHURN"
		},
		{
			label: i18n.t("new_sales_sms"),
			value: "NEW_SALES_SMS"
		},
	])

	const chooseOptionsContractType = {
		RECURRING: i18n.t("recurrent"),
		NOT_RECURRING: i18n.t("non_recurring"),
		NEW_SALES: i18n.t("new_sales"),
		CHURN: i18n.t("churn"),
		NEW_SALES_SMS: i18n.t("new_sales_sms"),
	}

	const onLoadOptions = (data: ContractType[]) => {
		setOptionsContractType(data.map((item) => ({ label: chooseOptionsContractType[item], value: item })));
	}

	useEffect(() => {
		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: `/revenue/recurring-contract-type/security/alloweds?group=${groupIds}&user={user}`
		}, onLoadOptions)
	}, [groupIds])

	return (
		<NewContractContext.Provider value={{
			form,
			filterFlexFieldHasValue,
			filterFlexFieldSmsHasValue,
			optionsContractType,
			setOptionsContractType
		}}>
			{children}
		</NewContractContext.Provider>
	);
};

export const useNewContractContext = () => {
	const context = useContext(NewContractContext);
	if (!context) {
        throw new Error("useNewContractContext deve ser usado dentro de um NewContractContext");
    }
    return context;
};
