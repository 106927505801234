import { Checkbox, Col, DatePicker, Form, Input, InputNumber, Modal, Row } from "antd";
import { useDistributionRuleContext } from "../context/DistributionRuleContext";
import i18n from "util/base/i18n";
import { IDatePickerDefaultProps } from "../../revenue/recurringContract/IRecurringContract";
import { Icon } from "@iconify/react";
import { FiltersTab } from "./FiltersTab";
import { FilterResultType, IDistributionRuleData, IDistributionRuleForm } from "../IDistributionRule";
import { useEffect, useMemo } from "react";
import moment from "moment";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { Notification } from "components/notification/Notification";
import { useUserContext } from "context/UserContext";
import StringUtil from "util/StringUtil";

export default function DistributionRuleModal() {
	const defaultDatePickerProps: IDatePickerDefaultProps = {
		picker: "month",
		className: "replicate-scenario-month",
		suffixIcon: <Icon icon="fa6-solid:calendar" />,
		allowClear: true,
		format: "MMM/YYYY"
	}

	const [form] = Form.useForm<IDistributionRuleForm>();
	const startDateSelected = Form.useWatch('startDate', form);
	const finalDateSelected = Form.useWatch('endDate', form);
	const {
		isModalOpen,
		isSaving,
		setIsSaving,
		setIsModalOpen,
		setRuleForEdit,
		ruleForEdit,
		ruleConditionFilter,
		ruleConditionResult,
		setRuleConditionFilter,
		setRuleConditionResult,
		getTableData,
	} = useDistributionRuleContext();
	const { userInfo: { id, selection: { scenarioId, businessUnitId, organizationId } } } = useUserContext();

	const isOkDisabled: boolean = useMemo(() => !(ruleConditionFilter.length && ruleConditionResult.length), [ruleConditionFilter, ruleConditionResult]);

	useEffect(() => {
		if (!ruleForEdit) return;

		form.setFieldsValue({
			...ruleForEdit,
			startDate: moment(ruleForEdit.startDate),
			endDate: moment(ruleForEdit.endDate),
		});
		setIsModalOpen(true);
	}, [ruleForEdit])

	const handleCloseModal = () => {
		setIsModalOpen(false);
		form.resetFields();
		setRuleForEdit(null);
		setIsSaving(false);
		setRuleConditionFilter([]);
		setRuleConditionResult([]);
	}

	const disableDate = (date: moment.Moment, isInitialDate: boolean = true) => {
		const isDisable = isInitialDate
			? finalDateSelected && date.isSameOrAfter(finalDateSelected)
			: startDateSelected && date.isSameOrBefore(startDateSelected);
		return isDisable;
	}

	const handleSave = (formValues: IDistributionRuleForm) => {
		setIsSaving(true);
		const startDate: string = StringUtil.capitalizeFirstLetter(moment(formValues.startDate).startOf('month').format("YYYY-MM-DD"));
		const endDate: string = StringUtil.capitalizeFirstLetter(moment(formValues.endDate).endOf('month').format("YYYY-MM-DD"));
		const ruleConditionToSave: IDistributionRuleData = {
			...formValues,
			filters: ruleConditionFilter,
			results: ruleConditionResult,
			startDate,
			endDate,
			scenarioId,
			businessUnitId,
			organizationId,
			updatedByUserId: id,
			id: ruleForEdit?.id,
		}
		const requestType: RequestType = ruleForEdit ? RequestType.PUT : RequestType.POST

		ServiceCaller.doRequest({
			type: requestType,
			url: "/software-house/distribution-rule",
			params: ruleConditionToSave
		}, onSave, handleErrorRequest)
	}

	const onSave = () => {
		Notification({
			message: i18n.t("successfully_saved"),
			type: "success"
		})
		handleCloseModal();
		getTableData();
	}

	return (
		<Modal
			open={isModalOpen}
			onCancel={handleCloseModal}
			title={i18n.t("distribution_rules.title")}
			okButtonProps={{
				className: "gs-main-button",
				loading: isSaving,
				htmlType: "submit",
				form: "rule-modal-form",
				disabled: isOkDisabled
			}}
			okText={i18n.t<string>("save")}
			cancelButtonProps={{ type: "text" }}
			cancelText={i18n.t<string>("close")}
			className="gs-modal rule-distribution-modal attribute_parameterization-new-rule-modal"
			centered
			width={"60%"}
		>
			<Form
				form={form}
				name="rule-modal-form"
				layout="vertical"
				onFinish={handleSave}
			>
				<Row gutter={10}>
					<Col span={6}>
						<Form.Item
							label={i18n.t("description")}
							name="description"
							rules={[{ required: true, message: i18n.t<string>("required_field") }]}
						>
							<Input />
						</Form.Item>
					</Col>
					<Col span={3}>
						<Form.Item
							label={i18n.t("order")}
							name="order"
							rules={[{ required: true, message: i18n.t<string>("required_field") }]}
							initialValue={1}
						>
							<InputNumber
								min={1}
							/>
						</Form.Item>
					</Col>
					<Col span={3}>
						<Row>
							<Form.Item
								valuePropName="checked"
								name={"planned"}>
								<Checkbox>
									{i18n.t("planned")}
								</Checkbox>
							</Form.Item>
						</Row>
						<Row>
							<Form.Item
								valuePropName="checked"
								name={"accomplished"}>
								<Checkbox>
									{i18n.t("realized")}
								</Checkbox>
							</Form.Item>
						</Row>
					</Col>
					<Col span={12} className="date-content">
						<Row gutter={10}>
							<Col span={12}>
								<Form.Item
									label={i18n.t("distribution_rules.beginning_term")}
									name={"startDate"}
									rules={[{ required: true, message: i18n.t<string>("required_field") }]}
								>
									<DatePicker
										{...defaultDatePickerProps}
										disabledDate={date => disableDate(date)}
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item
									label={i18n.t("distribution_rules.end_term")}
									name={"endDate"}
									rules={[{ required: true, message: i18n.t<string>("required_field") }]}
								>
									<DatePicker
										{...defaultDatePickerProps}
										disabledDate={date => disableDate(date, false)}
									/>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={10}>
							<Col span={12}>
								<Form.Item
									label={`% ${i18n.t("value")}`}
									name={"percentageValue"}
									rules={[{ required: true, message: i18n.t<string>("required_field") }]}
									initialValue={100}
								>
									<InputNumber
										min={0}
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item
									label={i18n.t("distribution_rules.multiplier")}
									name={"multiplier"}
									initialValue={1}
									rules={[{ required: true, message: i18n.t<string>("required_field") }]}
								>
									<InputNumber />
								</Form.Item>
							</Col>
						</Row>
					</Col>
				</Row>
			</Form>
			<Row >
				<Col id="attribute_parameterization-new-rules-modal-rules-section" span={24}>
					<FiltersTab />
				</Col>
			</Row>
		</Modal>
	)
}