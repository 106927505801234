export interface ICaptchaProps {
    charactersLength?: number;
    onSuccessfulSubmit?: () => void;
    tooltipTitleI18n?: string;
    buttonTitleI18n?: string;
    hasReloadButton?: boolean;
    disabledButton?: boolean;
    icon?: JSX.Element;
    hasIcon?: boolean;
    captchaType?: ICaptchType;
    messageCaptcha?: string;
    className?: string
}

export interface ILoadCaptchaEngingeProps {
	captchaType?: ICaptchType;
	numberOfCharacters?: number;
}

export enum ICaptchType {
	upper = "upper",
	lower = "lower",
	number = "number",
	special = "special",
	default = "default"
}