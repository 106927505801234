import { useEffect, useState } from "react";
import { Modal, Form, Select, Checkbox, Row, Col, Table,} from "antd";
import i18n from "util/base/i18n";

import { selectProps } from "util/props/props";
import { ISetPackageLevelModal, LevelItem } from "../IGroupings";

import type { ColumnsType } from 'antd/es/table';
import Captcha from "components/captcha/Captcha";

export function SetPackageLevelModal({ open, handleCancel, handleSetPackageLevel, lookupLevels, onDeletePackageLevel }: ISetPackageLevelModal) {
    const [options, setOptions] = useState([]);
    const [packageLevel, setPackageLevel] = useState({ level: null, hasCostCenterLinked: false });
    const [form] = Form.useForm();
    const selectedLevel = Form.useWatch('packageLevel', form);
    useEffect(() => {
        if (!lookupLevels || lookupLevels.length === 0) return;
        const hasAlreadylinked = lookupLevels.some(item => item.isSpendingPackageLevel);

        if (hasAlreadylinked) {
            const item = lookupLevels.find(item => item.isSpendingPackageLevel);
            setPackageLevel({ level: item.level, hasCostCenterLinked: item.hasCostCenterLinked })
            form.setFieldsValue({ packageLevel: item.level })
        }
        const updatedOptions = lookupLevels.reduce((acc, item) => {
            const hasOption = acc.find(option => option.value === item.level);
            if (!hasOption) {
                acc.push({ label: `Nível ${item.level}`, value: item.level })
            }
            return acc
        }, []).sort((a, b) => a.value - b.value);

        setOptions(updatedOptions);
    }, [lookupLevels])

    const columns: ColumnsType<any> = [
        {
            title: i18n.t("level"),
            dataIndex: 'level',
            key: 'level',
            align: 'center',
        },
        {
            title: i18n.t("link_cost_center"),
            dataIndex: 'hasCostCenterLinked',
            key: 'hasCostCenterLinked',
            align: 'center',
            render: value => value ? i18n.t("yes") : i18n.t("no")
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'x',
            align: 'center',
            render: () => (
                    <Captcha
                        className="captcha-group"
                        tooltipTitleI18n={i18n.t("package-level-deletion")}
                        buttonTitleI18n={i18n.t("delete")}
                        onSuccessfulSubmit={handleDelete}
                        messageCaptcha={i18n.t("delete_all_data_linked_to_package_level")}
                    />
            )
        },
    ]

    function handleDelete() {
        setPackageLevel({ level: null, hasCostCenterLinked: false });
        onDeletePackageLevel(packageLevel.level);
         handleCancel();
    }

    return (
        <Modal
            width={550}
            centered={true}
            open={open}
            title={i18n.t("spending_package_level")}
            onCancel={handleCancel}
            afterClose={() => form.resetFields()}
            className="gs-modal"
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        form.resetFields();
                        handleSetPackageLevel(values);
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
            }}
            cancelText={i18n.t<string>("close")}
            okText={i18n.t<string>("save")}
            okButtonProps={{ htmlType: "submit", form: "new-level" }}
            {...(packageLevel.level ? { footer: false } : {})}
        >
            {packageLevel.level ?
                <Table
                    className="gs-table"
                    dataSource={[packageLevel]}
                    columns={columns}
                    pagination={false}
                />
                :
                <>
                    <Form
                        form={form}
                        name="set-package-form"
                        initialValues={{ originLevel: 0 }}
                        layout="vertical"
                        requiredMark={false}
                    >
                        <Row align='bottom' gutter={20}>
                            <Col span={12}>
                                <Form.Item
                                    label={i18n.t("level")}
                                    name="packageLevel"
                                    rules={[{
                                        required: true,
                                        message: i18n.t<string>("required_field")
                                    }]}
                                >
                                    <Select
                                        placeholder={i18n.t("select")}
                                        id="package-level"
                                        options={options}
                                        {...selectProps}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="hasCostCenterLinked"
                                    valuePropName="checked"
                                >
                                    <Checkbox>
                                        {i18n.t("link_cost_center")}
                                    </Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <div>
                        {selectedLevel && <p>{i18n.t("packages_of_the_selected_level")}</p>}
                        {selectedLevel && lookupLevels.filter(item => item.level === selectedLevel).slice(0, 5).map((level: LevelItem) => (
                            <p className="gs-tag blue" key={level.key}>{level.title}</p>
                        ))}
                        {selectedLevel && lookupLevels.filter(item => item.level === selectedLevel).length > 5 && <p className="gs-tag blue">...</p>}
                    </div>
                </>
            }

        </Modal>
    )
}