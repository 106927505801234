import { FormInstance } from "antd";
import { Key } from "react";
import { OptionSelect } from "../../collaborators/linkAccounts/ILinkAccounts";
import { IRuleOperationFromRequest } from "../attributeParameterization/components/modals/rule/components/IModalFactory";
import { FlexFieldValue, FormulaItem, ICondition, ILevel, ILevelsValue, RuleConditionOperations } from "../attributeParameterization/IAttributeParameterization";

export interface ITableData {
    description: string;
    id: number;
}

export interface IRegistrationSalesReportsTableProps {
    tableData: ITableData[];
    setTableData: (data: ITableData[]) => void;
    setIsFetching: (isFetching: boolean) => void;
    isFetching: boolean;
    selectedRowKeys: Key[];
    onChange: (selectRowKeys: number[], selectedRows: ITableData[]) => void;
}

export interface INewReportProps {
    idReportForEdit: number;
    onCancel: () => void;
    isEditing: boolean;
}

export interface LevelReport {
    externalCode: string;
    isSubLevel?: boolean;
    ordination: number;
    description: string;
    invisible: boolean;
    title: boolean;
    informative: boolean;
    children: LevelReport[];
    levelStyle?: ILevelStyle;
    key: number;
    id?: number;
    subLevels?: LevelReport[];
    upperLevelId?: number;
    levelFormula?: LevelFormula;
    levelFilter?: LevelFilter;
    formulas?: Formula[];
    filters?: ICondition[];
}

export interface LevelFormula {
    belongsToId: number;
    formulas: Formula[];
}

export interface LevelFilter {
    belongsToId: number;
    filters: Filters[];
}

export interface Filters {
    id?: number;
    operation?: RuleConditionOperations;
    flexFieldId?: number;
    flexFieldValueId?: number;
    filterType?: FilterType;
    managementCostCenterId?: number;
    managementAccountingAccountId?: number;
    filterPlanning?: LevelFilterPlanning;
}

export enum FilterType {
    FLEX_FIELD,
    FROM_TO
}

export interface ILevelStyle {
    color: string;
    colorFont: string;
    isBold: boolean;
    isItalic: boolean;
    isUnderlined: boolean;
    isScratched: boolean;
    id?: number;
    fontSize: number;
    signalType?: SignalType;
}

export interface Report {
    description: string;
    levels: LevelReport[];
    id?: number;
    reportType: ReportType;
    type?: ReportType;
}

export interface Formula {
    ordination: number;
    type: FormulaType;
    value?: number;
    operator?: string;
    levelId?: number;
    attributeId?: number;
    id?: number;
}

export enum FormulaType {
    INFORMED_VALUE = "INFORMED_VALUE",
    OPERATORS = "OPERATORS",
    LEVEL = "LEVEL",
    ATTRIBUTE = "ATTRIBUTE",
    OPENING_BALANCE = "OPENING_BALANCE",
    FINAL_BALANCE = "FINAL_BALANCE",
    MOVEMENT = "MOVEMENT",
    DEBIT = "DEBIT",
    CREDIT = "CREDIT"
}

export interface IconIsVisible {
    isIconDownVisible: boolean;
    isIconUpVisible: boolean;
}

export interface IStepGenericProps {
    report: Report;
    setReport: (report: Report) => void;
    isEditing: boolean;
}

export interface IStepOneProps extends IStepGenericProps {
    sortLevels: (levels: LevelReport[]) => LevelReport[];
    setReportType: React.Dispatch<React.SetStateAction<ReportType>>;
}

export interface IStepTwoProps extends IStepGenericProps {
    optionsForFormula: OptionSelect[];
    fetchReports: () => void;
}

export interface ITableSideBarProps {
    report: Report;
    setReport: (report: Report) => void;
    selectedRowKey: number;
    setSelectedRowKey: React.Dispatch<React.SetStateAction<number>>;
    setSelectedRowFormulaFormated: React.Dispatch<React.SetStateAction<FormulaItem[]>>;
    setSelectedRowFormula: React.Dispatch<React.SetStateAction<Formula[]>>;
    options: OptionSelect[];
    hasUnsavedChanges: boolean;
    setPendingLevelSelected: React.Dispatch<React.SetStateAction<LevelReport>>;
    setSelectedRowFilters: React.Dispatch<React.SetStateAction<ICondition[]>>;
    isChangedFormula: (record: LevelReport) => boolean;
    isFilterValid: () => boolean;
    setSelectedRowPlanningFilters: React.Dispatch<React.SetStateAction<LevelFilterPlanning[]>>
}

export interface ITableLevelsProps {
    selectedRowKeys: number[];
    tableData: LevelReport[];
    setTableData: React.Dispatch<React.SetStateAction<LevelReport[]>>;
    onChange: (selectRowKeys: number[], selectedRows: ITableData[]) => void;
    setHasSavedValue: (value: boolean) => void;
    setIsLoading: (value: boolean) => void;
    isLoadingTableData: boolean;
}

export interface IOverflowColorProps {
    isVisible: boolean;
    setIsVisible: (isVisible: number) => void;
    selected: ILevelStyle;
    index: number;
    saveNewStyle: (newStyle: ILevelStyle) => void;
}

export interface IFontStylePickerProps {
    isBoldSelected: boolean;
    setIsBoldSelected: React.Dispatch<React.SetStateAction<boolean>>;
    isItalicSelected: boolean;
    setIsItalicSelected: React.Dispatch<React.SetStateAction<boolean>>;
    isUnderlinedSelected: boolean;
    setIsUnderlinedSelected: React.Dispatch<React.SetStateAction<boolean>>;
    isScratchedSelected: boolean;
    setIsScratchedSelected: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface ILineFilterProps {
    onClickSave: (conditions: ICondition[], planningCondition: LevelFilterPlanning[]) => void;
    conditionsEdit: ICondition[];
    levelsValue: ILevelsValue[];
    setLevelsValue: React.Dispatch<React.SetStateAction<ILevelsValue[]>>;
    setHasUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
    levels: ILevel;
    setLevels: React.Dispatch<React.SetStateAction<ILevel>>;
    operations: RuleConditionOperations;
    setOperations: React.Dispatch<React.SetStateAction<RuleConditionOperations>>;
    classNameForPulseButton: string;
    availableOperations: any[];
    setAvailableOperations: React.Dispatch<React.SetStateAction<[]>>;
    flexFieldsValues: FlexFieldValue[];
    setFlexFieldsValues: React.Dispatch<React.SetStateAction<FlexFieldValue[]>>;
    conditions: ICondition[];
    planningConditionsEdit: LevelFilterPlanning[];
    setConditions: React.Dispatch<React.SetStateAction<ICondition[]>>;
    form: FormInstance<any>;
    setFilterType: React.Dispatch<React.SetStateAction<FilterType>>;
    filterType: FilterType;
}

export interface IOptionsListProps {
    reportLevels: LevelReport[];
    selectedOptionsList: string[];
    setSelectedOptionsList: React.Dispatch<React.SetStateAction<string[]>>;
    onChangeCheckboxGroup: (checked: any[]) => void;
    options: OptionSelect[];
    formulas: Formula[];
    selectedRowKey: number;
}

export interface IFormulaContentProps {
    optionsSelected: any[];
    onSaveFormulas: (formula: Formula[]) => void;
    setIsLoading: (value: boolean) => void;
    setSelectedOptionsList: React.Dispatch<React.SetStateAction<string[]>>;
    setHasUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
    onCancelChanges: () => void;
    pendingLevelSelected: LevelReport
    setPendingLevelSelected: React.Dispatch<React.SetStateAction<LevelReport>>;
    formula: FormulaItem[];
    setFormula: React.Dispatch<React.SetStateAction<FormulaItem[]>>;
    onAcceptFormulaChanges: () => void;
    classNameForPulseButton: string;
    pendingLevelSelectedFormula: LevelFormula;
    optionsForFormula: OptionSelect[];
}

export interface IStepThreeProps extends IStepGenericProps {
    optionsForFormula: OptionSelect[];
}

export enum FormulaGrouperName {
    FIXED_EXPENSE,
    VARIABLE_EXPENSES,
    SALE_DEDUCTION,
    MARKETING_COST,
    MARKETING_COST_VARIABLE,
    MARKETING_COST_FIXED
}

export interface FormulaAttribute {
    id: number,
    grouperId: number,
    name: string,
    grouperName: FormulaGrouperName,
    formulaAttribute: boolean
}

export interface FontSizeOptions {
    value: number,
    label: string
}

export interface ChooseIconOperation {
    key: number;
    removeItem: (index: number) => void;
    position: number;
}

export enum ReportType {
    REVENUE,
    PLANNING
}

export interface INewReportModalProps {
    isNewReportModalOpen: boolean;
    setIsNewReportModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export enum SignalType {
    ORIGINAL,
    POSITIVE,
    NEGATIVE
}

export interface OptionsFilter {
    label: string;
    value: string;
}

export interface NewReportContextProps {
    reportType: ReportType;
    setReportType: React.Dispatch<React.SetStateAction<ReportType>>;
    isPlanningReport: (type?: ReportType) => boolean;
    resetReportType: () => void;
}

export interface IFiltersResponse {
    id: number;
    flexField: IRuleOperationFromRequest;
    operation: RuleConditionOperations;
    flexFieldValue: IRuleOperationFromRequest;
    managementCostCenter: IRuleOperationFromRequest;
    managementAccounting: IRuleOperationFromRequest;
    fromAccounting: ILevelExternalCodeResponse;
    fromCostCenter: ILevelExternalCodeResponse;
    toAccounting: ILevelExternalCodeResponse;
    toCostCenter: ILevelExternalCodeResponse;
    signal: IReportFilterSignal;
}

export interface IPlanningLineFilterProps {
    costCenter: OptionsFilter[];
    account: OptionsFilter[];
    filterSignalType: IReportFilterSignal;
    setFilterSignalType: React.Dispatch<React.SetStateAction<IReportFilterSignal>>;
    fromCostCenterExternalCode: string;
    toCostCenterExternalCode: string;
    fromAccountingExternalCode: string;
    toAccountingExternalCode: string;
    setFromCostCenterExternalCode: React.Dispatch<React.SetStateAction<string>>;
    setToCostCenterExternalCode: React.Dispatch<React.SetStateAction<string>>;
    setFromAccountingExternalCode: React.Dispatch<React.SetStateAction<string>>;
    setToAccountingExternalCode: React.Dispatch<React.SetStateAction<string>>;
}

export enum IReportFilterSignal {
    PLUS = "PLUS",
    MINUS = "MINUS",
}
export interface LevelFilterPlanning {
    fromCostCenterExternalCode: string;
    toCostCenterExternalCode: string;
    fromAccountingExternalCode: string;
    toAccountingExternalCode: string;
    signal: IReportFilterSignal;
    id: number;
}

export interface IPlanningConditionProps {
    conditions: LevelFilterPlanning[];
    handleRemove: (id: number) => void;
    handleEdit: (id: number) => void;
}

export interface ILevelExternalCodeResponse {
    id: string;
    name: string;
}