import { useState, useEffect } from "react";
import { Button, Form } from "antd";
import { ApportionmentForm } from "./components/ApportionmentForm";
import { NewApportionmentList } from "./components/NewApportionmentList";
import { TransitoryAccount } from "./components/TransitoryAccount";
import moment from "moment";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { Options } from "util/types/types";
import { ApportionmentResponse, ApportionmentType, INewApportionmentSection, TableData } from "../../IApportionment";
import { createTableData } from "../../util/createTableData";
import { useWindowSize } from "hooks/useWindowSize";
import { useUserContext } from "context/UserContext";
import { Notification } from "components/notification/Notification";
import i18n from "util/base/i18n";
import { createPostPayload } from "../../util/createPostPayload";

export function NewApportionmentSection({
    apportionmentPeriod,
    setApportionmentPeriod,
    costCenterOptions,
    businessUnitOptions,
    flexFieldsOriginOptions,
    isFetchingOriginAccountOptions,
    originAccountOptions,
    setFlexFieldsOriginOptions,
    flexFieldsDestinationOptions,
    setFlexFieldsDestinationOptions,
    setIsFetchingOriginAccountOptions,
    setOriginAccountOptions,
    originTableData,
    setOriginTableData,
    destinationTableData,
    setDestinationTableData,
    destinationCollapsed,
    originCollapsed,
    setDestinationCollapsed,
    setOriginCollapsed,
    setIsApportionmentListVisible,
    fetchApportionments,
    apportionmentType,
    setApportionmentType,
    openCalendarPeriod,
    budgetDates
}: INewApportionmentSection) {
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [isFetchingDestinationAccountOptions, setIsFetchingDestinationAccountOptions] = useState(false)
    const [destinationAccountOptions, setDestinationAccountOptions] = useState<Options[]>([])
    const [isTransitoryAccountOpen, setIsTransitoryAccountOpen] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { userInfo } = useUserContext()
    const windowSize = useWindowSize()
    const [transitoryAccountForm] = Form.useForm()
    const [form] = Form.useForm()
    const originCostCenterSelected = Form.useWatch('origin-costCenter', form)
    const destinationCostCenterSelected = Form.useWatch('destination-costCenter', form)
    const fiscalYear = Form.useWatch('origin-year', form)
    const [isOpenDeletePopover, setIsOpenDeletePopover] = useState(false);

    useEffect(() => {
        if (!fiscalYear) {
            return
        }
        const year = moment(fiscalYear).year()
        const updatedDefaultValue = [];
        for (let i = 0; i < 12; i++) {
            const month = `${year}-${String(i + 1).padStart(2, '0')}`;
            updatedDefaultValue.push(month);
        }
        setApportionmentPeriod(updatedDefaultValue)
    }, [fiscalYear])

    useEffect(() => {
        if (!originCostCenterSelected) return
        setIsFetchingOriginAccountOptions(true)

        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: `/budget-base/accounting-account?locale={locale}&client={client}&user={user}&organization={organization}&bu={businessUnit}&costCenter=${originCostCenterSelected}`,
        }, (data: Options[]) => {
            setIsFetchingOriginAccountOptions(false)
            setOriginAccountOptions(data)
        })
    }, [originCostCenterSelected])

    useEffect(() => {
        if (!destinationCostCenterSelected || destinationCostCenterSelected.length === 0) return
        setIsFetchingDestinationAccountOptions(true)

        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: `/budget-base/accounting-account?locale={locale}&client={client}&user={user}&organization={organization}&bu={businessUnit}&costCenter=${destinationCostCenterSelected}`,
        }, (data: Options[]) => {
            setIsFetchingDestinationAccountOptions(false)
            setDestinationAccountOptions(data)
        })
    }, [destinationCostCenterSelected])

    function compareOpenings(destinationList: TableData[]): boolean {
        const keySet = new Set();
        for (const item of destinationList) {
            const key = item.key;

            if (keySet.has(key)) {
                return true;
            }
            keySet.add(key);
        }
        return false;
    }

    async function handleSubmit(data) {        
        let updatedOriginValues = {} as TableData
        let formattedDestinationValues = {} as any
        let destinationFields = []
        Object.keys(data).forEach(key => {
            if (!data[key]) {
                delete data[key]
            } else if (key.includes('origin')) {
                Object.assign(updatedOriginValues, { [key.replace('origin-', '')]: data[key] })
            } else {
                destinationFields.push(key)
                Object.assign(formattedDestinationValues, { [key.replace('destination-', '')]: data[key] })
            }
        })
        let updatedDestinationValues = []
        if (formattedDestinationValues.costCenter) {
            formattedDestinationValues.costCenter.forEach(ccId => {
                updatedDestinationValues.push({ ...formattedDestinationValues, costCenter: ccId })
            })
        }
        const updatedDestinationTableData: TableData[] = formattedDestinationValues.costCenter ?
            updatedDestinationValues.map(destValue => createTableData(destValue, destinationAccountOptions, costCenterOptions, businessUnitOptions, flexFieldsDestinationOptions))
            : []

        let originData = {} as TableData
        if (updatedOriginValues.account) {
            const originTableData = createTableData(updatedOriginValues, originAccountOptions, costCenterOptions, businessUnitOptions, flexFieldsOriginOptions)
            originData = originTableData
        } else {
            originData = originTableData[0]
        }

        let isSameOrigin = false
        let isSameDestination = compareOpenings([...destinationTableData, ...updatedDestinationTableData])

        updatedDestinationTableData.forEach(destTableData => {
            if (originData.key === destTableData.key) {
                isSameOrigin = true
            }
        })

        switch (apportionmentType) {
            case ApportionmentType.PERIOD:
                if (isSameOrigin) {
                    sameOriginNotification()
                    return
                }
                break; 
            case ApportionmentType.HISTORIC:
                if (isSameOrigin) {
                    sameOriginNotification();
                    return;
                }
                break;
            default:
                break;
        }
        
        if (isSameDestination) {
            Notification({
                type: 'warning',
                message: i18n.t<string>('target_account_has_already_been_added')
            })
            return
        }

        const flexArray = Array(30)
        originData.flexFields.forEach(flexField => {
            const flexF = flexFieldsOriginOptions.find(flex => flex.value === flexField.flexFieldId)
            const position = flexF.fieldCode - 1
            const flexFilterValue = flexField.value
            flexArray[position] = flexFilterValue.toString()
        })
        const flexString = flexArray.join(':')
        try {
            const alreadyexists = originData.id ? { success: true, data: false } : await ServiceCaller.doAsyncRequest({
                type: RequestType.GET,
                url: '/expenses/apportionment/verify-apportionment-existence' +
                    '?scenario={scenario}' +
                    `&year=${moment(fiscalYear).year()}` +
                    '&organization={organization}' +
                    `&businessUnit=${originData.businessUnit.value}` +
                    `&costCenter=${originData.costCenter.value}` +
                    `&accountingAccount=${originData.account.value}` +
                    `&flexFieldFilter=${flexString.replaceAll(':', '%3A')}`,
            })
            if (alreadyexists.success) {
                if (!alreadyexists.data) {
                    switch (apportionmentType) {
                        case ApportionmentType.PERIOD:
                            apportionmentPeriod.forEach(month => {
                                updatedDestinationTableData.forEach(TableData => {
                                    Object.assign(TableData, { [month]: { value: 0, id: 0 } })
                                })
                            })
                            break;

                        case ApportionmentType.HEADCOUNT:
                            const destinations: TableData[] = [...destinationTableData, ...updatedDestinationTableData];
                            const { data, success } = await getHeadcountValues(originData, destinations, apportionmentType)

                            if (success) {
                                if (data.every(item => item.monthlyValues.length === 0)) {
                                    Notification({
                                        type: 'error',
                                        message: i18n.t<string>("there_are_no_headcount_hires")
                                    })
                                    return
                                }

                                data.forEach((opening) => {
                                    const flexIds = opening.flexFieldFilter.split(':').filter(element => element !== '').join('-')
                                    const openingKey = `${opening.costCenterId}-${opening.accountingAccountId}-${opening.businessUnitId}` + (flexIds.length > 0 ? `-${flexIds}` : '')
                                    const item = destinations.find(item => item.key === openingKey)
                                    opening.monthlyValues.forEach(month => {
                                        Object.assign(item, { [month.monthYear]: { value: month.value, id: month.id } })
                                    })
                                })
                            } else {
                                Notification({
                                    type: 'error',
                                    message: i18n.t<string>("there_are_no_headcount_hires")
                                })
                            }
                            break;
                        case ApportionmentType.HISTORIC:
                            break;
                        default:
                            break;
                    }

                    setOriginTableData([originData])
                    setDestinationTableData(prevState => [...prevState, ...updatedDestinationTableData])
                    setDestinationCollapsed(true)
                    setOriginCollapsed(true)
                    form.resetFields(destinationFields)
                } else {
                    Notification({
                        type: 'warning',
                        message: i18n.t<string>("this_account_already_has_apportionment")
                    })
                    return
                }
            } else {
                throw Error
            }

        } catch (error) {

        }
    }

    function sameOriginNotification() {
        Notification({
            type: 'warning',
            message: i18n.t<string>("origin_and_destination_must_be_different")
        })
    }

    function onChangeRowSelection(selectedRowKeys: React.Key[]) {
        setSelectedRowKeys(selectedRowKeys)
    }

    function handleEditDestinationData() {
        setDestinationCollapsed(false)

        const selectedApportiment = destinationTableData.find(item => item.key === selectedRowKeys[0])

        Object.keys(selectedApportiment).forEach(key => {
            if (key === 'costCenter') {
                const costCenter = costCenterOptions.find(costCenter => costCenter.value === selectedApportiment[key].value)
                form.setFieldsValue({ [`destination-${key}`]: [[costCenter.value]] })
                // } else if (key === 'account') {
                //     const account = destinationAccountOptions.find(account => account.label === selectedApportiment[key])
                //     form.setFieldsValue({[`destination-${key}`]: account.value})
            } else if (key === 'businessUnit') {
                const businessUnit = businessUnitOptions.find(businessUnit => businessUnit.value === selectedApportiment[key].value)
                form.setFieldsValue({ [`destination-${key}`]: businessUnit.value })
            } else if (key === 'flexFields' && selectedApportiment[key]?.length > 0) {
                selectedApportiment[key].forEach(flex => {
                    form.setFieldsValue({ [`destination-ff-${flex.flexFieldId}`]: flex.value })
                });
            }
        })
        setDestinationTableData(prevState => (
            prevState.filter(item => !selectedRowKeys.includes(item.key))
        ))
        setSelectedRowKeys([])
    }    
    function handleDelete() {

        if(budgetDates.period.filter(periodRealized => periodRealized.expensesResourcesRealizedPeriod).length > 0){
            Notification({
                type: 'warning',
                message: i18n.t<string>("period_realized_impossible_delete")
            })
            return
        }

        if (apportionmentType === ApportionmentType.HEADCOUNT) {
            const newDestinationTableData = destinationTableData.filter(item => !selectedRowKeys.includes(item.key))
            setDestinationTableData(newDestinationTableData)
            handleDeleteHeadcountData()
        }else{
            handleDeleteApportimentData()

        }
    }

    function generateFlexFieldString(tableData: TableData, flexFieldOptions): string {
        let flexFieldString = Array(30)

        if (tableData.flexFields.length > 0) {
            tableData.flexFields.forEach(flex => {
                const flexField = flexFieldOptions.find(f => f.value === flex.flexFieldId)
                const position = flexField.fieldCode - 1
                flexFieldString[position] = flex.value
            })
        }
        return flexFieldString.join(':')
    }
    async function handleDeleteHeadcountData() {
        setIsLoading(true);
        const tableData = destinationTableData.filter(item => selectedRowKeys.includes(item.key) && item.id)
        const dataId = tableData.map(item => item.id)
        const dataToSendDelete = {
            year: originTableData[0].year,
            destinationApportioments: destinationTableData
            .filter(item => !selectedRowKeys.includes(item.key))
            .map(item => {
                const monthlyValues = []
                Object.keys(item).forEach(key => {
                    if (/^\d/.test(key)) {
                        monthlyValues.push({
                            monthYear: key,
                            value: item[key].value,
                            id: item[key].id
                        })
                    }
                })
                if (monthlyValues.length === 0) {
                    apportionmentPeriod.forEach(month => {
                        monthlyValues.push({
                            monthYear: month,
                        })
                    })
                }
                const itemToSave = {
                    id: item.id,
                    businessUnitId: item.businessUnit.value,
                    costCenterId: item.costCenter.value,
                    accountingAccountId: item.account.value,
                    flexFieldFilter: generateFlexFieldString(item, flexFieldsDestinationOptions),
                    scenarioId: userInfo.selection.scenarioId,
                    organizationId: userInfo.selection.organizationId,
                    monthlyValues: monthlyValues
                }
                return itemToSave
            }), 
        }
        
        const { data, success } = await ServiceCaller.doAsyncRequest({
            type: RequestType.PUT,
            url: `/expenses/apportionment/destination/headcount?destinationApportionments=${dataId}&client={client}`,
            params: dataToSendDelete
        }) as { success: boolean, data: ApportionmentResponse['destinationApportioments'] }

        if (success) {
            if (data.every(item => item.monthlyValues.length === 0)) {
                Notification({
                    type: 'error',
                    message: i18n.t<string>("there_are_no_headcount_hires")
                })
                return
            }

            data.forEach((opening) => {
                const flexIds = opening.flexFieldFilter.split(':').filter(element => element !== '').join('-')
                const openingKey = `${opening.costCenterId}-${opening.accountingAccountId}-${opening.businessUnitId}` + (flexIds.length > 0 ? `-${flexIds}` : '')
                const item = destinationTableData.find(item => item.key === openingKey)
                opening.monthlyValues.forEach(month => {
                    Object.assign(item, { [month.monthYear]: { value: month.value, id: month.id } })
                })
            })
        } else {
            Notification({
                type: 'error',
                message: i18n.t<string>("there_are_no_headcount_hires")
            })
        }

        setIsLoading(false);
        
    }

    async function handleDeleteApportimentData() {
        setIsLoading(true)
        const isAllRowsSelected = selectedRowKeys.length === destinationTableData.length;
        const data = destinationTableData.filter(item => selectedRowKeys.includes(item.key) && item.id)
        if (data.length > 0) {
            const response = await ServiceCaller.doAsyncRequest<ApportionmentResponse[]>({
                type: RequestType.DELETE,
                url: `/expenses/apportionment/destination?destinationApportionments=${data.map(item => item.id)}`
            })
            if (response.success) {
                setDestinationTableData(prevState => (
                    prevState.filter(item => !selectedRowKeys.includes(item.key))
                ))
            } else {
                Notification({
                    type: 'error',
                    message: i18n.t<string>("unable_to_delete_destination")
                })
            }
        } else {
            setDestinationTableData(prevState => (
                prevState.filter(item => !selectedRowKeys.includes(item.key))
            ))

        }
        if (isAllRowsSelected) {
            setOriginTableData([])
            setDestinationTableData([])
            setIsApportionmentListVisible(true);
        }
        setSelectedRowKeys([])
        setIsLoading(false)
        fetchApportionments()
    }

    function onOpenTrasitoryAccountDrawer() {
        const updatedTableData = [...originTableData]
        const updatedItem = updatedTableData[0]

        if (updatedItem.transitoryApportionment) {
            Object.keys(updatedItem.transitoryApportionment).forEach(key => {
                if (key === 'costCenterId') {
                    const costCenter = costCenterOptions.find(costCenter => costCenter.value === updatedItem.transitoryApportionment[key])
                    form.setFieldsValue({ [key]: [[costCenter.value]] })
                } else if (key === 'accountingAccountId') {
                    const account = destinationAccountOptions.find(account => account.value === updatedItem.transitoryApportionment[key])
                    form.setFieldsValue({ [key]: account.value })
                } else if (key === 'flexFieldFilter') {
                    updatedItem.transitoryApportionment[key].split(':').forEach((flexId, index) => {
                        if (flexId) {
                            const flexField = flexFieldsOriginOptions.find(flex => flex.fieldCode === (index + 1))
                            form.setFieldsValue({ [`ff-${flexField.value}`]: Number(flexId) })
                        }
                    });
                }
            })
        }
        setIsTransitoryAccountOpen(true)
    }

    function handleApplyTransitory(data) {
        let formattedValues = {} as TableData
        Object.keys(data).forEach(key => {
            if (!data[key]) {
                delete data[key]
            } else {
                Object.assign(formattedValues, { [key.replace('origin-', '')]: data[key] })
            }
        })
        Object.assign(formattedValues, { businessUnit: originTableData[0].businessUnit.value })
        const transitoryData = createTableData(formattedValues, originAccountOptions, costCenterOptions, businessUnitOptions, flexFieldsOriginOptions)

        let isSameOpening = compareOpenings([transitoryData, ...destinationTableData, ...originTableData])

        if (isSameOpening) {
            Notification({
                type: 'warning',
                message: i18n.t<string>("transitory_account_diferent_from_origin_and_destination")
            })
            return
        }

        const updatedTableData = [...originTableData]
        const updatedItem = updatedTableData[0]
        const flexString = generateFlexFieldStringKey(data)

        updatedItem.transitoryApportionment = {
            scenarioId: userInfo.selection.scenarioId,
            organizationId: userInfo.selection.organizationId,
            businessUnitId: updatedItem.businessUnit.value,
            costCenterId: data.costCenterId,
            accountingAccountId: data.accountingAccountId,
            flexFieldFilter: flexString
        }
        setOriginTableData(updatedTableData)
        setIsTransitoryAccountOpen(false)
        Notification({
            type: 'success',
            message: i18n.t<string>("transitory_account_applied_successfully")
        })
    }

    function generateFlexFieldStringKey(data) {
        let flexFieldStringKey = Array(30)
        Object.keys(data).forEach(key => {
            if (key.includes('ff-')) {
                const flexFieldId = key.split('-')[1];
                const flexField = flexFieldsOriginOptions.find(flex => flex.value.toString() === flexFieldId)
                const position = flexField.fieldCode - 1
                flexFieldStringKey[position] = data[key]
            }
        })
        return flexFieldStringKey.join(':')
    }

    function handleSaveApportionment() {
        setIsLoading(true)
        setIsSaving(true)
        if (apportionmentType === ApportionmentType.PERIOD) {
            const totals = destinationTableData.reduce((acc, row) => {
                apportionmentPeriod.forEach(month => {
                    if (Object.keys(acc).includes(month)) {
                        acc[month] = acc[month] + (row[month].value as number)
                    } else {
                        Object.assign(acc, { [month]: row[month].value })
                    }
                })
                return acc
            }, {} as { [month: string]: number })
            if (Object.values(totals).some(total => total > 100)) {
                Notification({
                    type: 'warning',
                    message: i18n.t<string>("wrong_percentage_value_for_a_given_month")
                })
                setIsSaving(false)
                setIsLoading(false)
                return
            }
        }

        const dataToSave = createPostPayload(
            originTableData,
            destinationTableData,
            apportionmentType,
            userInfo,
            flexFieldsOriginOptions,
            flexFieldsDestinationOptions
        )

        if (originTableData[0].transitoryApportionment) {
            Object.assign(dataToSave, { transitoryApportionment: originTableData[0].transitoryApportionment })
        }

        ServiceCaller.doRequest({
            type: RequestType.POST,
            url: `/expenses/apportionment?scenario={scenario}&organization={organization}`,
            params: dataToSave
        }, (data) => {
            setOriginTableData([])
            setDestinationTableData([])
            setIsApportionmentListVisible(true)
            setIsSaving(false)
            setIsLoading(false)
            setDestinationCollapsed(false)
            setOriginCollapsed(false)
            Notification({
                type: 'success',
                message: i18n.t("successfully_saved")
            })
            fetchApportionments()
        })
    }

    async function getHeadcountValues(origin, destination, apportionmentType) {
        const dataToSend = createPostPayload(
            [origin],
            destination,
            apportionmentType,
            userInfo,
            flexFieldsOriginOptions,
            flexFieldsDestinationOptions,
            apportionmentPeriod
        )
        return await ServiceCaller.doAsyncRequest({
            type: RequestType.POST,
            url: '/expenses/apportionment/headcount-values?client={client}',
            params: dataToSend
        }) as { success: boolean, data: ApportionmentResponse['destinationApportioments'] }
    }

    async function onChangeApportionmentType(type: ApportionmentType) {
        const updatedDestinationTableData = [...destinationTableData]
        setApportionmentType(type)

        switch (type) {
            case ApportionmentType.PERIOD:
                apportionmentPeriod.forEach(month => {
                    updatedDestinationTableData.forEach(tableData => {
                        tableData[month] = 0
                    })
                })
                break;
            case ApportionmentType.HEADCOUNT:
                const { data, success } = await getHeadcountValues(originTableData[0], updatedDestinationTableData, ApportionmentType.HEADCOUNT)
                if (success) {
                    data.forEach((opening) => {
                        const flexIds = opening.flexFieldFilter.split(':').filter(element => element !== '').join('-')
                        const openingKey = `${opening.costCenterId}-${opening.accountingAccountId}-${opening.businessUnitId}` + (flexIds.length > 0 ? `-${flexIds}` : '')
                        const item = updatedDestinationTableData.find(item => item.key === openingKey)
                        opening.monthlyValues.forEach(month => {
                            item[month.monthYear] = month.value
                        })
                    })
                } else {
                    setApportionmentType(ApportionmentType.PERIOD)
                    Notification({
                        type: 'error',
                        message: i18n.t("something_went_wrong")
                    })
                }
                break;
            case ApportionmentType.HISTORIC:

                break;
            default:
                break;
        }
        setDestinationTableData(updatedDestinationTableData)
    }

    const width =
        originCollapsed && destinationCollapsed ? 120 :
            !originCollapsed && destinationCollapsed ? 346 :
                originCollapsed && !destinationCollapsed ? 346 :
                    560

    return (
        <>
            <ApportionmentForm
                openCalendarPeriod={openCalendarPeriod}
                handleSubmit={handleSubmit}
                form={form}
                costCenterOptions={costCenterOptions}
                originAccountOptions={originAccountOptions}
                destinationAccountOptions={destinationAccountOptions}
                businessUnitOptions={businessUnitOptions}
                isFetchingOriginAccountOptions={isFetchingOriginAccountOptions}
                isFetchingDestinationAccountOptions={isFetchingDestinationAccountOptions}
                flexFieldsOriginOptions={flexFieldsOriginOptions}
                setFlexFieldsOriginOptions={setFlexFieldsOriginOptions}
                flexFieldsDestinationOptions={flexFieldsDestinationOptions}
                setFlexFieldsDestinationOptions={setFlexFieldsDestinationOptions}
                originCollapsed={originCollapsed}
                setOriginCollapsed={setOriginCollapsed}
                destinationCollapsed={destinationCollapsed}
                setDestinationCollapsed={setDestinationCollapsed}
                apportionmentType={apportionmentType}
                setApportionmentType={setApportionmentType}
                originTableData={originTableData}
                destinationTableData={destinationTableData}
                setIsTransitoryAccountOpen={setIsTransitoryAccountOpen}
                setSelectedRowKeys={setSelectedRowKeys}
                onChangeApportionmentType={onChangeApportionmentType}
            />
            <section className="apportionments-list-section" style={{ width: '100%' }}>
                <NewApportionmentList
                    originTableData={originTableData}
                    destinationTableData={destinationTableData}
                    setDestinationTableData={setDestinationTableData}
                    selectedRowKeys={selectedRowKeys}
                    onChange={onChangeRowSelection}
                    handleEditDestination={handleEditDestinationData}
                    handleDelete={handleDelete}
                    apportionmentType={apportionmentType}
                    apportionmentPeriod={apportionmentPeriod}
                    onOpenTrasitoryAccountDrawer={onOpenTrasitoryAccountDrawer}
                    width={width}
                    costCenterOptions={costCenterOptions}
                    isLoading={isLoading}
                    budgetDates={budgetDates}
                    setIsOpenDeletePopover={setIsOpenDeletePopover}
                    isOpenDeletePopover={isOpenDeletePopover}
                    setIsApportionmentListVisible={setIsApportionmentListVisible}
                    setOriginTableData={setOriginTableData}
                />
                <TransitoryAccount
                    isTransitoryAccountOpen={isTransitoryAccountOpen}
                    setIsTransitoryAccountOpen={setIsTransitoryAccountOpen}
                    costCenterOptions={costCenterOptions}
                    originAccountOptions={originAccountOptions}
                    flexFieldsOriginOptions={flexFieldsOriginOptions}
                    setFlexFieldsOriginOptions={setFlexFieldsOriginOptions}
                    isFetchingOriginAccountOptions={isFetchingOriginAccountOptions}
                    businessUnitId={selectedRowKeys.length > 0 && selectedRowKeys[0].split('-')[2]}
                    handleSubmit={handleApplyTransitory}
                    form={transitoryAccountForm}
                />
                <footer style={{ width: windowSize.width - width - 35 }}>
                    <Button
                        className="gs-main-button"
                        onClick={handleSaveApportionment}
                        loading={isSaving}
                        disabled={isSaving || destinationTableData.length === 0}
                    >
                        {i18n.t('apply')}
                    </Button>
                </footer>
            </section>
        </>
    )
}