import { Button } from "antd";

import { Icon } from "@iconify/react";
import { IRuleComponent } from "../../IAttributeParameterization";

export function Condition({ children, index, handleRemove }: IRuleComponent) {
    return (
		<div className="attribute_parameterization-new-attribute-modal-rule" key={index}>
            {children}

            <div className="attribute_parameterization-new-attribute-modal-rule-options">
                <Button
                    className="top-tool-buttons edit"
                    icon={<Icon icon="mdi:trash" />}
                    style={{
                        border: 0,
                        background: "transparent",
                        outline: "none",
                        boxShadow: "none",
                    }}
                    onClick={() => handleRemove(index)}
                />
            </div>
        </div>
    );
}
