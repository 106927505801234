import { Icon } from "@iconify/react";
import { Checkbox, Col, DatePicker, Form, Modal, Row } from "antd";
import { useForm } from "antd/es/form/Form";
import i18n from "util/base/i18n";
import { IDatePickerDefaultProps } from "../../revenue/recurringContract/IRecurringContract";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import StringUtil from "util/StringUtil";
import moment from "moment";
import { Notification } from "components/notification/Notification";
import { useUserContext } from "context/UserContext";
import { useState } from "react";
import { ICalculateRulesFormValues } from "../IDistributionRule";

export interface Props {
	isModalOpen: boolean;
	setIsPopoverOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
export default function CalculateRulesModal({
	isModalOpen,
	setIsPopoverOpen
}: Props) {
	const defaultDatePickerProps: IDatePickerDefaultProps = {
		picker: "month",
		suffixIcon: <Icon icon="fa6-solid:calendar" />,
		allowClear: true,
		format: "MMM/YYYY",
		className: "replicate-scenario-month",
	}
	const { userInfo: { id, selection: { businessUnitId, scenarioId, organizationId }, clientId, localeId } } = useUserContext();
	const [form] = useForm<ICalculateRulesFormValues>();
	const startDateSelected = Form.useWatch('startPeriod', form);
	const finalDateSelected = Form.useWatch('endPeriod', form);
	const [isSaving, setIsSaving] = useState<boolean>(false);

	const handleCloseModal = () => {
		setIsPopoverOpen(false);
		setIsSaving(false);
	}

	const handleSave = (formValues: ICalculateRulesFormValues) => {
		setIsSaving(true);
		const startPeriod: string = StringUtil.capitalizeFirstLetter(moment(formValues.startPeriod).startOf('month').format("YYYY-MM-DD"));
		const endPeriod: string = StringUtil.capitalizeFirstLetter(
			moment(formValues.endPeriod).endOf('month').format("YYYY-MM-DD")
		);
		
		ServiceCaller.doRequest({
			type: RequestType.POST,
			url: "/software-house/distribution-rule/calculate",
			params: {
				...formValues,
				startPeriod,
				endPeriod,
				userId: id,
				organizationIds: [organizationId],
				businessUnitIds: [businessUnitId],
				scenarioId: scenarioId,
				clientId,
				localeId,
				type: "MANUAL"
			}
		}, onSave, handleErrorRequest)
	}

	const onSave = () => {
		Notification({
			type: "info",
			message: i18n.t("calculation.started")
		})

		handleCloseModal();
	}

	const disableDate = (date: moment.Moment, isInitialDate: boolean = true) => {
		const isDisable = isInitialDate
			? finalDateSelected && date.isSameOrAfter(finalDateSelected)
			: startDateSelected && date.isSameOrBefore(startDateSelected);
		return isDisable;
	}

	return (
		<Modal
			open={isModalOpen}
			onCancel={handleCloseModal}
			title={i18n.t("distribution_rules.title")}
			okButtonProps={{
				className: "gs-main-button",
				htmlType: "submit",
				form: "calculate-rules-form",
				icon: <Icon icon="ph:calculator-bold" />,
				loading: isSaving
			}}
			okText={i18n.t<string>("calculate")}
			className="gs-modal rule-distribution-modal"
			centered
			width={400}
			cancelButtonProps={{ type: "text" }}
			cancelText={i18n.t<string>("close")}
			afterClose={() => form.resetFields()}
		>
			<Form
				form={form}
				name="calculate-rules-form"
				onFinish={handleSave}
				layout="vertical"
			>
				<Row gutter={10}>
					<Col span={12}>
						<Form.Item
							label={i18n.t("start")}
							name={"startPeriod"}
							rules={[{ required: true, message: i18n.t<string>("required_field") }]}
						>
							<DatePicker
								{...defaultDatePickerProps}
								disabledDate={date => disableDate(date)}
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							label={i18n.t("end")}
							name={"endPeriod"}
							rules={[{ required: true, message: i18n.t<string>("required_field") }]}
						>
							<DatePicker
								{...defaultDatePickerProps}
								disabledDate={date => disableDate(date, false)}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col >
						<Row>
							<Form.Item
								valuePropName="checked"
								name={"planned"}
								initialValue={true}
							>
								<Checkbox>
									{i18n.t("planned")}
								</Checkbox>
							</Form.Item>
						</Row>
						<Row>
							<Form.Item
								valuePropName="checked"
								initialValue={true}
								name={"accomplished"}
							>
								<Checkbox>
									{i18n.t("realized")}
								</Checkbox>
							</Form.Item>
						</Row>
					</Col>
				</Row>
			</Form>
		</Modal>
	)
}