import { Key } from "react";
import { FlexFilterOptions, Options, PaginationResponse } from "util/types/types";
import { FormInstance, TablePaginationConfig } from "antd";
import { ColumnsType } from "antd/lib/table";
import { GenericSelection } from "../../flexField/IFlexField";
import { IMultipleSearch } from "components/input/search/IMultipleSearch";

export enum ContractType {
	RECURRING = "RECURRING",
	NOT_RECURRING = "NOT_RECURRING",
	NEW_SALES = "NEW_SALES",
	CHURN = "CHURN",
	NEW_NEGATIVE_SALES = "NEW_NEGATIVE_SALES",
	NEW_SALES_SMS = "NEW_SALES_SMS",
}

export enum PeriodicityType {
	MONTHLY,
	BIMONTHLY,
	QUARTERLY,
	SEMESTERLY,
	YEARLY
}

export interface AllContractTableData {
	contract: string;
	basePeriod: string;
	contractType: ContractType;
	periodicity: PeriodicityType;
	value?: number;
	flexFieldValueItens: GenericDisplayBean[];
	[param: string]: string | any;
}

export interface IRecurringContractCommon {
	id?: number;
	contractDescription?: string;
	value?: number;
	contractType?: ContractType;
	periodBase?: string;
	periodicityType?: PeriodicityType;
	flexFieldValueItens?: GenericDisplayBean[];
	recurringContractValues?: RecurringContractValue[];
	indexerDescription?: string;
	contractStatus?: ContractStatus;
	startPeriod?: string;
	endPeriod?: string;
}

export interface IRecurringContractResponse extends PaginationResponse {
	content: IRecurringContractCommon[];
}

export interface GenericDisplayBean {
	name: string;
	id: number;
}

interface RecurringContractValue {
	period: string;
	value: number;
	readjustment: number;
	tax: number;
	liquidRevenue: number;
}

export enum ContractStatus {
	ACTIVE,
	CANCELED
}

export interface IAllContractTableProps {
	tableData: AllContractTableData[];
	selectedRowKeys: Key[];
	onChange: (selectedRowKeys: Key[], selectedRows: AllContractTableData[]) => void;
	isLoading: boolean;
	pagination: TablePaginationConfig;
	flexFieldValues: FlexFilterContract[];
	columns: ColumnsType<AllContractTableData>;
	setColumns: React.Dispatch<React.SetStateAction<ColumnsType<AllContractTableData>>>;
}

export interface ContractToEdit {
	monthLiquidRevenue: { [key: string]: number };
	id?: number;
	contractDescription: string;
	contractType: string;
	flexFieldValueItens: GenericDisplayBean[];
	flexFieldValueSmsItens: GenericDisplayBean[];
	monthValues: { [key: string]: number };
	monthValuesSms: { [key: string]: number };
	monthTaxes: { [key: string]: number };
	periodBase: string;
	readjustDate: string;
	startPeriod: string;
	endPeriod: string;
	indexerId: number;
	value: number;
	periodicityType?: PeriodicityType;
	externalCode: string;
	recurringContractValues?: RecurringContractValue[];
	gracePeriodSms?: number;
	rateSms?: number;
}

export interface IValueViewData {
	competencyDate: string;
	value: string;
	tax?: string;
	readjustment?: string;
}

export interface IValueViewProps {
	contractId: Key;
	setContractId: React.Dispatch<React.SetStateAction<Key>>;
	period: moment.Moment;
}

export interface IContractModalProps {
	isOpen: boolean;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
	periodFrozen: moment.Moment;
	contractToEdit: ContractToEdit;
	setContractToEdit: React.Dispatch<any>;
	flexFieldValues: FlexFilterContract[];
	basePeriod: moment.Moment;
	isNewContract: boolean;
}


export interface EditableCellProps {
	title: React.ReactNode;
	editable: boolean;
	children: React.ReactNode;
	dataIndex: keyof IValuePeriodTableData;
	record: IValuePeriodTableData;
	onCell: (a: any) => any;
	handleSave: (record: IValuePeriodTableData, key: string) => void;
}

export interface IValuePeriodTableProps {
	period: [moment.Moment, moment.Moment];
	value?: number;
	isNotRecurring: boolean;
	periodicityTypeSelected: PeriodicityType;
	setMonthsValues: React.Dispatch<React.SetStateAction<{ [value: string]: number; }>>;
	monthsForEdit: { [value: string]: number };
	periodFrozen: moment.Moment;
	isDisable: boolean;
	basePeriod: moment.Moment;
}
export interface IDatePickerDefaultProps {
	picker: "week" | "month" | "quarter" | "year" | "date";
	className: string;
	suffixIcon: JSX.Element;
	allowClear: boolean;
	format: string;
}

export interface IColumnsProps {
	key?: string | number;
	dataIndex?: string | number;
	title?: string;
	render?: (value: any, record: any) => any;
	width?: number;
	align?: string;
	editable?: boolean;
}

export interface IValuePeriodTableData {
	months: string;
	key: string;
	[param: string]: number | string;
}

export const PeriodicityTypeValues = {
	MONTHLY: 1,
	BIMONTHLY: 2,
	QUARTERLY: 3,
	FOURMONTHLY: 4,
	SEMESTERLY: 6,
	YEARLY: 12
}

export interface IHeaderInfoTagProps {
	title: string;
	value: string;
}

export interface IFlexContentProps {
	flexFieldValues: FlexFilterContract[];
	isNewSales: boolean;
	isDisable: boolean;
}

export interface IFlexFieldItensProps {
	flexFieldValues: FlexFilterContract[];
	isDisable: boolean;
}


export interface IFlexFieldsSelectProps {
    label: string;
    values: Options[];
    id: number;
    fieldCode: string;
    defaultValue?: number;
	isDisabled: boolean;
}

export interface FlexFilterContract {
	fieldCode?: string;
	ordenation?: number;
	selectedOption?: Options[];
	isFetching?: boolean;
	value: number;
	label: string;
	linkedFilters?: string[];
	children?: FlexFilterOptions[];
	defaultValue?: number;
	id: number;
}

export interface INewContractContextProps {
	form: FormInstance<any>;
	filterFlexFieldHasValue: (formValues: {}) => number[];
	filterFlexFieldSmsHasValue: (formValues: {}) => number[];
	optionsContractType: GenericSelection[];
	setOptionsContractType: React.Dispatch<React.SetStateAction<GenericSelection[]>>;
}

export interface IRevenuePeriodsResponse {
	periodBase: string;
	periodFrozen: string;
	periodRealized: string;
	isBlocked: boolean;
}

export enum ISearchType {
	CONTRACT_DESCRIPTION,
	CONTRACT_TYPE,
	PERIODICITY
}

export interface IRecurringMultipleSearch extends IMultipleSearch {
    doRequest: (
        page?: number,
        pageSize?: number,
        flexFieldValueIds?: number[],
        isFilterModal?: boolean,
        search?: string,
        searchType?: ISearchType,
        contractType?: string,
        periodicityTypes?: string
    ) => void;
    searchType: string;
    flexFieldValueIds: number[];
    contractType?: string;
    searchContract?: string;
    searchPeriodicity?: string;
    searchDescrition?: string;
    searchExternalCode?: string;
    setContractType?: (value: string) => void;
    setSearchType?: (value: string) => void;
    setSearchContract?: (value: string) => void;
    setSearchPeriodicity?: (value: string) => void;
    setSearchDescrition?: (value: string) => void;
    setSearchExternalCode?: (value: string) => void;
}

export interface ITypeCalculate {
	checked?: boolean;
    label: string;
    options?: string[];
    type: string;
    value?: string | null;
}

export interface ITypeContract {
	label: string
}