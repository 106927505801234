import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { tableProps } from "util/props/props";
import { IndexerType, IProjectionGrouperData } from "../IProjectionGroupers";
import i18n from "util/base/i18n";
import { useGroupersContext } from "./GroupersContext";


export default function GroupersGrid() {
	const {
		isGridFetching,
		tableData,
		indexerList,
		getIndexerTypeDescription,
		rowSelection
	} = useGroupersContext();
	const columns: ColumnsType<IProjectionGrouperData> = [
		{
			dataIndex: "externalCode",
			key: "externalCode",
			title: i18n.t("external_code"),
		},
		{
			dataIndex: "description",
			key: "description",
			title: i18n.t("description"),
		},
		{
			dataIndex: "indexerType",
			key: "indexerType",
			title: i18n.t("projection_grouper.indexer_type"),
			render: (value: IndexerType) => getIndexerTypeDescription[value]
		},
		{
			dataIndex: "indexerId",
			key: "indexerId",
			title: i18n.t("indexer"),
			render: (indexerId) => {
				const indexerDescription: string = indexerList.find(({ value }) => value == indexerId)?.label;
				return indexerDescription;
			}
		},
	]
	return (
        <Table
            {...tableProps}
            columns={columns}
            loading={isGridFetching}
            dataSource={tableData}
            rowKey={"id"}
            rowSelection={rowSelection}
            pagination={{
                showSizeChanger: true,
                hideOnSinglePage: tableData?.length < 10 ? true : false,
                defaultPageSize: 20,
			}}
        />
    );
}