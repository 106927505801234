//@ts-nocheck
import { ServiceCaller } from "util/service/ServiceCaller";
import DetailTableContainer from "./components/DetailTableContainer";
import { useEffect, useState } from "react";
import { RequestType } from "util/service/IServiceCaller";
import { IDetail, ViewModeSelected } from "./IDetail";
import moment from "moment";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { cloneDeep } from "lodash";
import { Carousel } from "antd";
import ContributorInformation from "module/budget/pages/humanResources/hiredDashboard/components/contributorInformation/ContributorInformation";
import { useWindowSize } from "hooks/useWindowSize";
import { carouselDefaultProps } from "components/carousel/ICarousel"
import './Detail.sass';
import { BackButton } from "components/button/BackButton";

export default function Detail({
    detailId,
    investmentSelected,
    handleOpenModal,
    detailTableData,
    setDetailTableData,
    isModalVisible,
    form,
    flexFieldsOptions,
    setFlexFieldsOptions,
    currenciesList,
    loadInvestmentDetail,
    detailData,
    isFetching,
    setIsFetching,
    handleClickBackButton,
    viewModeSelected,
    setViewModeSelected,
    periodFilter,
    configurations,
    controlPanelPeriods,
    controlPanelValues,
    setToggle
}: IDetail) {
    const [filtersPerRow, setFiltersPerRow] = useState(5);
    const [flexFieldsCarousel, setFlexFieldsCarousel] = useState([]);
    const windowSize = useWindowSize();

    useEffect(() => {
        setIsFetching(true);
        loadInvestmentDetail();
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: `/budget-base/flex-field/find-all-by-module?module=EXPENSES`
        }, onLoadDetailFlexField.bind(this), (err: ErrorRequest) => {
            handleErrorRequest(err);
        });
    }, []);

    useEffect(() => {
        const updatedFiltersPerRow = Math.floor((windowSize.width - 515) / 280)
        setFiltersPerRow(updatedFiltersPerRow);
    }, [windowSize]);

    useEffect(() => {

        if (flexFieldsOptions.length < 1) return;

        const flexIds = Object.keys(investmentSelected)
            .filter(item => !isNaN(item))
            .map(Number);

        const ffChildObj = flexIds.map(id => {
            const ffChildValue = investmentSelected[id];
            const flexField = flexFieldsOptions.find(ff => ff.value === id);
            const children = flexField?.children.find(item => item.value === ffChildValue);

            return {
                flexFieldName: flexField?.label ? flexField?.label : "",
                flexFieldValueName: children?.label ? children?.label : ""
            };
        });

        setFlexFieldsCarousel(ffChildObj);

    }, [flexFieldsOptions]);

    useEffect(() => {

        if (Object.keys(detailData).length === 0) {
            setDetailTableData([]);
            return;
        };
        const condition = viewModeSelected === ViewModeSelected.TEMPLATE;

        const data = condition ? [...detailData?.templates] : [detailData?.accounting];

        if (data[0] !== null) {
            const updateTableData = data.map(item => {

                const months = item.months.reduce((acc, value, index) => {
                    const currentMonth = `${moment(periodFilter[0]).year()}-${String(index + 1).padStart(2, '0')}`;
                    Object.assign(acc, { [currentMonth]: value })
                    return acc;
                }, {});

                return {
                    key: condition ? item.templateId : item.accountingId,
                    description: condition ? item.templateDescription : item.accountingDescription,
                    ...months
                }
            });
            setDetailTableData(updateTableData);
        } else {
            setDetailTableData([]);
        }

    }, [detailData, viewModeSelected]);

    async function handleSaveDetailValue(data, month: string) {
        const isNew = data[month].id ? false : true
        const dataToSave = {
            id: data[month].id,
            headerId: detailId,
            templateId: viewModeSelected === ViewModeSelected.TEMPLATE ? data.key : null,
            quantity: data[month].quantity,
            unityValue: data[month].unityValue,
            totalValue: data[month].totalValue,
            activationDate: data[month].activationDate,
        }


        if (isNew) {
            Object.assign(dataToSave, { acquisitionDate: moment(month, 'YYYY-MM').date(1).format('YYYY-MM-DD') })
        }

        const response = await ServiceCaller.doAsyncRequest({
            type: isNew ? RequestType.POST : RequestType.PUT,
            url: `/investment/${isNew ? 'save-opening' : 'update-opening'}`,
            params: dataToSave
        })

        if (response.success) {
            const updatedData = cloneDeep(detailTableData)
            const detail = updatedData.find(item => item.key === data.key)
            detail[month].id = response.data.id
            detail[month].quantity = response.data.quantity
            detail[month].unityValue = response.data.unityValue
            detail[month].totalValue = response.data.totalValue
            detail[month].activationDate = response.data.activationDate
            setDetailTableData(updatedData)
        } else {
            handleErrorRequest(response.data)
        }
    }

    async function onLoadDetailFlexField(data: FlexFieldFilterResponse[]) {
        const flexField: FlexFilter[] = data
            .sort((a, b) => a.ordenation - b.ordenation)
            .map(item => {
                return {
                    label: item.description,
                    value: item.id,
                    linkedFilters: item.filters ? item.filters : null,
                    ordenation: item.ordenation,
                    fieldCode: Number(item.fieldCode.split('_').pop()),
                    selectedOption: [],
                    children: []
                }
            });
        try {
            const promises = flexField.map(ff => {
                return new Promise<void>((resolve, reject) => {
                    ServiceCaller.doRequest({
                        type: RequestType.GET,
                        url: `/budget-base/flex-field-value?user={user}&flexFieldId=${ff.value}`
                    }, (data: FlexFieldValueListBean[]) => {
                        ff.children = data.map((item: FlexFieldValueListBean) => ({
                            value: item.id,
                            label: item.externalCode + " - " + item.description,
                            flexFieldId: item.flexFieldId,
                        }));
                        return resolve();
                    }, (err) => {
                        handleErrorRequest(err)
                        return reject()
                    })
                })
            })
            await Promise.all(promises)
        } catch (error) {
            handleErrorRequest(error)
        }
        setFlexFieldsOptions(flexField);
    }

    return (
        <div style={{ borderTop: "1px solid #D5D5D5" }}>
            <div className="detail-info" >
                <div>
                    <h3 style={{ color: "#717171", minWidth: 250, fontSize: 18 }}>{detailData?.accounting?.accountingDescription}</h3>
                    <BackButton onGoBack={handleClickBackButton} />
                </div>
                <Carousel
                    className="gs-carousel carousel-infos"
                    {...carouselDefaultProps}
                    slidesPerRow={filtersPerRow}
                >
                    <ContributorInformation title="business_unit" value={investmentSelected.businessUnitDescription} />
                    <ContributorInformation title="cost_center" value={investmentSelected.costCenterDescription} />
                    <ContributorInformation title="investment.accounting" value={investmentSelected.accountingDescription} />
                    <ContributorInformation title="currency" value={currenciesList.find(item => item.value === investmentSelected.currencyId)?.label} />
                    {flexFieldsCarousel.length > 0 &&
                        flexFieldsCarousel.map(({ flexFieldName, flexFieldValueName }) => {
                            return (
                                <ContributorInformation key={flexFieldName} title={flexFieldName} value={flexFieldValueName} />
                            )
                        })
                    }
                </Carousel>
            </div>
            <section className="investment-section" style={{ padding: "10px 10px 0" }}>
                <DetailTableContainer
                    detailTableData={detailTableData}
                    setDetailTableData={setDetailTableData}
                    isFetching={isFetching}
                    setIsFetching={setIsFetching}
                    viewModeSelected={viewModeSelected}
                    setViewModeSelected={setViewModeSelected}
                    handleSaveDetailValue={handleSaveDetailValue}
                    handleOpenModal={handleOpenModal}
                    isModalVisible={isModalVisible}
                    investmentSelected={investmentSelected}
                    form={form}
                    detailId={detailId}
                    loadInvestmentDetail={loadInvestmentDetail}
                    detailData={detailData}
                    configurations={configurations}
                    controlPanelPeriods={controlPanelPeriods}
                    controlPanelValues={controlPanelValues}
                    setToggle={setToggle}
                />
            </section>
        </div>
    )
}