import { Form, Input, Tooltip } from "antd";
import type { InputRef } from "antd";
import type { FormInstance } from "antd/es/form";
import "./GridContainer.sass";
import { createContext, Key, useContext, useEffect, useRef, useState, useMemo, memo } from "react";
import {
    EditableCellProps,
    EditableRowProps,
    DetailData,
    IGrid,
    BudgetGridData,
    RowType,
} from "../../IFixedExpense";
import { CommentsModal } from "./components/CommentsModal";
import { validateMonetaryInput } from "util/functions/validateKey";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import moment from "moment";
import { GridOperations } from "./components/GridOperations";
import { DistributionModal } from "./components/distributionModal/DistributionModal";
import { useUserContext } from "context/UserContext";
import { useBudgetOpeningContext } from "../../context/useBudgetOpeningContext";
import { Notification } from "components/notification/Notification";
import { exportGrid } from "./functions/exportGrid";
import { Grid } from "./components/Grid";
import { cloneDeep } from "lodash";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { createGridColumns } from "./utils/createGridColumns";
import { updateParentRowValues } from "./functions/updateParentRowValues";
import { Icon } from "@iconify/react";
import i18n from "util/base/i18n";
import { IndexerModal } from "./components/IndexerModal";
import { ModuleType, Options } from "util/types/types";
import { verifyBudgetPeriod } from "util/functions/verifyBudgetPeriod";
import { DetailModal } from "./components/detailModal/DetailModal";
import { addDetailRow } from "./functions/addDetailRow";

export function GridContainer({ saveStatus, handleSavePlannedValue, isFetching, setIsFetching, handleCreateBudgetOpeningAccount, handleCreateBudgetOpening, functionalityPermissions }: IGrid) {
    const [budgetOpening, setBudgetOpening] = useState<BudgetGridData>({} as BudgetGridData);
    // const [columns, setColumns] = useState<Column[]>([]);
    const [monthAmount, setMonthAmount] = useState(0);
    const [commentsModalOpen, setCommentsModalOpen] = useState(false);
    const [detailModalOpen, setDetailModalOpen] = useState(false);
    const [checkedList, setCheckedList] = useState(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
    const [expandedRowKeys, setExpandedRowKeys] = useState<Key[]>([]);
    const [searchValue, setSearchValue] = useState("");
    const [isCurrencyButtonDisabled, setisCurrencyButtonDisabled] = useState(true);
    const [isAddDetailButtonDisabled, setIsAddDetailButtonDisabled] = useState(true);
    const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(true);
    const [isOpenAccountDetailPopover, setIsOpenAccountDetailPopover] = useState(false);
    const [isOpenCurrenciesPopover, setIsOpenCurrenciesPopover] = useState(false);
    const [isOpenCurrencyPopoverDetailModal, setIsOpenCurrencyPopoverDetailModal] = useState(false);
    const [isOpenDeletePopover, setIsOpenDeletePopover] = useState(false);
    const [isOpenViewModePopover, setIsOpenViewModePopover] = useState(false);
    const [comment, setComment] = useState("");
    const EditableContext = createContext<FormInstance<any> | null>(null);
    const commentTextArea = useRef(null);
    const [isDistributionModalOpen, setIsDistributionModalOpen] = useState(false);
    const [isDistributionButtonDisabled, setIsDistributionButtonDisabled] = useState(true);
    const [isIndexerModalOpen, setIsIndexerModalOpen] = useState(false);
    const [isIndexerButtonDisabled, setIsIndexerButtonDisabled] = useState(true);
    const { userInfo } = useUserContext();
    const [viewModeChecked, setViewModeChecked] = useState([]);
    const [isOutOfPeriod, setIsOutOfPeriod] = useState(false);
    const inputRefs = useRef({});
    const {
        budgetGridData,
        setBudgetGridData,
        currenciesOptions,
        indexerOptions,
        period,
        selectedRows,
        setSelectedRows,
        budgetPeriodDates,
        setAccountAccounting,
        accountAccountingOptions,
        spendingPackage,
        plannedMonthsMock,
        isQueryByPackage,
        viewModeSelected,
        setViewModeSelected,
        columns,
        setColumns
    } = useBudgetOpeningContext();

    useEffect(() => {
        if (budgetGridData.length === 0) return;
        const monthArray = [];
        let currentDate = cloneDeep(period[0]);

        while (currentDate.isSameOrBefore(period[1], "month")) {
            monthArray.push(currentDate.format("YYYY-MM"));
            currentDate.add(1, "month");
        }

        if (Object.keys(budgetGridData[0].plannedMonths).length >= monthArray.length) {
            const { columnData, updatedMonthAmount } = createGridColumns(
                budgetGridData,
                onOpenCommentModal,
                onOpenDetailModal,
                viewModeSelected,
                budgetPeriodDates,
                monthArray,
                indexerOptions,
                spendingPackage,
                isQueryByPackage
            );
            setColumns(columnData as any);
            setMonthAmount(updatedMonthAmount);
            setIsFetching(false);
        }
    }, [
        budgetGridData,
        budgetPeriodDates,
        viewModeSelected,
        indexerOptions,
        spendingPackage,
        isQueryByPackage,
    ]);

    useEffect(() => {
        if (budgetPeriodDates.period?.length === 0) return;
        let updatedValidator = false;
        if (
            (budgetPeriodDates.period[0].expensesResourcesOpeningStartDate &&
                moment(budgetPeriodDates.localDate).isBefore(
                    moment(budgetPeriodDates.period[0].expensesResourcesOpeningStartDate).startOf("day")
                )) ||
            (budgetPeriodDates.period[0].expensesResourcesOpeningEndDate &&
                moment(budgetPeriodDates.localDate).isAfter(
                    moment(budgetPeriodDates.period[0].expensesResourcesOpeningEndDate).startOf("day")
                ))
        ) {
            updatedValidator = true;
        }
        setIsOutOfPeriod(updatedValidator);
    }, [budgetPeriodDates]);

    async function handleAddCurrencyAccountRow(currencyId: number, data = selectedRows) {
        setIsOpenCurrenciesPopover(false);
        setIsOpenCurrencyPopoverDetailModal(false);
        setIsFetching(true);
        const updatedBudgetGridRow = data[0];

        let detailDataToSave = [
            {
                description: `openingInOtherCurrency:${updatedBudgetGridRow.accounting}`,
                externalCode: `budgetOpeningId:${updatedBudgetGridRow.budgetOpening.id}:${currencyId}`,
                organizationId: userInfo.selection.organizationId,
                currencyId: currencyId,
            },
        ];
        if (updatedBudgetGridRow.detailList.length === 0) {
            detailDataToSave.unshift({
                description: `openingInOtherCurrency:${updatedBudgetGridRow.accounting}`,
                externalCode: `budgetOpeningId:${updatedBudgetGridRow.budgetOpening.id}:${userInfo.currencyId}`,
                organizationId: userInfo.selection.organizationId,
                currencyId: Number(userInfo.currencyId),
            });
        }

        await ServiceCaller.doAsyncRequest<DetailData[]>({
            type: RequestType.POST,
            url: "/expenses/detail",
            useProxy: true,
            params: detailDataToSave,
        }).then((res) => {
            handleAddNewDetail(res.data, updatedBudgetGridRow);
        });
    }

    function onOpenDetailModal(record: BudgetGridData | null) {
        if (record) {
            setBudgetOpening(record);
        } else {
            const budgetOpening = budgetGridData.find(
                (item) => item.budgetOpening.id === selectedRows[0].budgetOpening.id
            );
            setBudgetOpening(budgetOpening);
        }
        setIsOpenAccountDetailPopover(false);
        setDetailModalOpen(true);
    }

    function onOpenCommentModal(record: BudgetGridData) {
        setBudgetOpening(record);
        setComment(record.comment ? record.comment.comment : "");
        setCommentsModalOpen(true);
    }

    function handleAddNewDetail(data: DetailData[], record: BudgetGridData) {
        const updatedBudgetGridData: BudgetGridData[] = cloneDeep(budgetGridData);
        const budgetGridRow = updatedBudgetGridData.find((item) => item.budgetOpening.id === record.budgetOpening.id);
        const index = updatedBudgetGridData.indexOf(budgetGridRow);
        let onlyNewDetail: DetailData[] = data.filter((detail) => !record.detailIdList.includes(detail.id));
        budgetGridRow.isOpeningInOtherCurrency = true;
        onlyNewDetail.forEach((detail) => {
            const newDescription = detail.description.substring(0, detail.description.lastIndexOf("_"));
            detail.description = newDescription;
        });

        const promises = onlyNewDetail.map(async (detail) => {
            return await addDetailRow(
                budgetGridRow,
                detail,
                plannedMonthsMock,
                userInfo.currencyId,
                currenciesOptions,
                false,
                detail.currencyId,
                userInfo.selection.scenarioId
            );
        });

        Promise.all(promises).then((values) => {
            const dataToSave = {
                budgetOpeningId: budgetGridRow.budgetOpening.id,
                rowType: RowType.DETAIL,
                plannedMonths: [],
                scenarioId: userInfo.selection.scenarioId,
            };
            const valuesToSave = values.flat();
            dataToSave.plannedMonths.push(...valuesToSave);
            updatedBudgetGridData.splice(index, 1, budgetGridRow);

            setBudgetGridData(updatedBudgetGridData);
            setBudgetOpening(budgetGridRow);
            handleSavePlannedValue(dataToSave, budgetGridRow, record);
            handleToggleDisableActionButtons();
            setDetailModalOpen(true);
            setIsFetching(false);
        });
    }

    function handleDeleteRow(data = selectedRows) {
        setIsFetching(true);
        if (data.some((row) => row.rowType === RowType.DETAIL)) {
            let detailIdList = data.map((row) => row.detailId);
            const updatedBudgetGridData = cloneDeep(budgetGridData);
            const updatedBudgetGridRow = updatedBudgetGridData.find(
                (item) => item.budgetOpening.id === data[0].budgetOpening.id
            );

            const budgetOpeningId: number = data[0].budgetOpening.id;
            const startDate: number = period[0].valueOf();
            const endDate: number = period[1].valueOf();

            ServiceCaller.doRequest(
                {
                    type: RequestType.DELETE,
                    url: `/expenses/budget-opening/detail-values?budgetOpeningId=${budgetOpeningId
                        }&detailIds=${detailIdList}&startDate=${startDate}&endDate=${endDate}`,
                },
                () => {
                    const parentIndex = updatedBudgetGridData.indexOf(updatedBudgetGridRow);
                    const filteredChildren = updatedBudgetGridRow.detailList.filter(
                        (item) => !detailIdList.includes(item.detailId)
                    );

                    updatedBudgetGridRow.detailIdList = updatedBudgetGridRow.detailIdList.filter(
                        (item) => !detailIdList.includes(item)
                    );
                    updatedBudgetGridRow.detailList = filteredChildren;
                    updatedBudgetGridData.splice(parentIndex, 1, updatedBudgetGridRow);
                    updateParentRowValues(updatedBudgetGridRow);
                    setBudgetGridData(updatedBudgetGridData);
                    setBudgetOpening(updatedBudgetGridRow);
                    if (filteredChildren.length === 0) {
                        setDetailModalOpen(false);
                    }
                    Notification({
                        type: "success",
                        message: i18n.t("fixed_expense_texts.detail_deleted_successfully"),
                    });
                    setIsFetching(false);
                },
                () => {
                    setIsFetching(false);
                    Notification({
                        type: "error",
                        message: i18n.t("something_went_wrong"),
                    });
                }
            );
        } else {
            const budgetOpeningIdsList: number[] = data.map((row) => row.budgetOpening.id);
            ServiceCaller.doRequest(
                {
                    type: RequestType.DELETE,
                    url: `/expenses/budget-opening/clean-account-values?budgetOpeningIds=${budgetOpeningIdsList}&startDate=${period[0].valueOf()}&endDate=${period[1].valueOf()}`,
                },
                () => {
                    const updatedBudgetGridData: BudgetGridData[] = budgetGridData.filter(({ key }) => !selectedRowKeys.some(id => id === key));
                    const accountList: number[] = updatedBudgetGridData.map(({ budgetOpening: { accountId } }) => accountId);
                    const accountOptions: Options[] = accountAccountingOptions.filter((account) => accountList.some(accountId => accountId === account.value));
                    setAccountAccounting(accountOptions);
                    setBudgetGridData(updatedBudgetGridData);
                    Notification({
                        type: "success",
                        message: i18n.t("fixed_expense_texts.account_cleared_successfully"),
                    });
                    setIsFetching(false);
                },
                () => {
                    Notification({
                        type: "error",
                        message: i18n.t("something_went_wrong"),
                    });
                    setIsFetching(false);
                }
            );
        }
        setIsOpenDeletePopover(false);
        handleToggleDisableActionButtons();
    }

    function onViewModeCheckboxChange(values) {
        setViewModeChecked(values);
    }

    function handleToggleDisableActionButtons(
        currency = true,
        detail = true,
        deleteBtn = true,
        distribution = true,
        indexer = true
    ) {
        if (selectedRowKeys.length > 0) {
            setSelectedRowKeys([]);
        }
        if (selectedRows.length > 0) {
            setSelectedRows([]);
        }
        setisCurrencyButtonDisabled(currency);
        setIsAddDetailButtonDisabled(detail);
        setIsDeleteButtonDisabled(deleteBtn);
        setIsDistributionButtonDisabled(distribution);
        setIsIndexerButtonDisabled(indexer);
    }

    const EditableRow: React.FC<EditableRowProps> = memo(({ ...props }) => {
        const [form] = Form.useForm();
        return (
            <Form form={form} component={false}>
                <EditableContext.Provider value={form}>
                    <tr {...props} />
                </EditableContext.Provider>
            </Form>
        );
    });

    const EditableCell: React.FC<EditableCellProps> = memo(
        ({ title, editable, children, dataIndex, record, ...restProps }) => {
            const [editing, setEditing] = useState(false);
            const inputRef = useRef<InputRef>(null);
            const form = useContext(EditableContext)!;
            let month = "";
            let monthArray = [];
            let rowType = RowType.PLANNED;

            if (record && dataIndex) {
                month = dataIndex[1];
                monthArray = Object.keys(record.plannedMonths);
                rowType = record.rowType === RowType.DETAIL ? RowType.PLANNED : record.rowType;
            }

            useEffect(() => {
                if (editing) {
                    inputRef.current!.focus();
                    inputRef.current!.select();
                }
            }, [editing]);

            function handleReplicateValues(
                record: BudgetGridData,
                currentMonth: string,
                value: number,
                direction: "left" | "right"
            ) {
                const updatedBudgetGridData = cloneDeep(budgetGridData);
                const rowIndex = updatedBudgetGridData.findIndex(
                    (item) => record.budgetOpening.id === item.budgetOpening.id
                );
                let budgetGridRow = updatedBudgetGridData.find(
                    (item) => record.budgetOpening.id === item.budgetOpening.id
                );

                let dataToSave = {
                    rowType: record.rowType,
                    budgetOpeningId: record.budgetOpening.id,
                    plannedMonths: [],
                    scenarioId: userInfo.selection.scenarioId,
                };
                Object.keys(record.plannedMonths).forEach((month) => {
                    const currentBudgetPeriod = budgetPeriodDates.period.find(
                        (budDate) => budDate.year === moment(month, "YYYY-MM").year()
                    );
                    const { isEditable } = verifyBudgetPeriod(
                        currentBudgetPeriod,
                        moment(month, "YYYY-MM"),
                        budgetPeriodDates.localDate,
                        ModuleType.EXPENSERESOURCES
                    );

                    if (
                        direction === "right" &&
                        moment(month, "YYYY-MM").isAfter(moment(currentMonth, "YYYY-MM")) &&
                        isEditable
                    ) {
                        switch (record.rowType) {
                            case RowType.PLANNED:
                                budgetGridRow.plannedTotal += value - budgetGridRow.plannedMonths[month].plannedValue;
                                budgetGridRow.plannedMonths[month].plannedValue = value;
                                dataToSave.plannedMonths.push(budgetGridRow.plannedMonths[month]);
                                break;

                            default:
                                break;
                        }
                    }
                });

                updatedBudgetGridData[rowIndex] = budgetGridRow;
                setBudgetGridData(updatedBudgetGridData);

                handleSavePlannedValue(dataToSave, budgetGridRow, record);
            }

            const toggleEdit = () => {
                setEditing(!editing);
                if (dataIndex === "plannedTotal") {
                    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
                } else {
                    const valueKey = record.rowType === RowType.DETAIL ? RowType.PLANNED : record.rowType;
                    form.setFieldsValue({ plannedMonths: { [month]: record.plannedMonths[month][valueKey] } });
                }
            };

            const save = async () => {
                try {
                    const fieldObject = form.getFieldsValue();
                    let isTotalCell = false;
                    let newValue = 0;
                    toggleEdit();

                    if (fieldObject.plannedMonths) {
                        newValue = fieldObject.plannedMonths[month]
                            ? Number(fieldObject.plannedMonths[month].toString().replace(",", "."))
                            : 0;
                        if (record.plannedMonths[month][rowType] === newValue) {
                            return;
                        }
                    } else {
                        newValue = fieldObject.plannedTotal
                            ? Number(fieldObject.plannedTotal.toString().replace(",", "."))
                            : 0;
                        if (record["plannedTotal"] === newValue) {
                            return;
                        }
                        isTotalCell = true;
                    }

                    let dataToSave = {
                        rowType: record.rowType,
                        budgetOpeningId: record.budgetOpening.id,
                        plannedMonths: [],
                        scenarioId: userInfo.selection.scenarioId,
                    };

                    const updatedBudget = cloneDeep(budgetGridData);
                    const rowIndex = updatedBudget.findIndex(
                        (item) => record.budgetOpening.id === item.budgetOpening.id
                    );
                    let budgetGridRow = updatedBudget.find((item) => record.budgetOpening.id === item.budgetOpening.id);

                    if (isTotalCell) {
                        const monthsBlocked = Object.keys(record.plannedMonths).reduce((acc, month) => {
                            const currentBudgetPeriod = budgetPeriodDates.period.find(
                                (budDate) => budDate.year === moment(month, "YYYY-MM").year()
                            );
                            const { isEditable } = verifyBudgetPeriod(
                                currentBudgetPeriod,
                                moment(month, "YYYY-MM"),
                                budgetPeriodDates.localDate,
                                ModuleType.EXPENSERESOURCES
                            );
                            if (!isEditable) {
                                acc.push(month);
                            }
                            return acc;
                        }, []);

                        const totalBlocked = Object.values(record.plannedMonths).reduce((acc, val) => {
                            if (monthsBlocked.includes(val.month)) acc += val.plannedValue;
                            return acc;
                        }, 0);

                        const totalToDistribute = newValue - totalBlocked;

                        const amount = monthArray.length - monthsBlocked.length;
                        if (amount <= 0) return;
                        let valueToDistribute =
                            totalToDistribute < 0
                                ? Math.ceil(totalToDistribute / amount)
                                : Math.floor(totalToDistribute / amount);
                        const restValue = totalToDistribute % amount;
                        let hasRest = true;
                        monthArray.forEach((month) => {
                            if (!monthsBlocked.includes(month)) {
                                if (hasRest) {
                                    budgetGridRow.plannedMonths[month].plannedValue = valueToDistribute + restValue;
                                    hasRest = false;
                                } else {
                                    budgetGridRow.plannedMonths[month].plannedValue = valueToDistribute;
                                }
                                let plannedMonthToSave = budgetGridRow.plannedMonths[month];
                                dataToSave.plannedMonths.push(plannedMonthToSave);
                            }
                        });
                        budgetGridRow.plannedTotal = newValue;
                    } else {
                        budgetGridRow.plannedTotal += newValue - budgetGridRow.plannedMonths[month].plannedValue;
                        budgetGridRow.plannedMonths[month].plannedValue = newValue;
                        dataToSave.plannedMonths.push(budgetGridRow.plannedMonths[month]);
                    }
                    updatedBudget[rowIndex] = budgetGridRow;
                    setBudgetGridData(updatedBudget);
                    handleSavePlannedValue(dataToSave, budgetGridRow, record);
                } catch (errInfo) {
                    console.log("Save failed:", errInfo);
                }
            };

            let childNode = children;

            if (!record) {
                return <td {...restProps}>{childNode}</td>;
            }

            if (editable && !record.hasDetail) {
                childNode = editing ? (
                    <Form.Item style={{ margin: 0, padding: "2px 4px" }} name={dataIndex}>
                        <Input
                            onKeyDown={(e) => {
                                if (e.key === "Tab") {
                                    e.preventDefault();
                                    let refKey = `${record.key}-${record.detailId}-${record.rowType}`;

                                    let nextCellIndex = "";
                                    const refIndex = inputRefs.current
                                        ? Object.keys(inputRefs.current).findIndex((key) => key === refKey)
                                        : null;

                                    if (month !== monthArray[monthArray.length - 1]) {
                                        nextCellIndex = moment(month, "YYYY-MM").add(1, "month").format("YYYY-MM");
                                    } else {
                                        refKey = Object.keys(inputRefs.current)[refIndex + 1];
                                        nextCellIndex = monthArray[0];
                                    }
                                    if (inputRefs.current[refKey][nextCellIndex] && refIndex > -1) {
                                        inputRefs.current[refKey][nextCellIndex].click();
                                    }
                                    return;
                                }
                                validateMonetaryInput(e, true);
                            }}
                            ref={inputRef}
                            onPressEnter={save}
                            onBlur={save}
                        />
                    </Form.Item>
                ) : (
                    <div className="gs-table-input-editable-cell">
                        <div
                            className="editable-cell-value-wrap"
                            onClick={toggleEdit}
                            ref={(el) => {
                                if (!inputRefs.current[`${record.key}-${record.detailId}-${record.rowType}`]) {
                                    inputRefs.current[`${record.key}-${record.detailId}-${record.rowType}`] = {};
                                }
                                inputRefs.current[`${record.key}-${record.detailId}-${record.rowType}`][month] = el;
                            }}
                        >
                            {children}
                        </div>
                        <div className="gs-table-replicate-buttons-container right">
                            {month !== monthArray[monthArray.length - 1] &&
                                dataIndex !== "plannedTotal" &&
                                functionalityPermissions.replicate && (
                                    <Tooltip title={i18n.t("replicate_to_next_months")}>
                                        <Icon
                                            onClick={() => {
                                                handleReplicateValues(
                                                    record,
                                                    month,
                                                    record.plannedMonths[month][rowType],
                                                    "right"
                                                );
                                            }}
                                            icon="material-symbols:content-copy"
                                            style={{
                                                marginLeft: "10px !important",
                                                boxShadow: "none",
                                                color: "#A6A7A7",
                                                background: "transparent",
                                            }}
                                        />
                                    </Tooltip>
                                )}
                        </div>
                    </div>
                );
            }

            return <td {...restProps}>{childNode}</td>;
        }
    );

    function handleSaveComment() {
        let isFirstComment = true;
        const updatedBudgetGridData = cloneDeep(budgetGridData);
        const index = updatedBudgetGridData.findIndex(
            (item) => budgetOpening.budgetOpening.id === item.budgetOpening.id
        );
        let budgetGridRow = updatedBudgetGridData[index];
        if (budgetGridRow.comment) {
            isFirstComment = false;
            budgetGridRow.comment.comment = comment;
        } else {
            Object.assign(budgetGridRow, { comment: { comment } });
        }
        updatedBudgetGridData.splice(index, 1, {
            ...budgetGridRow,
        });
        setBudgetGridData(updatedBudgetGridData);
        setCommentsModalOpen(false);

        ServiceCaller.doRequest(
            {
                type: isFirstComment ? RequestType.POST : RequestType.PUT,
                url: "/expenses/budget-comment",
                params: {
                    id: isFirstComment ? null : budgetGridRow.comment.id,
                    budgetOpeningId: budgetGridRow.budgetOpening.id,
                    comment,
                },
            },
            () => {
                Notification({
                    type: "success",
                    message: i18n.t("fixed_expense_texts.comment_successfully_saved"),
                });
            },
            handleErrorRequest
        );
    }

    const rowSelection = useMemo(() => {
        return {
            selectedRowKeys,
            onChange: (selRowKeys: React.Key[], selectedRows: BudgetGridData[]) => {
                const hasDetailRow = selectedRows.some((row) => row.rowType === RowType.DETAIL);
                const hasParentRow = selectedRows.some((row) => row.rowType !== RowType.DETAIL);
                const onlyDetailRow = selectedRows.every((row) => row.rowType === RowType.DETAIL);
                const onlyParentRow = selectedRows.every((row) => row.rowType !== RowType.DETAIL);
                const hasChildren = selectedRows.some((row) => row.detailList?.length > 0);
                const hasAccountLinkedToHR = selectedRows.some((row) => row.budgetOpening.isAccountLinkedToHR);

                if (isOutOfPeriod || selRowKeys.length === 0 || !selectedRows[0].canEdit) {
                    handleToggleDisableActionButtons();
                } else if (selRowKeys.length === 1 && hasAccountLinkedToHR === true) {
                    handleToggleDisableActionButtons(true, true, false, true, false);
                } else if (selRowKeys.length > 1 && (onlyDetailRow || onlyParentRow) && !hasAccountLinkedToHR) {
                    handleToggleDisableActionButtons(true, true, false, true, true);
                } else if (selRowKeys.length > 1 && ((hasParentRow && hasDetailRow) || hasAccountLinkedToHR)) {
                    handleToggleDisableActionButtons();
                } else if (onlyParentRow && !hasChildren && !hasAccountLinkedToHR) {
                    handleToggleDisableActionButtons(false, false, false, false, false);
                } else if (onlyParentRow && hasChildren) {
                    handleToggleDisableActionButtons(true, true, false, true, false);
                } else if (onlyDetailRow) {
                    setIsDeleteButtonDisabled(false);
                } else if ((!hasDetailRow && !hasParentRow) || (hasDetailRow && hasParentRow)) {
                    setisCurrencyButtonDisabled(true);
                    setIsAddDetailButtonDisabled(true);
                    setIsDeleteButtonDisabled(true);
                }
                setSelectedRowKeys(selRowKeys);
                setSelectedRows(selectedRows);
            },
            renderCell: (checked, record: BudgetGridData, index, originNode) => {
                if (record.rowType === RowType.QUANTITY || record.rowType === RowType.UNITVALUE) {
                    return null;
                }
                return originNode;
            },
        };
    }, [selectedRows]);

    const rowExpandable = useMemo(() => {
        return {
            expandedRowKeys: expandedRowKeys,
            onExpand: (expanded: boolean, record: BudgetGridData) => {
                if (expanded) {
                    setExpandedRowKeys((rowsKeys) => [...rowsKeys, record.key]);
                } else {
                    const updatedKeys = [...expandedRowKeys];
                    const index = updatedKeys.findIndex((key) => key === record.key);
                    updatedKeys.splice(index, 1);
                    setExpandedRowKeys(updatedKeys);
                }
            },
        };
    }, [expandedRowKeys]);

    const components = useMemo(
        () => ({
            body: {
                row: (props) => <EditableRow {...props} handleSavePlannedValue={handleSavePlannedValue} />,
                cell: (props) => <EditableCell {...props} />,
            },
        }),
        [handleSavePlannedValue]
    );

    const cols = useMemo(
        () => {
            const mapColumns = (columnsToMap) => {
                return columnsToMap.map((col) => {
                    if (!col.editable || !functionalityPermissions.edit) {
                        return col;
                    }
                    const newCol = {
                        ...col,
                        onCell: (record) => {
                            return {
                                record,
                                editable:
                                    record.budgetOpening.isAccountLinkedToHR || !record.canEdit ? false : col.editable,
                                dataIndex: col.dataIndex,
                                title: col.title,
                                align: "center",
                            };
                        },
                    };
                    if (col.children?.length) {
                        newCol.children = mapColumns(col.children);
                    }
                    return newCol;
                })
            }
            return mapColumns(columns);
        }, [columns, functionalityPermissions]);

    return (
        <div id="df-grid-content">
            <GridOperations
                handleDelete={handleDeleteRow}
                currencyId={checkedList}
                setCurrencyId={setCheckedList}
                isOpenDeletePopover={isOpenDeletePopover}
                setIsOpenDeletePopover={setIsOpenDeletePopover}
                isOpenViewModePopover={isOpenViewModePopover}
                setIsOpenViewModePopover={setIsOpenViewModePopover}
                isOpenCurPopover={isOpenCurrenciesPopover}
                setIsOpenCurPopover={setIsOpenCurrenciesPopover}
                isCurrencyBtnDisabled={isCurrencyButtonDisabled}
                isAddDetailButtonDisabled={isAddDetailButtonDisabled}
                isDeleteButtonDisabled={isDeleteButtonDisabled}
                handleAddCurrencyAccountRow={handleAddCurrencyAccountRow}
                isOpenAccountDetailPopover={isOpenAccountDetailPopover}
                setIsOpenAccountDetailPopover={setIsOpenAccountDetailPopover}
                userDefaultCurrency={userInfo.currencyId}
                saveStatus={saveStatus}
                setOpenDistributionModal={setIsDistributionModalOpen}
                isDistributionButtonDisabled={isDistributionButtonDisabled}
                onViewModeCheckboxChange={onViewModeCheckboxChange}
                viewModeSelected={viewModeSelected}
                setViewModeSelected={setViewModeSelected}
                setViewModeChecked={setViewModeChecked}
                viewModeChecked={viewModeChecked}
                budgetPeriodDates={budgetPeriodDates}
                handleExportGrid={() => exportGrid(columns, budgetGridData, viewModeSelected, selectedRows)}
                setOpenIndexerModal={setIsIndexerModalOpen}
                isIndexerButtonDisabled={isIndexerButtonDisabled}
                onOpenDetailModal={onOpenDetailModal}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
            />
            <Grid
                key='grid'
                isFetching={isFetching}
                monthAmount={monthAmount}
                viewModeSelected={viewModeSelected}
                components={components}
                cols={cols}
                budgetGridData={budgetGridData.filter((item) =>
                    item.accounting.toLowerCase().includes(searchValue.toLowerCase())
                )}
                rowExpandable={rowExpandable}
                rowSelection={rowSelection}
                isQueryByPackage={isQueryByPackage}
                spendingPackage={spendingPackage}
            />
            <CommentsModal
                setShowModal={setCommentsModalOpen}
                setComment={setComment}
                comment={comment}
                showModal={commentsModalOpen}
                handleSaveComment={handleSaveComment}
                commentTextArea={commentTextArea}
            />
            <DetailModal
                open={detailModalOpen}
                setOpen={setDetailModalOpen}
				budgetOpening={budgetOpening}
                verifyBudgetPeriod={verifyBudgetPeriod}
                budgetPeriodDates={budgetPeriodDates}
                handleCreateBudgetOpening={handleCreateBudgetOpening}
                handleCreateBudgetOpeningAccount={handleCreateBudgetOpeningAccount}
            />
            <DistributionModal
                setModalOpen={setIsDistributionModalOpen}
                isOpen={isDistributionModalOpen}
                selectedAccount={selectedRows}
                handleSavePlannedValue={handleSavePlannedValue}
                handleDisableActionButtons={handleToggleDisableActionButtons}
            />
            <IndexerModal
                setModalOpen={setIsIndexerModalOpen}
                isOpen={isIndexerModalOpen}
                selectedAccount={selectedRows}
                setSelectedRows={setSelectedRowKeys}
                handleSavePlannedValue={handleSavePlannedValue}
            />
        </div>
    );
}
