import { useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { Modal, Carousel, Tooltip } from "antd";
import { BudgetGridData, BudgetPeriodDates } from "module/budget/pages/fixedExpense/IFixedExpense";
import "./DetailModal.sass";
import { TopButtons } from "components/topButtons/TopButtons";
import { Icon } from "@iconify/react";
import { useBudgetOpeningContext } from "module/budget/pages/fixedExpense/context/useBudgetOpeningContext";
import { carouselDefaultProps } from "components/carousel/ICarousel";
import { TableDetail } from "./TableDetail";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import { Notification } from "components/notification/Notification";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import * as React from "react";
import { PeriodBoardInfo } from "module/budget/components/PeriodBoardInfo/PeriodBoardInfo";
import { ModuleType } from "util/types/types";

interface IDetailModal {
    open: boolean;
    setOpen: (open: boolean) => void;
	budgetOpening: BudgetGridData;
    verifyBudgetPeriod: any;
    budgetPeriodDates: any;
    handleCreateBudgetOpeningAccount: () => void;
    handleCreateBudgetOpening: () => void;
}

export function DetailModal({
    open,
    setOpen,
    budgetOpening,
    verifyBudgetPeriod,
	budgetPeriodDates,
	handleCreateBudgetOpening,
	handleCreateBudgetOpeningAccount,
}: IDetailModal) {
    const {
        flexFieldsFilters,
		costCenter,
        spendingPackage,
        currenciesSelected,
        isQueryByPackage,
        period,
        userInfo,
        tableData,
        handleChangeTableData
    } = useBudgetOpeningContext();
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [isRowSelected, setIsRowSelected] = useState(false);
    const [selectIds, setSelectIds] = useState<number[]>([]);
    const [buttonDisabled, setButtonDisabled] = useState(true);
	const [loading, setLoading] = useState(false);
	const [hasChangedValue, setHasChangedValue] = useState(false);

    useEffect(() => {
        setButtonDisabled(tableData.length === 0);
    }, [tableData]);

    const handleAddDetail = () => {
        let currentDate = period[0].clone();
        let monthValues = {};
        while (currentDate.isSameOrBefore(period[1], "month")) {
            const dateKey = currentDate.format("YYYYMM");
            monthValues[dateKey + "quantity"] = 0;
            monthValues[dateKey + "value"] = 0;
            currentDate.add(1, "month");
        }

        const newData = [...tableData, {
            key: tableData.length + 1,
            name: '',
            note: '',
            ...monthValues
        }];
		handleChangeTableData(newData);
		setHasChangedValue(true);
    };

    const handleRowSelectionChange = (selectedRowKeys: any[]) => {
        setSelectIds(tableData.filter(({ key }) => selectedRowKeys.includes(key)).map(({ id }) => id));
        setSelectedRowKeys(selectedRowKeys);
        setIsRowSelected(selectedRowKeys.length > 0);
    }

    const NotificationDelete = () => {
        Notification({
            type: "success",
            message: i18n.t("successfully_deleted"),
        })
    }

    const handleDelete = () => {
        ServiceCaller.doRequest({
            type: RequestType.DELETE,
            url: `/expenses/detail?${selectIds.map(id => "detailUuid=" + id).join("&")}`,
        }, NotificationDelete, handleErrorRequest)
        const newData = tableData.filter(item => !selectIds.includes(item.id));
		handleChangeTableData(newData);
		setHasChangedValue(true);
    }

	const handleCancel = () => {
		if (hasChangedValue) {
			if (isQueryByPackage) {
				handleCreateBudgetOpening();
			} else {
				handleCreateBudgetOpeningAccount();
			}
		}

		setOpen(false);
		setHasChangedValue(false);
		handleChangeTableData([]);
    }

    const NotificationSave = () => {
        Notification({
            type: "success",
            message: i18n.t("successfully_saved"),
        })
    }

    const handleSaveDetail = () => {
        setLoading(true);
        const isFieldsEmpty = tableData.some(item => !item.name.trim());

        if (isFieldsEmpty) {
            Notification({
                type: "error",
                message: i18n.t("description_field_is_required"),
            });
            setLoading(false);
            return;
        }
        const requestData = tableData.map(item => {
            const plannedDetails = [];
            function convertStringToDecimal(valueString) {
                const cleanedString = valueString.replace(/[^0-9,.-]/g, '');
                const normalizedString = cleanedString.replace(',', '.');
                return parseFloat(normalizedString);
            }
            Object.entries(item).forEach(([key, value]) => {
                if (key.includes('quantity') && item[key.replace('quantity', 'value')] !== 0) {
                    const month = parseInt(key.substring(4, 6));
                    const quantity = parseInt(String(value), 10);
                    const year = parseInt(key.substring(0, 4));
                    const valueunitary = convertStringToDecimal(String(item[key.replace('quantity', 'value')]));
                    const yearMonthKey = key.substring(0, 6);
                    plannedDetails.push({
                        uuid: item[yearMonthKey + 'id'],
                        year: year,
                        month: month,
                        quantity: quantity ? quantity : 0,
                        value: valueunitary ? valueunitary : 0
                    });
                }
            });
            return {
                uuid: item.id,
                openingId: budgetOpening.budgetOpening.id,
                name: item.name,
                note: item.note,
                supplierid: null,
                hasSupplier: false,
                plannedDetails: plannedDetails
            };
        });
        ServiceCaller.doRequest({
            type: RequestType.POST,
            url: `/expenses/detail?userId=${userInfo.id}`,
            params: requestData
        }, () => {
            NotificationSave();
            setLoading(false);
        }, error => {
            handleErrorRequest(error);
            setLoading(false);
        });
        setButtonDisabled(true);
	};

    const handleTooltip = (text) => {
        return text.length > 12 ? text : null;
    };

    const PeriodTag = ({ budgetPeriodDates, period }: { budgetPeriodDates: BudgetPeriodDates; period: moment.Moment[] }) => {
        if (!budgetPeriodDates.period || budgetPeriodDates.period.length === 0) return null;

        const periodsWithinSelectedRange = budgetPeriodDates.period.filter(periodItem =>
            periodItem.year >= period[0].year() && periodItem.year <= period[1].year()
        );

        const periodTags = periodsWithinSelectedRange.map(periodItem => {
            const realizedTag = periodItem.expensesResourcesRealizedPeriod ? (
                <span key={`realized_${periodItem.year}`} className="gs-tag green" style={{ margin: "0 15px" }}>
                    {i18n.t("accomplished_until")}:{" "}
                    {moment(periodItem.expensesResourcesRealizedPeriod).format("MMM/YYYY")}
                </span>
            ) : null;

            const frozenTag = periodItem.expensesResourcesFrozenPeriod ? (
                <span key={`frozen_${periodItem.year}`} className="gs-tag blue" style={{ margin: "0 15px" }}>
                    {i18n.t("frozen_until")}:{" "}
                    {moment(periodItem.expensesResourcesFrozenPeriod).format("MMM/YYYY")}
                </span>
            ) : null;

            return (
                <React.Fragment key={`period_${periodItem.year}`}>
                    {realizedTag}
                    {frozenTag}
                </React.Fragment>
            );
        });

        return <>{periodTags}</>;
	}

    const spendingPackageLabel: string = spendingPackage.find((sp) => sp.label === budgetOpening.spendingPackage)?.label;

    return (
        <Modal
            title={i18n.t<string>("detail")}
            open={open}
            centered
            className="gs-modal new-detail-modal"
			cancelText={i18n.t<string>("exit")}
			okText={i18n.t<string>("save")}
            maskClosable={false}
            onCancel={handleCancel}
            onOk={() => handleSaveDetail()}
            okButtonProps={{
				loading,
				disabled: buttonDisabled || !hasChangedValue,
				className: buttonDisabled ? "gs-secondary-button-disabled" : 'gs-primary-button',
            }}
            width={'93%'}
            destroyOnClose
        >
            <div className="detail-modal-header-container" style={{ display: 'flex', gap: '10px' }}>
                <Carousel
                    className="gs-carousel"
                    {...carouselDefaultProps}
					slidesPerRow={6}
                >
                    {flexFieldsFilters.map((filter, index) => (
						<div className="filters-flex-fields" key={index} style={{ width: '100%' }}>
                            {filter.label && (
                                <Tooltip title={handleTooltip(filter.label)} key={index}>
                                    <p className="paragraph-filter-header">{filter.label}</p>
                                </Tooltip>
                            )}
                            {filter.selectedOption.length > 0 ? (
                                filter.selectedOption.map((option, optionIndex) => (
                                    <Tooltip title={handleTooltip(option.label)} key={optionIndex}>
                                        <p className="paragraph-filter-content">{option.label}</p>
                                    </Tooltip>
                                ))
                            ) : (
                                <p className="paragraph-filter-content">{i18n.t("no_option")}</p>
                            )}
                        </div>
                    )
                    )}
                    <div className="filter-fixed">
                        <p className="paragraph-filter-header" >{i18n.t("cost_center")}</p>
                        {costCenter.map(filter => (
                            <Tooltip title={filter.label}>
                                <p className="paragraph-filter-content">{filter.label}</p>
                            </Tooltip>
                        ))}
                    </div>
                    <div className="filter-fixed">
                        <p className="paragraph-filter-header">{i18n.t("accounting_account")}</p>
                        <Tooltip title={budgetOpening.accounting}>
                            <p className="paragraph-filter-content" >{budgetOpening.accounting}</p>
                        </Tooltip>
                    </div>
                    {isQueryByPackage ? (
                        <div className="filter-fixed">
                            <p className="paragraph-filter-header">{i18n.t("spending_package")}</p>
                            <Tooltip title={spendingPackageLabel}>
                                <p className="paragraph-filter-content">{spendingPackageLabel}</p>
                            </Tooltip>
                        </div>
                    ) : null}
                    <div className="filter-fixed-currency" >
                        <p className="paragraph-filter-header">{i18n.t("currency")}</p>
                        {currenciesSelected.map(filter => (
                            <Tooltip title={filter.label}>
                                <p className="paragraph-filter-content">{filter.label}</p>
                            </Tooltip>
                        ))}
                    </div>
                </Carousel>
            </div>
            <div className="detail-modal-header-container-buttons-left"
                style={{ display: "flex", justifyContent: "flex-start", alignItems: 'center' }}
            >
                <div className="header-container-buttons">
                    <TopButtons
                        mainButtonTitle={i18n.t("add_detail")}
                        mainButtonIcon={<Icon icon={"mdi:plus"} />}
                        handleNew={handleAddDetail}
                        handleDelete={handleDelete}
                        isDeletable={isRowSelected}
                    />
                </div>
                <div className="tags-container" style={{ display: 'flex' }}>
                    {tableData.length > 0 && (
                        <>
                            <PeriodTag budgetPeriodDates={budgetPeriodDates} period={period} />
                            <PeriodBoardInfo
                                budgetPeriodDates={budgetPeriodDates}
                                moduleType={ModuleType.EXPENSERESOURCES}
                                period={period}
                            />
                        </>
                    )}
                </div>
            </div>
			<div id="datail-modal-content-grid">
                <TableDetail
                    key={"table-detail-container"}
                    open={open}
                    budgetOpening={budgetOpening}
                    handleAddDetail={handleAddDetail}
                    selectedRowKeys={selectedRowKeys}
                    setSelectedRowKeys={handleRowSelectionChange}
                    verifyBudgetPeriod={verifyBudgetPeriod}
					budgetPeriodDates={budgetPeriodDates}
					setHasChangedValue={setHasChangedValue}
                />
            </div>
        </Modal >
    );
}
