import { useEffect, useState } from "react";
import "./Apportionment.sass";
import { ApportionmentListSection } from "./components/ApportionmentListSection/ApportionmentListSection";
import { NewApportionmentSection } from "./components/NewApportionmentSection/NewApportionmentSection";
import i18n from "util/base/i18n";
import { BackButton } from "components/button/BackButton";
import {
    ErrorRequest,
    FlexFieldFilterResponse,
    FlexFieldValueListBean,
    FlexFilter,
    ModuleType,
    Options,
} from "util/types/types";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { ApportionmentResponse, ApportionmentType, TableData } from "./IApportionment";
import moment from "moment";
import { useBudgetDates } from "hooks/useBudgetDates";
import { Notification } from "components/notification/Notification";
import { useBusinessUnitOptions } from "hooks/useBusinessUnitOptions";
import { useCostCenterOptions } from "hooks/useCostCenterOptions";

export function Apportionment() {
    const [isApportionmentListVisible, setIsApportionmentListVisible] = useState(true);
    const [originAccountOptions, setOriginAccountOptions] = useState<Options[]>([]);
    const [isFetchingOriginAccountOptions, setIsFetchingOriginAccountOptions] = useState(false);
    const [flexFieldsOriginOptions, setFlexFieldsOriginOptions] = useState<FlexFilter[]>([]);
    const [flexFieldsDestinationOptions, setFlexFieldsDestinationOptions] = useState<FlexFilter[]>([]);
    const [apportionmentList, setApportionmentList] = useState<ApportionmentResponse[]>([]);
    const [originTableData, setOriginTableData] = useState<TableData[]>([]);
    const [destinationTableData, setDestinationTableData] = useState<TableData[]>([]);
    const [originCollapsed, setOriginCollapsed] = useState(false);
    const [destinationCollapsed, setDestinationCollapsed] = useState(false);
    const [isFetchingApportionments, setIsFetchingApportionments] = useState(true);
    const [apportionmentType, setApportionmentType] = useState<ApportionmentType>(ApportionmentType.PERIOD);
    const [apportionmentPeriod, setApportionmentPeriod] = useState<string[]>([]);
    const [openCalendarPeriod, setOpenPeriodCalendar] = useState([]);
    const { data: budgetDates } = useBudgetDates(ModuleType.EXPENSERESOURCES);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const { data: businessUnitOptions } = useBusinessUnitOptions();
    const { data: costCenterOptions } = useCostCenterOptions();

    useEffect(() => {
        fetchApportionments();
        ServiceCaller.doRequest(
            {
                type: RequestType.GET,
                url: `/budget-base/flex-field/find-all-by-module?module=EXPENSES`,
            },
            onLoadFlexField.bind(this),
            (err: ErrorRequest) => {
                handleErrorRequest(err);
            }
        );
    }, []);

    useEffect(() => {
        if (!budgetDates) {
            return;
        }
        setOpenPeriodCalendar(budgetDates.period.map((period) => moment(period.year, "YYYY")));
    }, [budgetDates]);

    async function fetchApportionments(
        type = ApportionmentType.ALL,
        buId = 0,
        costCenterId = 0,
        accountId = 0,
        flexFieldStringKey = Array(30).join("%3A"),
        year = moment().year()
    ) {
        setIsFetchingApportionments(true);

        const response = await ServiceCaller.doAsyncRequest<ApportionmentResponse[]>({
            type: RequestType.GET,
            url:
                "/expenses/apportionment" +
                "?scenario={scenario}" +
                "&organization={organization}" +
                "&locale={locale}" +
                `&year=${year}` +
                (type !== ApportionmentType.ALL ? `&apportionmentType=${type}` : "") +
                (buId > 0 ? `&businessUnit=${buId}` : "") +
                (costCenterId > 0 ? `&costCenter=${costCenterId}` : "") +
                (accountId > 0 ? `&accountingAccount=${accountId}` : "") +
                `&flexFieldFilter=${flexFieldStringKey.replaceAll(":", "%3A")}`,
        });

        if (response.success) {
            setIsFetchingApportionments(false);
            setApportionmentList(response.data);
        } else {
            setIsFetchingApportionments(false);
            handleErrorRequest(response as any);
        }
    }

    async function onLoadFlexField(data: FlexFieldFilterResponse[]) {
        const flexField: FlexFilter[] = data
            .sort((a, b) => a.ordenation - b.ordenation)
            .map((item) => {
                return {
                    label: item.description,
                    value: item.id,
                    linkedFilters: item.filters ? item.filters : null,
                    ordenation: item.ordenation,
                    fieldCode: Number(item.fieldCode.split("_").pop()),
                    selectedOption: [],
                    children: [],
                };
            });
        try {
            const promises = flexField.map((ff) => {
                if (!ff.linkedFilters) {
                    return new Promise<void>((resolve, reject) => {
                        ServiceCaller.doRequest(
                            {
                                type: RequestType.GET,
                                url: `/budget-base/flex-field-value?user={user}&flexFieldId=${ff.value}`,
                            },
                            (data: FlexFieldValueListBean[]) => {
                                ff.children = data.map((item) => ({
                                    value: item.id,
                                    label: item.externalCode + " - " + item.description,
                                    flexFieldId: item.flexFieldId,
                                }));
                                return resolve();
                            },
                            (err) => {
                                handleErrorRequest(err);
                                return reject();
                            }
                        );
                    });
                }
                return Promise.resolve();
            });

            await Promise.all(promises);
        } catch (error) {
            handleErrorRequest(error);
        }
        setFlexFieldsDestinationOptions(flexField);
        setFlexFieldsOriginOptions(flexField);
    }

    function onOpenApportionmentInfo(data: ApportionmentResponse) {
        setOriginCollapsed(true);
        setDestinationCollapsed(true);
        setIsApportionmentListVisible(false);
        const updatedDestinationTableData = data.destinationApportioments.map((item) => createTableData(item));
        setOriginTableData([createTableData(data)]);
        setDestinationTableData(updatedDestinationTableData);
        setApportionmentType(data.apportionmentType);
    }

	function createTableData(data): TableData {
        const tableData = {
            id: data.id,
            year: data.year,
            costCenter: { value: data.costCenterId, label: data.costCenterName },
            account: { value: data.accountingAccountId, label: data.accountingAccountName },
            businessUnit: { value: data.businessUnitId, label: data.businessUnitName },
            flexFields: [],
            key: `${data.costCenterId}-${data.accountingAccountId}-${data.businessUnitId}`,
        };
        if (data.flexFieldFilter) {
            data.flexFieldFilter.split(":").forEach((flexId, index) => {
                if (flexId) {
                    const flexField = flexFieldsOriginOptions.find((f) => f.fieldCode === index + 1);
                    tableData.flexFields.push({
                        value: Number(flexId),
                        label: flexField.children.find((child) => child.value === Number(flexId))?.label,
                        flexFieldId: flexField.value,
                    });
                    tableData.key += `-${flexId}`;
                }
            });
        }
        if (data.monthlyValues) {
            data.monthlyValues.forEach((monthlyValue) => {
                Object.assign(tableData, {
                    [monthlyValue.monthYear]: { value: monthlyValue.value, id: monthlyValue.id },
                });
            });
            const year = moment(data.monthlyValues[0].monthYear, "YYYY-MM").year();
            const updatedDefaultValue = [];
            for (let i = 0; i < 12; i++) {
                const month = `${year}-${String(i + 1).padStart(2, "0")}`;
                updatedDefaultValue.push(month);
            }
            setApportionmentPeriod(updatedDefaultValue);
        }

        return tableData;
    }

    function onClickNewApportionment() {
        setIsApportionmentListVisible(false);
        setOriginCollapsed(false);
        setDestinationCollapsed(false);
    }

    function onGoBackToList() {
        setIsApportionmentListVisible(true);
        setOriginTableData([]);
        setDestinationTableData([]);
    }

    async function onDelete() {
        const response = await ServiceCaller.doAsyncRequest<ApportionmentResponse[]>({
            type: RequestType.DELETE,
            url: `/expenses/apportionment?apportionments=${selectedRowKeys}`,
        });
        if (response.success) {
            setApportionmentList((state) => state.filter((item) => !selectedRowKeys.includes(item.id)));
            Notification({
                type: "success",
                message: i18n.t<string>("successfully_deleted"),
            });
        } else {
            Notification({
                type: "error",
                message: i18n.t<string>("something_went_wrong"),
            });
        }
    }

    return (
        <>
            <div>
                <header className="page-title-content">
                    <div className="gs-flex align-start flex-col">
                        <h1>{i18n.t("apportionment_of_expenses")}</h1>
                        {isApportionmentListVisible ? null : <BackButton onGoBack={() => onGoBackToList()} />}
                    </div>
                </header>
                <main
                    className="apportionment-main-container"
                    style={{ height: `calc(100vh - ${isApportionmentListVisible ? "156" : "176"}px)` }}
                >
                    {isApportionmentListVisible ? (
                        <ApportionmentListSection
                            openCalendarPeriod={openCalendarPeriod}
                            apportionmentList={apportionmentList}
                            onClickNewApportionment={onClickNewApportionment}
                            costCenterOptions={costCenterOptions}
                            originAccountOptions={originAccountOptions}
                            businessUnitOptions={businessUnitOptions}
                            isFetchingOriginAccountOptions={isFetchingOriginAccountOptions}
                            flexFieldsOriginOptions={flexFieldsOriginOptions}
                            setFlexFieldsOriginOptions={setFlexFieldsOriginOptions}
                            setOriginAccountOptions={setOriginAccountOptions}
                            setIsFetchingOriginAccountOptions={setIsFetchingOriginAccountOptions}
                            onOpenApportionmentInfo={onOpenApportionmentInfo}
                            isFetchingApportionments={isFetchingApportionments}
                            apportionmentType={apportionmentType}
                            fetchApportionments={fetchApportionments}
                            selectedRowKeys={selectedRowKeys}
                            setSelectedRowKeys={setSelectedRowKeys}
                            onDelete={onDelete}
                        />
                    ) : (
                        <NewApportionmentSection
                            openCalendarPeriod={openCalendarPeriod}
                            apportionmentPeriod={apportionmentPeriod}
                            setApportionmentPeriod={setApportionmentPeriod}
                            costCenterOptions={costCenterOptions}
                            originAccountOptions={originAccountOptions}
                            businessUnitOptions={businessUnitOptions}
                            isFetchingOriginAccountOptions={isFetchingOriginAccountOptions}
                            flexFieldsOriginOptions={flexFieldsOriginOptions}
                            setFlexFieldsOriginOptions={setFlexFieldsOriginOptions}
                            setOriginAccountOptions={setOriginAccountOptions}
                            setIsFetchingOriginAccountOptions={setIsFetchingOriginAccountOptions}
                            flexFieldsDestinationOptions={flexFieldsDestinationOptions}
                            setFlexFieldsDestinationOptions={setFlexFieldsDestinationOptions}
                            originTableData={originTableData}
                            setOriginTableData={setOriginTableData}
                            destinationTableData={destinationTableData}
                            setDestinationTableData={setDestinationTableData}
                            originCollapsed={originCollapsed}
                            setOriginCollapsed={setOriginCollapsed}
                            destinationCollapsed={destinationCollapsed}
                            setDestinationCollapsed={setDestinationCollapsed}
                            setIsApportionmentListVisible={setIsApportionmentListVisible}
                            fetchApportionments={fetchApportionments}
                            setApportionmentType={setApportionmentType}
                            apportionmentType={apportionmentType}
                            apportionmentList={apportionmentList}
                            budgetDates={budgetDates}
                        />
                    )}
                </main>
            </div>
        </>
    );
}
