import { Form, InputNumber } from "antd";
import { Loading } from "components/loading/Loading";
import { useEffect, useRef } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useProjectionModelingContext } from "../../context/ProjectionModelingContext";
import { FormulaFieldType } from "../../IProjectionModeling";
import { IExpression } from "../../newProjection/stepTwo/IStepTwo";
import { DivideOp, LeftParenthesisOp, MinusOp, PlusOp, RightParenthesisOp, TimesOp } from "../operators/MathOperators";
import { Tag } from "./Tag";

interface IFormulaAreaProps {
	removeItem: (index: number) => void;
}

export function FormulaArea({ removeItem }: IFormulaAreaProps) {
	const {
		formula,
		setFormula,
		fieldList,
		isFormulaFetching,
		setIsFormulaFetching,
		form,
		isNewProjection
	} = useProjectionModelingContext();

	const origin = Form.useWatch("origin", form);

	const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
	const currentEditIndex = useRef<number | null>(null);

	const operatorComponents: Record<string, JSX.Element> = {
		PLUS: <PlusOp />,
		LESS: <MinusOp />,
		MULTIPLY: <TimesOp />,
		DIVIDE: <DivideOp />,
		OPEN_PARENTHESIS: <LeftParenthesisOp />,
		CLOSE_PARENTHESIS: <RightParenthesisOp />,
	};

	const formulaWithOperatorKeys =
		formula?.expressions?.map((item, index) => ({
			...item,
			key: `${item.position}-${item.fieldType}-${item.operator}-${index}`,
		})) || [];

	const mathOperations = ["PLUS", "LESS", "MULTIPLY", "DIVIDE", "OPEN_PARENTHESIS", "CLOSE_PARENTHESIS"];

	useEffect(() => {
		if (!formula.expressions.length && isNewProjection && !origin) {
            setIsFormulaFetching(false);
            return;
        }

		formula.expressions.forEach((item) => {
			fieldList.forEach((field) => {
				if (
                    (!item.staticField && item.modularFieldId !== 0) ||
                    field.value.id === item.staticField ||
                    item.modularFieldId === Number(field.value.id)
                ) {
                    item.formulaExpression = {
                        id: field.value.id,
                        name: field.value.name,
                        type: field.value.type,
                    };
                }
			});
		});

		setFormula(formula);
	}, [fieldList]);

	useEffect(() => {
		if (currentEditIndex.current !== null) {
			const inputElement: HTMLInputElement | null = inputRefs.current[currentEditIndex.current];
			if (inputElement) {
				const item = formulaWithOperatorKeys[currentEditIndex.current];
				inputElement.focus();

				if (item.fieldType === FormulaFieldType.PERCENTAGE_VALUE) {
					const value = inputElement.value;
					inputElement.setSelectionRange(value.length - 1, value.length - 1);
				}
			}
		}
	}, [formulaWithOperatorKeys]);

	const handleDragEnd = (result) => {
		if (!result.destination) return;

		const items = Array.from(formula.expressions);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);

		setFormula((prevFormula) => ({
			...prevFormula,
			expressions: items,
		}));
	};

	const hasProjectionConf = (item: IExpression) => {
		if (item.formulaExpression && item.formulaExpression?.type === "field") {
			return true;
		}
		return false;
	};

	const onChangeValueInput = (newValue: number, ordination: number): void => {
		currentEditIndex.current = ordination;

		setFormula((prevFormula) => {
			const newState = [...prevFormula.expressions];
			const itemToChange = { ...newState[ordination] };

			if (itemToChange.value !== newValue || itemToChange.rate !== newValue) {
				if (itemToChange.fieldType === FormulaFieldType.PERCENTAGE_VALUE) {
					itemToChange.operator = null;
					itemToChange.rate = newValue;
				} else if (itemToChange.fieldType === FormulaFieldType.ABSOLUTE_VALUE) {
					itemToChange.operator = null;
					itemToChange.value = newValue;
				}

				newState[ordination] = itemToChange;
				return {
					...prevFormula,
					expressions: newState,
				};
			}

			return prevFormula;
		});
	};

	return (
		<DragDropContext onDragEnd={handleDragEnd}>
			<Droppable
				droppableId="attribute_parameterization-new-attribute-modal-formula-workarea"
				direction="horizontal"
			>
				{(provided) => (
					<div
						className="attribute_parameterization-new-attribute-modal-formula-workarea"
						{...provided.droppableProps}
						ref={provided.innerRef}
					>
						{
							isFormulaFetching ?
								<Loading
									caption=" "
									styles={{ marginLeft: "50px", height: "70px", alignItems: "start" }}
								/>
								:
								formulaWithOperatorKeys.map((item, index) => (
									<Draggable key={item.key} draggableId={item.key} index={index}>
										{(provided) => {
											let content: JSX.Element | null = null;
											let isMathOperation: boolean = false;

											if (mathOperations.includes(item.operator)) {
												isMathOperation = true;
												content = operatorComponents[item.operator];
											} else if (item.fieldType === FormulaFieldType.ABSOLUTE_VALUE) {
												content = (
													<InputNumber
														ref={(el) => (inputRefs.current[index] = el)}
														onChange={(newValue) => onChangeValueInput(newValue as number, index)}
														value={item.value !== null ? item.value : undefined}
														controls={false}
														formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
														parser={(value) =>
															parseFloat(value.replace(/%/g, "").replace(/,/g, ""))
														}
													/>
												);
											} else if (item.fieldType === FormulaFieldType.PERCENTAGE_VALUE) {
												content = (
													<div style={{ display: "flex", alignItems: "center" }}>
														<InputNumber
															ref={(el) => (inputRefs.current[index] = el)}
															value={item.rate !== null ? item.rate : undefined}
															onChange={(newValue) =>
																onChangeValueInput(newValue as number, index)
															}
															formatter={(value) =>
																`${value}%`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
															}
															parser={(value) =>
																parseFloat(value.replace(/%/g, "").replace(/,/g, ""))
															}
															controls={false}
														/>
													</div>
												);
											}

											return (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <Tag
                                                        isMathOperation={isMathOperation}
                                                        itemIndex={index}
                                                        removeItem={removeItem}
                                                        fieldList={fieldList}
                                                        hasProjectionConf={hasProjectionConf(item)}
                                                    >
                                                        {content === null && item.formulaExpression
                                                            ? item.formulaExpression?.name
                                                            : content}
                                                    </Tag>
                                                </div>
                                            );
										}}
									</Draggable>
								))
						}
						{provided.placeholder}
					</div>
				)
				}
			</Droppable>
		</DragDropContext>
	);
}
