import { Button, Col, Form, Input, Modal, Row, Select } from "antd";
import { useGroupersContext } from "./GroupersContext";
import i18n from "util/base/i18n";
import { selectProps } from "util/props/props";
import NewGroupersTable from "./NewGroupersTable";
import { IndexerType, IProjectionGrouperData } from "../IProjectionGroupers";
import { Icon } from "@iconify/react";
import { useEffect } from "react";
import { validateFormField } from "util/functions/validateFormField";

export default function GroupersModal() {
	const [form] = Form.useForm();
	const {
		isModalOpen,
		setIsModalOpen,
		isSaving,
		listToSave,
		indexerList,
		setListToSave,
		indexerTypeList,
		handleSave,
		itemForEdit,
		setItemForEdit,
		tableData,
		selectedRowKeys
	} = useGroupersContext();
	const modalTitle: string = i18n.t(itemForEdit ? "Editar Agrupador" : "Cadastrar Agrupador");

	useEffect(() => {
		if (itemForEdit) {
			form.setFieldsValue(itemForEdit);
		}
	}, [itemForEdit])
	const handleCloseModal = () => {
		setIsModalOpen(false);
		form.resetFields();
		setListToSave([]);
		setItemForEdit(null);
	}

	const handleFinishForm = (values: IProjectionGrouperData) => {
		values["id"] = Math.random();
		setListToSave(state => ([...state, values]));
		form.resetFields();
	}

	const handleEdit = (grouper: IProjectionGrouperData) => {
		setListToSave(state => state.filter(({ id }) => id !== grouper.id));
		form.setFieldsValue(grouper);
	}

	const handleDelete = (id: number) => {
		setListToSave(state => state.filter((grouper) => id !== grouper.id));
	}

	return (
		<Modal
			open={isModalOpen}
			onCancel={handleCloseModal}
			title={modalTitle}
			destroyOnClose
			okButtonProps={{
				className: "gs-main-button",
				loading: isSaving,
				disabled: !(itemForEdit || listToSave.length),
				htmlType: "submit",
				form: "grouper-modal-form"
			}}
			okText={i18n.t<string>("save")}
			cancelButtonProps={{ type: "text" }}
			cancelText={i18n.t<string>("close")}
			className="gs-modal projection-grouper-modal"
			centered
			width={700}
			onOk={() => handleSave(form.getFieldsValue())}
		>
			<Form
				form={form}
				name="grouper-modal-form"
				layout="vertical"
				onFinish={handleFinishForm}
			>
				<Row gutter={10}>
					<Col span={12}>
						<Form.Item
							label={i18n.t("external_code")}
							name="externalCode"
							rules={[
								{ required: true, message: i18n.t<string>("required_field") },
								() => ({
									validator(_, value: string) {
										return validateFormField(value, 'externalCode', tableData, listToSave, i18n.t<string>("the_external_code_field_cannot_repeat"), selectedRowKeys[0]);
									},
								})
							]}
						>
							<Input />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							label={i18n.t("description")}
							name="description"
							rules={[{ required: true, message: i18n.t<string>("required_field") }]}
						>
							<Input />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={10}>
					<Col span={12}>
						<Form.Item
							label={i18n.t("indexer")}
							name="indexerId"
						>
							<Select
								options={indexerList}
								allowClear
								{...selectProps}
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							label={i18n.t("projection_grouper.indexer_type")}
							name="indexerType"
							initialValue={IndexerType.NONE}
						>
							<Select
								options={indexerTypeList}
							/>
						</Form.Item>
					</Col>
				</Row>
				{!itemForEdit &&
					<Row className="gs-flex jc-end">
						<Col >
							<Button
								type="default"
								htmlType="submit"
								className="gs-main-button"
								icon={<Icon icon="akar-icons:circle-plus-fill" />}
							>
								{i18n.t<string>("addToList")}
							</Button>
						</Col>
					</Row>
				}
			</Form>
			{
				!itemForEdit &&
				<NewGroupersTable
					listToSave={listToSave}
					handleDelete={handleDelete}
					handleEdit={handleEdit}
				/>
			}
		</Modal >
	)
}