import { useEffect, useState } from "react";
import { FilterType, FormulaAttribute, INewReportProps, LevelFilter, LevelFormula, LevelReport, Report } from "../../IRegistrationSalesReports";
import { Button, Steps } from "antd";
import i18n from "util/base/i18n";
import StepOne from "./stepOne/StepOne";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import StepTwo from "./stepTwo/StepTwo";
import StepThree from "./stepThree/StepThree";
import { ErrorFallback } from "components/error/ErrorFallback";
import { useUserContext } from "context/UserContext";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { OptionSelect } from "module/budget/pages/collaborators/linkAccounts/ILinkAccounts";
import { Notification } from "components/notification/Notification";
import { stringToSignalType } from "./stepTwo/content/constants";
import { useNewReportContext } from "../NewReportContext";
import { RuleConditionOperations } from "../../../attributeParameterization/IAttributeParameterization";

export default function NewReport({
    idReportForEdit,
    onCancel,
    isEditing,
}: INewReportProps) {
    const [current, setCurrent] = useState(idReportForEdit ? -1 : 0);
    const [report, setReport] = useState<Report>();
    const { reportType, setReportType, isPlanningReport } = useNewReportContext();
    const isNextButtonDisabled = !report?.levels?.length || report?.description === "";
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [optionsForFormula, setOptionsForFormula] = useState<OptionSelect[]>([]);
    const { userInfo: { selection: { businessUnitId, organizationId } } } = useUserContext();
    const steps = [
        {
            title: i18n.t<string>("levels_structure"),
            stepNumber: 1,
            stepIndex: 0,
            disabled: false
        },
        {
            title: i18n.t<string>("add_value_to_row"),
            stepNumber: 2,
            stepIndex: 1,
            disabled: isNextButtonDisabled
        },
        {
            title: i18n.t<string>("report_summary"),
            stepNumber: 3,
            stepIndex: 2,
            disabled: isNextButtonDisabled
        },
    ]

    function onClickBackStep() {
        if (current === 0) {
            setReport(null);
            onCancel();
        } else {
            setCurrent(state => state - 1);
        }
    }

    function hasDuplicateExternalCode(levels: LevelReport[]): boolean {
        const externalCodeSet = new Set<string>();

        function verify({ externalCode, children }: LevelReport): boolean {
            if (externalCodeSet.has(externalCode)) {
                return true;
            }

            externalCodeSet.add(externalCode);

            for (const child of children || []) {
                if (verify(child)) {
                    return true;
                }
            }

            return false;
        }

        for (const root of levels) {
            if (verify(root)) {
                return true;
            }
        }

        return false;
    }

    function onClickNextStep() {
        if (isLoading) return;
        setIsLoading(true);
        if (current === 0) {
            if (hasDuplicateExternalCode(report.levels)) {
                Notification({
                    message: i18n.t("line_code_cannot_repeat"),
                    type: "warning"
                })
                setIsLoading(false);
                return;
            }
            ServiceCaller.doRequest({
                type: RequestType.POST,
                url: `budget-report/report/${(isEditing || report.id) ? "updateReport" : "createReport"}`,
                params: {
                    ...report,
                    levels: report.levels.map(line => {
                        return ({
                            ...line,
                            subLevels: line.children
                        })
                    }),
                    organizationId: organizationId,
                    businessUnitId: businessUnitId,
                    reportType
                }
            }, onSaveLevels)
        } else if (current === 1) {
            setIsLoading(false);
            let formatedFormulas: LevelFormula[] = [];
            report.levels.forEach(({ levelFormula, children, id }) => {
                let formulasToReturn: LevelFormula[] = [];
                if (children?.length) {
                    children.forEach((child) => {
                        formulasToReturn.push(child.levelFormula);
                    })
                } else {
                    formulasToReturn.push(levelFormula);
                }
                formatedFormulas = [...formatedFormulas.concat(formulasToReturn.filter(value => value))];
            })

            if (formatedFormulas.length) {
                ServiceCaller.doRequest({
                    type: RequestType.POST,
                    url: `budget-report/report/${isEditing ? "updateFormulas" : "createFormulas"}`,
                    params: formatedFormulas
                }, null, ErrorFallback)
            }

            let formatedFilters: LevelFilter[] = [];
            report.levels.forEach(({ levelFilter, children }) => {
                let filtersToReturn: LevelFilter[] = [];
                if (children?.length) {
                    children.forEach((child) => {
                        child.levelFilter.filters.forEach((filter) => {
                            filter.id = null;
                        });
                        filtersToReturn.push(child.levelFilter);
                    });
                } else if (levelFilter) {
                    levelFilter.filters.forEach((filter) => {
                        filter.id = null;
                        if (isPlanningReport()) {
                            filter.filterType = FilterType.FROM_TO;
                            filter.operation = "FROM_TO" as RuleConditionOperations;
                        } else {
                            filter.filterType = FilterType.FLEX_FIELD;
                        }
                    });
                    filtersToReturn.push(levelFilter);
                }
                formatedFilters = [...formatedFilters.concat(filtersToReturn.filter(value => value))];
            });

            if (formatedFilters.length) {
                const isForEdit: boolean = !!(formatedFilters.some(({ filters }) => filters.some(({ id }) => id)) || idReportForEdit);
                ServiceCaller.doRequest({
                    type: RequestType.POST,
                    url: `budget-report/report/${isForEdit ? "updateFilters" : "createFilters"}`,
                    params: formatedFilters
                }, null, ErrorFallback)
            }
            setCurrent(2);
        } else {
            setReport(null);
            onCancel();
            setIsLoading(false);
        }
    }

    function onSaveLevels(data: Report) {
        setIsLoading(false);
        setReport({
            ...data, levels: sortLevels(data.levels)
        });
        setCurrent(1);
    }

    function onLoadFindLevels(levels: FormulaAttribute[]) {
        const attributesOptions: OptionSelect[] = levels.map(({ name, id, formulaAttribute, grouperName }) => {
            const labelAttribute: string = formulaAttribute ? i18n.t(name) : `${i18n.t(grouperName.toString().toLowerCase())} - ${name} `
            return ({
                value: `ATTRIBUTE-${id}`,
                label: labelAttribute
            })
        });
        let level: OptionSelect[] = [];
        report?.levels.forEach(({ id, description, externalCode, title }) => {
            if (title) return;
            level.push({
                value: `LEVEL-${id}`,
                label: `${externalCode} - ${description}`,
            })
        })
        const options: OptionSelect[] = attributesOptions.concat()
        setOptionsForFormula(options);
    }

    const renderStep = {
        0: <StepOne isEditing={isEditing} report={report} setReport={setReport} sortLevels={sortLevels} setReportType={setReportType} />,
        1: <StepTwo optionsForFormula={optionsForFormula} isEditing={isEditing} report={report} setReport={setReport} fetchReports={fetchReports} />,
        2: <StepThree optionsForFormula={optionsForFormula} isEditing={isEditing} report={report} setReport={setReport} />
    }

    function onLoadEdit(data: Report) {
        setReportType(data.type);
        setReport({
            ...data,
            reportType: data.reportType,
            levels: sortLevels(data.levels),
        });
        setCurrent(0);
    }

    function sortLevels(levels: LevelReport[]): LevelReport[] {
        return levels.map(line => {
            if (line.subLevels.length) {
                line.children = sortLevels(line.subLevels);
            }
            line.isSubLevel = line.upperLevelId ? true : false;
            line.levelFormula = {
                belongsToId: line.id,
                formulas: line.formulas
            }
            line.levelStyle = { ...line.levelStyle, signalType: stringToSignalType[line.levelStyle.signalType] }
            line.isSubLevel = line.upperLevelId ? true : false;

            return { ...line, key: line.ordination };
        }).sort((a, b) => a.ordination > b.ordination ? 1 : a.ordination === b.ordination ? 0 : -1);
    }

    function fetchReports() {
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: `budget-report/report/find/${idReportForEdit}`,
        }, onLoadEdit)
    }

    const flattenHierarchy = (lines: LevelReport[] = []): OptionSelect[] => {
        let newLevels: OptionSelect[] = []

        lines.forEach(({ externalCode, description, children, id, title }) => {
            if (title) return;
            newLevels.push({
                value: `LEVEL-${id}`,
                label: `${externalCode} - ${description}`,
            })
            if (children?.length) {
                newLevels = newLevels.concat(flattenHierarchy(children));
            }
        })
        return newLevels
    }

    useEffect(() => {
        if (!idReportForEdit) return;
        fetchReports();
    }, [idReportForEdit])

    useEffect(() => {
        if (isPlanningReport()) {
            onLoadFindLevels([]);
            return;
        }
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: "revenue/grouper/attribute?loadFormulaAttribute=true",
        }, onLoadFindLevels, handleErrorRequest)
    }, [reportType])

    useEffect(() => {
        setOptionsForFormula(options => {
            let newOptions: OptionSelect[] = [];
            options.forEach((option) => {
                if (option && option.value.includes("ATTRIBUTE-")) {
                    newOptions.push(option);
                }
            })
            let formattedLevels: OptionSelect[] = flattenHierarchy(report?.levels);
            newOptions = newOptions.concat(formattedLevels);
            return newOptions.filter(value => value);
        })
    }, [report])

    return (
        <div className="new-report-page">
            <div className="page-title-content">
                <h1>{i18n.t<string>("sales_report_registration")}</h1>
                <Steps
                    type="navigation"
                    size="small"
                    current={current}
                    onChange={value => setCurrent(value)}
                    className="site-navigation-steps"
                >
                    {
                        steps.map(({ stepIndex, stepNumber, title, disabled }) => {
                            return <Steps.Step disabled={disabled} key={stepIndex} title={title} stepNumber={stepNumber} stepIndex={stepIndex} />
                        })
                    }
                </Steps>
            </div>
            <div className="button-steps">
                <Button
                    type="link"
                    onClick={onClickBackStep}
                >
                    {i18n.t(current === 0 ? "cancel" : "back")}
                </Button>
                <Button
                    type="primary"
                    className="gs-main-button"
                    disabled={isNextButtonDisabled}
                    onClick={onClickNextStep}
                    loading={isLoading}
                >
                    {i18n.t(current + 1 < steps.length ? "next" : "conclude")}
                </Button>
            </div>
            <main>
                {
                    renderStep[current]
                }
            </main>
        </div>
    )
}