import { cloneDeep } from "lodash";
import { Options } from "util/types/types";
import { OtherEventColumn, OtherEventDataResponse, OtherEventGridData, RowType } from "../../../IOtherEvents";
import { updateRowTotals } from "../functions/updateRowTotals";

export function createGridData(
	otherEventData: OtherEventDataResponse[],
	otherEventColumns: OtherEventColumn[],
	currencySelectedId: number,
	currenciesOptions: Options[]
) {
	if (!otherEventData.length) return [];
	const gridData: OtherEventGridData[] = [];
	const { scenarioId, organizationId } = otherEventData[0];
	otherEventData.forEach(({ rows }) => {
		rows.forEach((otherEventRow, index) => {
			let isOpeningInOtherCurrency = false;
			let currencyIsoCode = "";

			const currencyDefault = currenciesOptions.find((cur) => cur.value === currencySelectedId);
			if (currencyDefault) {
				currencyIsoCode = currencyDefault.label;
			}

			const plannedMonthsObject = otherEventRow.columns.reduce(
				(acc, plannedMonth) => ({
					...acc,
					[plannedMonth.monthLabel]: plannedMonth,
				}),
				{}
			);
			const key: string = Math.random().toString();

			const otherEventGridRow: OtherEventGridData = {
				...otherEventRow,
				otherEvent: {
					scenarioId: scenarioId,
					organizationId: organizationId,
					flexFieldFilter: otherEventRow.flexKey
				},
				isOpeningInOtherCurrency,
				rowType: RowType.PLANNED,
				key,
				currencyId: otherEventRow.columns[0].currencyId,
				currencyIsoCode,
				columns: cloneDeep(plannedMonthsObject),
			};
			updateRowTotals(otherEventGridRow);
			gridData.push(otherEventGridRow);
		})
	})

	return gridData.sort((a, b) => a.lastFlexFieldDescription.localeCompare(b.lastFlexFieldDescription));
}
