import { Button, Col, Divider, Row } from "antd";
import { ImageBox } from "components/imageBox/ImageBox";
import { INewGroupersTableProps } from "../IProjectionGroupers";
import i18n from "util/base/i18n";
import { useGroupersContext } from "./GroupersContext";
import { Icon } from "@iconify/react";

export default function NewGroupersTable({
	listToSave,
	handleDelete,
	handleEdit
}: INewGroupersTableProps) {
	const { getIndexerTypeDescription, indexerList } = useGroupersContext();
	return (
		<>
			<Divider className='title_divider' />
			{
				listToSave.length ?
					<div className="list-of-added-items">
						<div className="cost-category-list-header">
							<Row align={"middle"} gutter={7}>
								<Col span={6}>
									{i18n.t("external_code")}
								</Col>
								<Col span={6}>
									{i18n.t("description")}
								</Col>
								<Col span={5}>
									{i18n.t("projection_grouper.indexer_type")}
								</Col>
								<Col span={5}>
									{i18n.t("indexer")}
								</Col>
								<Col span={2}>
								</Col>
							</Row>
						</div>
						<div className="cost-category-list-content">
							{
								listToSave.map((grouper) => {
									const {
										description,
										externalCode,
										id,
										indexerId,
										indexerType
									} = grouper;
									return (
										<Row gutter={7} align={"middle"} key={id}>
											<Col span={6}>
												{description}
											</Col>
											<Col span={6}>
												{externalCode}
											</Col>
											<Col span={5}>
												{getIndexerTypeDescription[indexerType]}
											</Col>
											<Col span={5}>
												{indexerId &&
													indexerList.find(({ value }) => value === indexerId)?.label
												}
											</Col>
											<Col span={1} >
												<Button
													onClick={() => handleEdit(grouper)}
													icon={<Icon className="edit-button" icon="material-symbols:edit-sharp" />}
												/>
											</Col>
											<Col span={1} >
												<Button
													onClick={() => handleDelete(id)}
													icon={<Icon className="delete-icon" icon="fa6-solid:trash" />}
												/>

											</Col>
										</Row>
									)
								})
							}
						</div>
					</div>
					:
					< ImageBox />
			}
		</>
	)
}