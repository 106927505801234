import { Table } from "antd";
import i18n from "util/base/i18n";
import { memo } from "react";
import { OtherEventGridData, ColumnTypes, ViewModeSelected, totalSummary } from "../../../IOtherEvents";
import { createTableTotalsRow } from "../functions/createTableTotalsRow";
import { formatNumber } from "util/formatNumber";
import { usePermissionContext } from "context/PermissionContext";
import { FunctionalityPermissions } from "util/types/types";
import { useWindowSize } from "hooks/useWindowSize";
import { Loading } from "components/loading/Loading";

interface GridProps {
    isFetching: boolean;
    monthAmount: number;
    viewModeSelected: ViewModeSelected[];
    components: any; // replace with the correct type
    cols: any[];
    otherEventGridData: OtherEventGridData[];
    rowSelection: any; // replace with the correct type
}

export const Grid = memo(
    ({
        isFetching,
        monthAmount,
        viewModeSelected,
        components,
        cols,
        otherEventGridData,
        rowSelection
    }: GridProps) => {
        const { functionalityPermissions } = usePermissionContext();
        const { width } = useWindowSize();

        return (
            <Table
                loading={{
                    spinning: isFetching,
                    tip: `${i18n.t<string>("loading")}...`,
                    indicator: <Loading />
                }}
                className="gs-table"
                scroll={
                    monthAmount > 6 || viewModeSelected.length > 0 || width < 1350
                        ? { x: 1600, y: 580 }
                        : { x: true, y: 580 }
                }
                components={components}
                columns={cols as ColumnTypes}
                rowClassName={(record: OtherEventGridData) =>  "" }
                dataSource={otherEventGridData}
                bordered
                rowSelection={functionalityPermissions.edit ? rowSelection : null}
                summary={(data: OtherEventGridData[]) =>
                    Summary(
                        data,
                        viewModeSelected,
                        functionalityPermissions
                    )
                }
                pagination={{
                    showSizeChanger: true,
                    pageSizeOptions: [10, 20, 50],
                    hideOnSinglePage: otherEventGridData?.length < 10 ? true : false,
                }}
            />
        );
    }
);

function Summary(
    data: OtherEventGridData[],
    viewModeSelected: ViewModeSelected[],
    functionalityPermissions: FunctionalityPermissions
) {
    if (data.length === 0) return null;
    const totals: totalSummary[] = createTableTotalsRow(data, viewModeSelected);
    const isEditable = functionalityPermissions.edit;

    return (
        <Table.Summary fixed>
            <Table.Summary.Row style={{ background: "#FBE6E6" }}>
                {isEditable ? <Table.Summary.Cell index={0} /> : null}
                <Table.Summary.Cell index={isEditable ? 1 : 0} align="left">
                    {i18n.t("total")}
                </Table.Summary.Cell>
                {totals.map((value, index) => (
                    <Table.Summary.Cell
                        align="center"
                        key={value.month + index}
                        index={index + (isEditable ? 2 : 1)}
                    >
                        {formatNumber(value.value, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                    </Table.Summary.Cell>
                ))}
                <Table.Summary.Cell
                    index={totals.length + (isEditable ? 2 : 1)}
                />
            </Table.Summary.Row>
        </Table.Summary>
    );
}
