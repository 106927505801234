import { Button, Steps } from "antd";
import { useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { useProjectionModelingContext } from "../context/ProjectionModelingContext";
import { IPeriodColumn, IPeriodValue, IProjection } from "../IProjectionModeling";
import StepOne from "./stepOne/StepOne";
import StepThree from "./stepThree/StepThree";
import StepTwo from "./stepTwo/StepTwo";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import { Notification } from "components/notification/Notification";
import { formatProjectionFormula } from "../functions/handleProjection";
import { useUserContext } from "context/UserContext";
import { handleErrorRequest } from "util/functions/handleErrorRequest";

export default function NewProjection() {
	const [currentStep, setCurrentStep] = useState<number>(0);

	const [periodColumns, setPeriodColumns] = useState<IPeriodColumn[]>([]);
	const [periodValues, setPeriodValues] = useState<IPeriodValue[]>([]);
	const isNextButtonDisabled = false;
	const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
	const { userInfo: { selection: { organizationId } } } = useUserContext();
	const {
        form,
        setSelectedRowKeys,
        setSelectedRows,
        setStepOneListConditions,
        setFormula,
        setIsEditingProjection,
        setIsNewProjection,
        setIsGridFetching,
        stepOneListConditions,
        selectedRowKeys,
        isEditingProjection,
        isWhole,
        formula,
        setProjectionsOptions,
        isNewProjection,
        projectionToSave,
        setProjectionToSave,
        scheduleDays,
        setFieldList,
        getTableData,
        itemForEdit,
        setScheduleDays,
    } = useProjectionModelingContext();

	const steps = [
		{
			key: "step-one",
			title: i18n.t<string>("human_resources.initial_information"),
			stepNumber: 1,
			stepIndex: 0,
			disabled: false,
		},
		{
			key: "step-two",
			title: i18n.t<string>("term_and_operation"),
			stepNumber: 2,
			stepIndex: 1,
			disabled: isNextButtonDisabled,
		},
		{
			key: "step-three",
			title: i18n.t<string>("competition_and_cash"),
			stepNumber: 3,
			stepIndex: 2,
			disabled: isNextButtonDisabled,
		},
	];

	useEffect(() => {
        setScheduleDays(itemForEdit?.scheduleDays ? itemForEdit.scheduleDays : 0);
    }, [itemForEdit]);

	function onCancel() {
		setSelectedRowKeys([]);
		setSelectedRows([]);
		setStepOneListConditions([]);
		setFormula({ projectionId: null, expressions: [] });
		setIsEditingProjection(false);
		setIsNewProjection(false);
		setProjectionToSave(null);
		setCurrentStep(0);
		setFieldList([]);
		form.resetFields();
	}

	const stepsContents: Record<number, JSX.Element> = {
		0: <StepOne
			key={"step-one"}
			setPeriodColumns={setPeriodColumns}
			setPeriodValues={setPeriodValues}
		/>,
		1: <StepTwo
			key={"step-two"}
		/>,
		2: <StepThree
			key={"step-three"}
			periodColumns={periodColumns}
			periodValues={periodValues}
			setPeriodValues={setPeriodValues}
		/>,
	}

	function onClickBackStep() {
		if (currentStep === 0) {
			onCancel();
		} else {
			form.setFieldsValue(projectionToSave);
			setCurrentStep((prev) => prev - 1);
		}
	}

	function onClickNextStep() {
		form.validateFields().then(() => {
			if (currentStep === 2) {
				form.validateFields().then(() => {
					handleSaveProjection();
					form.resetFields();
				});
			} else {
				setProjectionToSave(state => ({ ...state, ...form.getFieldsValue() }));
				setCurrentStep((prev) => prev + 1);
			}
		});

	}

	const onLoadProjection = (data: IProjection[]) => {
		if (!data) {
			return;
		}

		const updatedSelect = Object.values<IProjection>(data).map((projection: IProjection) => ({
			value: projection.id,
			label: projection.description,
		}));
		setProjectionsOptions(updatedSelect);
	};

	function onSaveProjection(data: IProjection) {
		if (data) {
			Notification({
				type: "success",
				message: i18n.t("successfully_saved"),
			});

			ServiceCaller.doRequest(
				{
					type: RequestType.GET,
					url: `/planning/projection?organization={organization}`,
				},
				onLoadProjection.bind(this)
			);
		}
		getTableData()
		onCancel();
	}

	function handleSaveProjection() {
		setIsSubmitButtonClicked(true);
		setIsGridFetching(true);
		const stepThreeValues = form.getFieldsValue();
		const allFormValues: Record<string, any> = { ...projectionToSave, ...stepThreeValues };

		const conditions = stepOneListConditions.map((item) => {
			return {
				id: item.id,
				flexFieldId: item.levels,
				flexFieldValueId: item.levelsValue,
				condition: item.operation,
			};
		});

		const data = {
            id: isEditingProjection ? selectedRowKeys[0] : null,
            organizationId: organizationId,
            description: allFormValues.description,
            event: allFormValues.event,
            origin: allFormValues.origin,
            formula: formatProjectionFormula(formula, allFormValues.event),
            order: allFormValues.calculationOrder,
            effectiveStartDate: allFormValues.initialDate.format("YYYY-MM-DD"),
            effectiveEndDate: allFormValues.endDate.format("YYYY-MM-DD"),
            currencyId: allFormValues.currency,
            percentualBase: allFormValues.base,
            cashCreditAccountingAccountId: allFormValues.cashCreditAcc,
            cashDebitAccountingAccountId: allFormValues.cashDebitAcc,
            cashCreditCostCenterId: allFormValues.cashCreditCc,
            cashDebitCostCenterId: allFormValues.cashDebitCc,
            accrualCreditAccountingAccountId: allFormValues.competenceCreditAcc,
            accrualDebitAccountingAccountId: allFormValues.competenceDebitAcc,
            accrualCreditCostCenterId: allFormValues.competenceCreditCc,
            accrualDebitCostCenterId: allFormValues.competenceDebitCc,
            scheduleDays: scheduleDays,
            schedulePayment: isWhole === true ? "WHOLE" : "INSTALLMENT",
            flexFieldsConditions: conditions,
            ledgerId: allFormValues.ledgerId,
            grouperId: allFormValues.grouper,
            projectionCopyType: allFormValues.projectionCopyType,
            indexerId: !allFormValues.isIndexerGrouper ? allFormValues.indexerId : null,
            indexerType: !allFormValues.isIndexerGrouper ? allFormValues.indexerType : null,
        };

		ServiceCaller.doRequest(
            {
                type: isEditingProjection ? RequestType.PUT : RequestType.POST,
                url: `/planning/projection?organization={organization}`,
                params: data,
            },
            (response) => {
                onSaveProjection(response);
                setIsSubmitButtonClicked(false);
            },
            (error) => {
				setIsSubmitButtonClicked(true);

				if (error) {
					handleErrorRequest(error);
				}
            }
        );
	}

	return (
		<>
			{
				isNewProjection &&
				<div className="new-projection-page">
					<div className="page-title-content">
						<h1>{"Modelagem Projeção"}</h1>
							<Steps type="navigation" size="small" current={currentStep} className="site-navigation-steps"
							>
							{steps.map(({ stepIndex, stepNumber, title, disabled }) => {
								return (
									<Steps.Step
										disabled={disabled}
										key={stepIndex}
										title={title}
										stepNumber={stepNumber}
										stepIndex={stepIndex}
									/>
								);
							})}
						</Steps>
					</div>
					<div className="button-steps">
						<Button type="link" onClick={onClickBackStep}>
							{i18n.t(currentStep === 0 ? "cancel" : "back")}
						</Button>
						<Button
							type="primary"
							className="gs-main-button"
							disabled={isNextButtonDisabled || isSubmitButtonClicked}
							onClick={onClickNextStep}
						>
							{i18n.t(currentStep + 1 < steps.length ? "next" : "conclude")}
						</Button>
					</div>
						<main className="projection-main">{stepsContents[currentStep]}</main>
				</div>
			}
		</>
	);
}
