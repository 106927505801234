import { ColumnsType, TablePaginationConfig } from "antd/lib/table";
import { Notification } from "components/notification/Notification";
import { useUserContext } from "context/UserContext";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { queryClient } from "util/queryClient";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { FlexFieldSimpleBean, FlexFilter, Options } from "util/types/types";
import { BudgetOpeningCachedData } from "../../fixedExpense/IFixedExpense";
import { buildFlexField } from "../function/buildFlexField";
import { AccountingOpeningTableColumnBean, ColumnGrid, IAccountingLedgerContext, ITableDataAccountingLedger, ITableDataResponse } from "../IAccountingLedger";

import useCachedQuery from "hooks/useCachedQuery";
import i18n from "util/base/i18n";
import moment from "moment";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { GenericSelection } from "../../flexField/IFlexField";
import buildGridData from "../function/buildGridData";
import { buildColumnDescription, buildNewColumns } from "../function/buildNewColumns";
import { defaultColumnsTable } from "../mock/defaultColumns";
import { IOptionsForSearch } from "components/input/search/IMultipleSearch";
import { useUserCurrency } from "hooks/useUserCurrency";

export const AccountingLedgerContext = createContext<IAccountingLedgerContext>(
    {} as IAccountingLedgerContext
);

export function AccountingLedgerProvider({ children }) {
    const { userInfo } = useUserContext();
    const [period, setPeriod] = useState<[moment.Moment, moment.Moment]>([null, null]);
    const [costCenter, setCostCenter] = useState<Options[]>([]);
    const [spendingPackage, setSpendingPackage] = useState<Options[]>([]);
    const [grouperAccount, setGrouperAccount] = useState([]);
    const [flexFieldsFilters, setFlexFieldsFilters] = useState<FlexFilter[]>([]);
    const [isFetchingFlexFields, setIsFetchingFlexFields] = useState<boolean>(true);
    const [accountAccounting, setAccountAccounting] = useState<Options[]>([]);
    const [isAllFilterChecked, setIsAllFilterChecked] = useState<boolean>(false);
    const [tableData, setTableData] = useState<ITableDataAccountingLedger[]>([]);
    const [columns, setColumns] = useState<ColumnsType<any>>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentPageSize, setCurrentPageSize] = useState<number>(20);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [searchType, setSearchType] = useState<string>("business_unit");
    const [flexFieldsSelected, setFlexFieldsSelected] = useState([]);
    const [groupColumns, setGroupColumns] = useState<ColumnGrid[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [flexFieldDetails, setFlexFieldDetails] = useState<FlexFieldSimpleBean[]>([]);
    const [flexFieldValueDetailSelected, setFlexFieldValueDetailSelected] = useState<GenericSelection[]>([]);
    const [allColumns, setAllColumns] = useState<AccountingOpeningTableColumnBean>({} as AccountingOpeningTableColumnBean);
    const [isLastRefreshTableReorder, setIsLastRefreshTableReorder] = useState<boolean>(false);
    const [totalEntryValue, setTotalEntryValue] = useState<number>(0);
    const [isReorder, setIsReorder] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<any>("");
    const [isMultipleSearch, setIsMultipleSearch] = useState<boolean>(false);
    const [businessUnit, setBusinessUnit] = useState<Options[]>([]);
    const [isExport, setIsExport] = useState<boolean>(false);
    const { data: currency } = useUserCurrency(userInfo.currencyId, { enabled: !!userInfo.id });

    const costCenterId: number[] = costCenter.map((cc) => cc.value);

    const [searchValues, setSearchValues] = useState({
        value: "",
        organization: "",
        operating_unit: "",
        accounting_account: "",
        release_date: "",
    });

    const setSearchValue = (type, value) => {
        setSearchValues((prevValues) => ({
            ...prevValues,
            [type]: value,
        }));
    };

    const { data: costCenterOptions } = useCachedQuery<Options[]>(
        ["cost-center-options"],
        {
            type: RequestType.GET,
            url: `/expenses/cost-center?client={client}&locale={locale}&user={user}&organization={organization}&bu={businessUnit}`,
        },
        {
            enabled: true,
        },
        (data: { id: number; name: string; lastSelected: boolean }[]) => {
            return data.map((item) => ({ label: item.name, value: item.id, lastSelected: item.lastSelected }));
        }
    );

    const { data: spendingPackageOptions } = useCachedQuery<Options[]>(
        ["spending-package-options"],
        {
            type: RequestType.GET,
            url: `/budget-base/account-hierarchy/find-all-spending-packages-by-client?clientId=${userInfo.clientId}`,
        },
        { enabled: !!userInfo.clientId },
        (data: { id: number; description: string }[]) => {
            return data.map((item) => ({
                label: item.description, value: item.id
            }));
        }
    );

    const { data: grouperAccountOptions } = useCachedQuery<Options[]>(
        ["grouper-account-options", costCenter[0]?.value.toString()],
        {
            type: RequestType.GET,
            url: `/budget-base/account-hierarchy/find-last-childrens?&businessUnit={businessUnit}&client={client}&locale={locale}&user={user}&organization={organization}`,
        },
        {
            enabled: true,
        },
        (data: any[]) => {
            return data.map((item) => ({ label: `${item.externalCode} - ${item.description}`, value: item.id }));
        }
    );

    const { data: accountAccountingOptions } = useCachedQuery<Options[]>(
        ["account-accounting-options"],
        {
            type: RequestType.GET,
            url: `/expenses/accounting-account/find-all-in-classifications?locale={locale}&client={client}&user={user}&organization={organization}&bu={businessUnit}${costCenterId.toString().length > 0 ? `&costCenter=${costCenterId}` : ''}&classifications=3,4`,
        },
        {},
        (data: { id: number; name: string; lastSelected: boolean, canEdit: boolean }[]) => {
            return data.map((item) => ({ label: item.name, value: item.id, lastSelected: item.lastSelected, canEdit: item.canEdit }));
        }
    );
    const defaultSearchOptions: IOptionsForSearch[] = [
        {
            description: "business_unit",
            i18nString: "operating_unit"
        },
        {
            description: "cost_center",
            i18nString: "cost_center"
        },
        {
            description: "accounting_account",
            i18nString: "accounting_account"
        },
        {
            description: "creation_date",
            i18nString: "release_date"
        },
        {
            description: "launch_code",
            i18nString: "launch_code"
        },
        {
            description: "launch",
            i18nString: "launch"
        },
        {
            description: "batch",
            i18nString: "batch"
        },
        {
            description: "invoice",
            i18nString: "invoice"
        },
        {
            description: "serie",
            i18nString: "serie"
        },
        {
            description: "product_group",
            i18nString: "product_group"
        },
    ];

    useEffect(() => {
        getFlexFieldFilters();
        getFlexFieldValueDetails();
        loadColumns();
    }, []);

    const pagination: TablePaginationConfig = {
        current: currentPage,
        pageSize: currentPageSize,
        onChange(
            page: number,
        
            pageSize: number) {
            setCurrentPage(page);
            
            setCurrentPageSize(pageSize);
            onLoadAccountingLedger(isLastRefreshTableReorder, isExport, isMultipleSearch, getValueInput(inputValue), searchType, inputValue, page -1, pageSize);
        },
        total: totalItems,
        pageSizeOptions: [20,100, 200, 500, 1000],
        hideOnSinglePage: false,
        showSizeChanger: true,
        defaultPageSize: 15,
    }

    function getFlexFieldValueDetails() {
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: '/budget-base/flex-field/find-all-details?module=EXPENSES'
        }, doLoadFlexFieldDetails, handleErrorRequest);
    }

    function doLoadFlexFieldDetails(data: FlexFieldSimpleBean[]): void {
        setFlexFieldDetails(data.map((flex) => {
            return { value: flex.id, label: flex.description, flexFieldValues: flex.flexFieldValues };
        }));
    }

    function handleSearchType(type: string) {
        setSearchType(type);
    }

    async function onLoadFlexField(data: any[], budgetOpeningCachedData?: BudgetOpeningCachedData) {
        setFlexFieldsFilters(buildFlexField(data, userInfo, budgetOpeningCachedData));
        setIsFetchingFlexFields(false);
    }

    async function getFlexFieldFilters(budgetOpeningCachedData?: BudgetOpeningCachedData) {
        let flexfields: any = queryClient.getQueryData(["all-flex-field-by-module", "accountingLedger"], {});
        if (!flexfields) {
            flexfields = await ServiceCaller.doAsyncRequest({
                type: RequestType.GET,
                url: "/expenses/flex-field-opening?user={user}",
            });
            queryClient.setQueryData(["all-flex-field-by-module", "accountingLedger"], flexfields, {});
        }

        onLoadFlexField(flexfields.data, budgetOpeningCachedData);
    }

    function loadColumns(): void {
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: '/expenses/accounting-opening/find-grid-columns'
        }, setAllColumns, handleErrorRequest);
    }

    function handleChangePeriod(period) {
        setPeriod(period);
    }
    function handleInputValue(value: any) {
        setInputValue(value);
    }

    function onLoadTableData({ items, accountHierarchySizeColumns, costCenterHierarchySizeColumns, flexFieldValueColumns, sizeBeforeReorder, totalValue }: ITableDataResponse, isReorderNow: boolean = false) {
        if (items.content.length === 0) {
            Notification({ type: "info", message: i18n.t("no_data_accounting_ledger") });
        }

        const newData: ITableDataAccountingLedger[] = items.content.map((item) => buildGridData(item, isReorderNow));
        const newColumns: ColumnGrid[] =
            buildNewColumns(accountHierarchySizeColumns, costCenterHierarchySizeColumns, flexFieldValueColumns, allColumns);

        const defaultColumns: ColumnGrid[] = defaultColumnsTable(i18n, allColumns);
        let finalColumns: ColumnGrid[] = [...defaultColumns, ...newColumns];
        if (isReorderNow) {
            finalColumns = [...buildColumnDescription(), ...defaultColumns, ...newColumns];
            setTotalItems(sizeBeforeReorder);
        }

        setColumns(finalColumns);
        if (!isReorderNow) {
            setTotalItems(items.totalElements);
            setGroupColumns(finalColumns.map(column => ({ ...column, checked: true })))
        }
        setTotalEntryValue(totalValue);
        setTableData(newData);
        setIsLoading(false);
        setTableData(newData);
    }

    function getValueInput(date: string) {
        if (date) {
            return date;
        } else if (inputValue) {
            return inputValue;
        } else {
            return null;
        }
    }

    function getCommonParams(isExportNow: boolean, isReorder: boolean = false, isMultipleSearchNow: boolean = false, date: string = null, filterValue: string = null) {
        return {
            userId: userInfo.id,
            costCenterIds: costCenterId,
            businessUnitId: businessUnit?.length > 0 ? businessUnit.map((bu) => bu.value) : [],
            organizationId: userInfo.selection.organizationId,
            accountingAccountIds: accountAccounting.map((acc) => acc.value),
            clientId: userInfo.clientId,
            localeId: userInfo.localeId,
            period: period[0].format("YYYY-MM"),
            spendingPackageIds: spendingPackage.map((sp) => sp.value),
            accountHierarchyIds: grouperAccount.filter((acc) => acc.selectedOption?.length > 0).map((acc) => acc.selectedOption[0].value),
            flexFields: flexFieldsSelected.map(({filterId: flexFieldId, value}) => {
                return {
                    [flexFieldId]: (value?.filter((value) => value !== 0).map(({value}) => value) || []),
                }
            }).reduce((prevValue, newValue) => {
                return { ...prevValue, ...newValue };
            }, {}),
            isMultipleSearch: isMultipleSearchNow,
            isReorder: isReorder,
            orders: groupColumns && groupColumns.length > 0 ? groupColumns.filter((column) => column.checked).map((column) => column.key) : null,
            flexFieldValueDetailsIds: flexFieldValueDetailSelected.map((detail) => Number(detail.value)),
            isExport: isExportNow,
            fileName: i18n.t('accounting_ledger') + ".csv",
            filterValue: isMultipleSearchNow ? getValueInput(date) : null,
            searchType: isMultipleSearchNow && searchType ? searchType.toUpperCase() : null
        };
    }

    function handleExport() {
        const bodyParams = getCommonParams(true, isReorder, isMultipleSearch, getValueInput(inputValue));

        ServiceCaller.doRequest(
            {
                type: RequestType.POST,
                url: `/expenses/accounting-opening/export/is-running?clientId={client}&userId={user}`,
                params: bodyParams,
            },
            (response: boolean) => {
                if (!response) {
                    ServiceCaller.doRequest(
                        {
                            type: RequestType.POST,
                            url: `/expenses/accounting-opening/export`,
                            params: bodyParams,
                        },
                        () => {
                            Notification({
                                type: "info",
                                message: i18n.t("exporting_accounting_ledger"),
                                duration: 5,
                            });
                        },
                        handleErrorRequest
                    );

                } else {
                    Notification({
                        type: "warning",
                        message: i18n.t("export_already_running"),
                        duration: 5,
                    });
                }
            },
        );
    }

    function onLoadAccountingLedger(
        isReorderNow: boolean = false,
        isExportNow: boolean = false,
        isMultipleSearchNow: boolean = false,
        dateValue: string = null,
        searchType: string = null,
        filterValue: string = null,
        pageIndex: number = currentPage - 1,
        pageSize: number = currentPageSize
    ) {
        setTableData([]);
        setIsLoading(true);
        setIsLastRefreshTableReorder(isReorderNow);
        setIsReorder(isReorderNow);
        setIsMultipleSearch(isMultipleSearchNow);
        setIsExport(isExportNow);
        const hasFilters: boolean = !!(dateValue || searchType) && isMultipleSearchNow;
        
        if (dateValue) {
            setInputValue(dateValue);
        }

        if (pageSize) {
            setCurrentPageSize(pageSize);
        }

        const bodyParams = getCommonParams(isExportNow, isReorderNow, hasFilters, dateValue || inputValue, filterValue || inputValue);



        ServiceCaller.doRequest(
            {
                type: RequestType.POST,
                url: `/expenses/accounting-opening?page=${pageIndex}&size=${pageSize}`,
                params: bodyParams,
            },
            (data: ITableDataResponse) => {
                onLoadTableData(data, isReorderNow);
            },
            handleErrorRequest
        );
    }

    return (
        <AccountingLedgerContext.Provider value={{
            userInfo,
            period,
            setPeriod,
            handleChangePeriod,
            costCenter,
            setCostCenter,
            costCenterOptions,
            spendingPackage,
            setSpendingPackage,
            spendingPackageOptions,
            flexFieldsFilters,
            setFlexFieldsFilters,
            isFetchingFlexFields,
            setIsFetchingFlexFields,
            setAccountAccounting,
            accountAccounting,
            accountAccountingOptions,
            grouperAccount,
            setGrouperAccount,
            grouperAccountOptions,
            isAllFilterChecked,
            setIsAllFilterChecked,
            tableData,
            setTableData,
            columns,
            setColumns,
            pagination,
            searchType,
            handleSearchType,
            setSearchValue,
            searchValues,
            setFlexFieldsSelected,
            groupColumns,
            setGroupColumns,
            isLoading,
            setIsLoading,
            flexFieldDetails,
            setFlexFieldValueDetailSelected,
            flexFieldValueDetailSelected,
            businessUnit,
            setBusinessUnit,
            handleExport,
            totalEntryValue,
            isReorder,
            setIsReorder,
            inputValue,
            handleInputValue,
            onLoadAccountingLedger,
            isMultipleSearch,
            isExport,
            defaultSearchOptions,
            totalItems,
            currency,
            setCurrentPage,
            currentPage,
        }}>
            {children}
        </AccountingLedgerContext.Provider>
    );
}
export const useAccountingLedgerContext: () => IAccountingLedgerContext = () => {
    return useContext(AccountingLedgerContext);
}
