import { Icon } from "@iconify/react";
import { Button, Form, FormInstance, Input, InputRef, Tooltip } from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import { ResponseData, ResponseStatus, RevenueConstants, RevenueItemTable } from "../../IRevenue";
import { convertToNumber, getLocaleByIso } from "../../functions/Utils";

import { usePermissionContext } from "context/PermissionContext";
import { useUserContext } from "context/UserContext";

import moment from "moment";
import * as React from "react";

import i18n from "util/base/i18n";

import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { ErrorRequest } from "util/types/types";
import { RevenueContext } from "../../RevenueContext";

import { EditableContext } from '../RevenueTable';
import { Notification } from "components/notification/Notification";

export interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: string;
    record: RevenueItemTable;
    periodAccomplished: any;
    monthsColumns: any;
    updateTreeGrid: any;
    setHasSavedValue: any;
}

export const EditableCell: React.FC<EditableCellProps> = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    periodAccomplished,
    monthsColumns,
    updateTreeGrid,
    setHasSavedValue,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<InputRef>(null);
    const form: any = useContext(EditableContext);

    const { functionalityPermissions } = usePermissionContext();
    const { userInfo } = useUserContext();

    const { handleIsLoading } = useContext(RevenueContext);

    useEffect(() => {
        if (editing) {
            inputRef.current!.focus();
        }
    }, [editing]);

    const isTotalOrAccomplished = (row: RevenueItemTable, month: string) => {
        if (row?.key === "lineTotalRow") {
            return true;
        }
    
        if (row?.dates && periodAccomplished instanceof moment) {
            const now = moment(month);
            return now.isSameOrBefore(periodAccomplished);
        }
    
        return false;
    };

    let isAccomplished: boolean = isTotalOrAccomplished(record, dataIndex);
    let rowValue = record?.dates ? record.dates[dataIndex] : null;

    const toggleEdit = () => {
        const isCellAccomplishedOrHasCheckbox = isAccomplished;
        const hasEditPermissions = functionalityPermissions.edit;
        const hasNewPermissions = functionalityPermissions.new;
        const isThisCellNotEditable = isCellAccomplishedOrHasCheckbox && hasEditPermissions && hasNewPermissions;

        if (isThisCellNotEditable) {
            return;
        }

        setEditing(!editing);

        if (rowValue?.total || rowValue?.value) {
            form.setFieldValue(record.key + "-" + dataIndex, rowValue?.total?.value || rowValue?.value);
        }
    };

    const handleRedistribute = (row: RevenueItemTable, value: number, month: string) => {
        handleIsLoading(true);

        let itemIds: number[] = [];
        let flexFieldValueIds: number[];

        const isMeasuringUnitRow = row?.measuringUnitId ? true : false;

        if (isMeasuringUnitRow) {
            itemIds = row.ids || [row.id];
            flexFieldValueIds = row.children[0].flexFieldValueIds;
        } else {
            if (!row.total && !row.value) {
                flexFieldValueIds = getAnyMeasuringUnitRow(row).flexFieldValueIds;
            } else {
                flexFieldValueIds = row.flexFieldValueIds;
            }
        }

        ServiceCaller.doRequest({
            type: RequestType.POST,
            url: "/revenue/field/value/date/redistribute-values",
            params: {
                itemIds,
                value: value || 0,
                flexFieldValueIds,
                clientId: userInfo.clientId,
                organizationId: userInfo.selection.organizationId,
                scenarioId: userInfo.selection.scenarioId,
                monthDate: month,
                userId: userInfo.id,
                groupIds: userInfo.groupIds
            }
		}, ({ messageCode, status, }: ResponseData) => {
			if (status === ResponseStatus.WARNING) {
				Notification({
					message: i18n.t(`revenue.${messageCode}`),
					type: "warning"
				})
			}
            setHasSavedValue(true);
            updateTreeGrid(false);
		}, (err: ErrorRequest) => {
            handleErrorRequest(err);
            handleIsLoading(false);
        });
    };

    const getAnyMeasuringUnitRow = (rowReference: RevenueItemTable) => {
        let isMeasuringUnitRow: boolean = rowReference?.measuringUnitId ? true : false;
        let measuringUnitRow: RevenueItemTable = rowReference;

        while (!isMeasuringUnitRow) {
            measuringUnitRow = measuringUnitRow.children[0];
            isMeasuringUnitRow = measuringUnitRow?.measuringUnitId ? true : false;
        }

        return measuringUnitRow;
    }

    const handleSave = (row: RevenueItemTable, value: number, month: string) => {
        handleIsLoading(true);
        ServiceCaller.doRequest({
            type: RequestType.POST,
            url: `/revenue/field/value/date/`,
            params: {
                id: row.dates[month].id,
                fieldValueId: row.fieldValueId,
                flexFieldValueIds: row.flexFieldValueIds,
                scenarioId: userInfo.selection.scenarioId,
                organizationId: userInfo.selection.organizationId,
                clientId: userInfo.clientId,
                monthDate: month,
                fieldTemplate: row.idTemplate,
                value: value || 0
            }
        }, (() => {
            setHasSavedValue(true);
            updateTreeGrid(false);
        }), (err: ErrorRequest) => {
            handleErrorRequest(err);
            handleIsLoading(false);
        });
    };

    function doReplicateValues(row: RevenueItemTable, month: string) {
        handleIsLoading(true);
        let end: string = monthsColumns[monthsColumns.length - 1];
        let value: number = row.dates[month].value;
        let monthId: number = row.dates[month].id;

        ServiceCaller.doRequest({
            type: RequestType.POST,
            url: `/revenue/field/value/date/replicate-values`,
            params: {
                id: monthId,
                scenarioId: userInfo.selection.scenarioId,
                organizationId: userInfo.selection.organizationId,
                businessUnitId: userInfo.selection.businessUnitId,
                endPeriod: end,
                fieldTemplate: row.idTemplate,
                value: value
            }
        }, (() => {
            setHasSavedValue(true);
            updateTreeGrid(false);
        }), (err: ErrorRequest) => {
            handleErrorRequest(err);
            handleIsLoading(false);
        });
    }

    const save = async () => {
        const hasTemplateId = record?.idTemplate ? true : false;

        try {
            const values: { key: 'string' } = await form.validateFields();
            let newValue: number = parseFloat(convertToNumber(values[record.key + "-" + dataIndex]).toFixed(8));

            if ((rowValue?.total?.value || rowValue?.value) === newValue) {
                setEditing(!editing);
                return;
            }

            setEditing(!editing);

            if (!hasTemplateId) {
                handleRedistribute(record, newValue, dataIndex);
            } else {
                handleSave(record, newValue, dataIndex);
            }
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;

    if (record?.dates) {
        let typeRow: string = "";
        if (record.type) {
            typeRow = record.type;
        }
        let actual = record?.dates[dataIndex];
        let actualValue: number = actual?.total?.value || actual?.value || 0;

        let localeCoin = getLocaleByIso(record.isoCode);

        const modifyChildRecursively = (child: React.ReactNode): React.ReactNode => {
            if (React.isValidElement(child)) {
                if (child.props && child.props.children) {
                    const updatedChildren = React.Children.map(child.props.children, modifyChildRecursively);
                    return React.cloneElement(child, {
                        ...child.props,
                        children: updatedChildren
                    });
                }

                if (child.props.value !== undefined) {
                    return React.cloneElement(child, {
                        ...child.props,
                        value: parseFloat(child.props.value).toFixed(2),
                    });
                }
            }
            return child;
        };

        const updatedChildren = React.Children.map(children, (child, index) => {
            if (index === 1) {
                return modifyChildRecursively(child);
            }
            return child;
        });

        childNode = editing ? (
            <Form.Item style={{ margin: 0 }} name={record.key + "-" + dataIndex} initialValue={record.formatType === "DECIMAL"
                ? actualValue.toString().replace('.', ',')
                : actualValue.toLocaleString(localeCoin, { currency: record.isoCode, minimumFractionDigits: 2 }).replace('.', ',')}>
                <Input ref={inputRef} onPressEnter={save} onBlur={save} />
            </Form.Item >
        ) : (
            <>
                {!record.key.includes('lineBeforeCoin') ? (
                    <div className="revenue-icon-replicate-values"
                        style={{
                            height: 35
                        }}>
                        {typeRow !== RevenueConstants.CALCULATED ? (
                            <div className={!isAccomplished && record.idTemplate ? "editable-cell-value-wrap" : null} style={{ padding: 5, whiteSpace: 'nowrap' }} onClick={toggleEdit}>
                                {updatedChildren}
                            </div>
                        ) : (
                            <div style={{ color: "#424242", opacity: 0.6, paddingLeft: 5, paddingTop: 5, whiteSpace: 'nowrap' }}>
                                {updatedChildren}
                            </div>
                        )}

                        {functionalityPermissions["replicate"] && (
                            <>
                                {(typeRow !== RevenueConstants.CALCULATED && record.idTemplate && !isAccomplished) && (
                                    <Tooltip placement="topLeft" title={i18n.t<string>("replicate_to_next_months")}>
                                        <Button style={{ border: 'none', boxShadow: 'none', color: '#0065B3', background: 'none' }}
                                            icon={<Icon
                                                style={{ boxShadow: 'none', color: '#A6A7A7' }} icon={"material-symbols:content-copy"} />}
                                            onClick={() => doReplicateValues(record, dataIndex)} />
                                    </Tooltip>
                                )}
                            </>
                        )}
                    </div>
                ) : (
                    <div style={{ display: 'none' }} />
                )}

            </>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};