import { Col, DatePicker, Form, InputNumber, Modal, Row, Select } from "antd";
import { cloneDeep } from "lodash";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { selectProps } from "util/props/props";
import { useProjectionModelingContext } from "../../context/ProjectionModelingContext";
import { IFormulaModalFormValues } from "../../IProjectionModeling";
import { IFormModalProps } from "../../newProjection/stepTwo/IStepTwo";

interface ProjectionModelingModalProps {
    itemIndex: number;
    isModalVisible: boolean;
    handleCancel: () => void;
}
export default function ProjectionModelingModal({
    itemIndex,
    isModalVisible,
    handleCancel,
}: ProjectionModelingModalProps) {
    const [isSaving, setIsSaving] = useState(false);
    const [isProjection, setIsProjection] = useState<boolean>(false);
    const [isProjectedValue, setIsProjectedValue] = useState<boolean>(false);
    const [isTaxValue, setIsTaxValue] = useState<boolean>(false);
    const [formModal] = Form.useForm<IFormModalProps>();
    const { formula, setFormula, form, accountingList, costCenterList, projectionsOptions, listTax } =
        useProjectionModelingContext();
    const origin = Form.useWatch("origin", form);

    useEffect(() => {
        if (!formula) {
            return;
        }

        const expression = formula?.expressions.find((expression) => expression.position === itemIndex);
        const staticField: string = expression?.staticField?.toString() || "";

        let isProjection: boolean = ["INITIAL_VALUE", "DEBIT_VALUE", "CREDIT_VALUE", "FINAL_VALUE"].includes(staticField);

        setIsProjection(isProjection);
    }, [formula]);

    useEffect(() => {
        if (origin) {
            setIsProjectedValue(origin.toString() === "PROJECTION");
            setIsTaxValue(origin.toString() === "TAX");
        }
    }, [origin]);

    useEffect(() => {
        if (!isModalVisible) {
            return;
        }

        formModal.resetFields();

        const { projectionId, managementAccountingAccountId, rate, period, managementCostCenterId, modularFieldId } =
            formula?.expressions.find((expression) => expression.position === itemIndex) || null;
        if (rate) {
            if (isProjection || isProjectedValue) {
                formModal.setFieldsValue({
                    projectionId: isProjectedValue ? (projectionId ? projectionId : null) : null,
                    accountingId: isProjection
                        ? managementAccountingAccountId
                            ? managementAccountingAccountId
                            : null
                        : null,
                    costCenterId: isProjection ? (managementCostCenterId ? managementCostCenterId : null) : null,
                    acquisitionDate: period ? moment(period, "YYYY-MM") : null,
                    rate: rate,
                });
            } else if (isTaxValue) {
                formModal.setFieldsValue({
                    tax: modularFieldId || null,
                    rate: rate,
                });
            } else {
                formModal.setFieldsValue({
                    rate: rate,
                });
            }
        }
    }, [isModalVisible]);

    const validateDateFields = (acquisitionDate: Moment, activationDate: Moment) => {
        if (!activationDate) return Promise.resolve();
        if (acquisitionDate.isBefore(activationDate, "month") || acquisitionDate.isSame(activationDate, "month")) {
            return Promise.resolve();
        } else {
            return Promise.reject(new Error("A data de ativação não pode ser menor que a data do período"));
        }
    };

    const handleDateChange = (date: Moment | null) => {
        if (date && date.isValid()) {
            formModal.setFieldsValue({ rate: 100 });
        }
    };

    function onFinish({
        projectionId,
        accountingId,
        acquisitionDate,
        costCenterId,
        rate,
        tax,
    }: IFormulaModalFormValues): void {
        setIsSaving(true);

        const auxFormula = cloneDeep(formula);
        const existingExpressionIndex = auxFormula?.expressions.findIndex(
            (expression) => expression.position === itemIndex
        );
        const expression = auxFormula?.expressions.find((expression) => expression.position === itemIndex);
        const period: string = acquisitionDate?.format("YYYY-MM");

        if (existingExpressionIndex !== -1) {
            expression.rate = rate;
            expression.projectionId = projectionId;
            expression.managementAccountingAccountId = accountingId;
            expression.managementCostCenterId = costCenterId;
            expression.period = period;
            expression.modularFieldId = tax || null;
        } else {
            const newExpression = {
                position: itemIndex,
                staticField: "",
                fieldType: null,
                operator: "",
                value: null,
                modularFieldId: null,
                scenarioId: null,
                organizationId: null,
                rate: Number(rate),
                projectionId: projectionId || formula.expressions[0].projectionId,
                managementAccountingAccountId: accountingId,
                managementCostCenterId: costCenterId,
                period: period,
                formulaExpression: { id: "", name: "", type: "field" as "field" },
            };
            auxFormula.expressions.push(newExpression);
        }
        setFormula(auxFormula);
        setIsSaving(false);
        handleCancel();
    }

    return (
        <Modal
            width={600}
            okButtonProps={{
                htmlType: "submit",
                form: "projection-modeling",
                loading: isSaving,
            }}
            title={"Projeção de Modelagem"}
            open={isModalVisible}
            onCancel={() => {
                handleCancel();
                formModal.resetFields();
            }}
            className="gs-modal account-registration-modal"
            cancelText={i18n.t<string>("cancel")}
            okText={i18n.t<string>("save")}
            destroyOnClose={true}
        >
            <Form form={formModal} layout="vertical" name="projection-modeling" onFinish={onFinish}>
                <div>
                    {isProjectedValue && (
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="projectionId"
                                    label={"Projeção"}
                                    rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                                >
                                    <Select
                                        options={projectionsOptions}
                                        showSearch
                                        placeholder={i18n.t<string>("select")}
                                        allowClear
                                        {...selectProps}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    )}
                    {isProjection && (
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    name="accountingId"
                                    label={"Conta Contábil Gerencial"}
                                    rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                                >
                                    <Select
                                        options={accountingList}
                                        placeholder={i18n.t<string>("select")}
                                        allowClear
                                        {...selectProps}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item name="costCenterId" label={"Centro de Custo Gerencial"}>
                                    <Select
                                        options={costCenterList}
                                        placeholder={i18n.t<string>("select")}
                                        allowClear
                                        {...selectProps}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    )}

                    <Row gutter={24}>
                        {(isProjection || isProjectedValue) && (
                            <Col span={12}>
                                <Form.Item
                                    name="acquisitionDate"
                                    label={"Período"}
                                    rules={[
                                        { required: !isProjectedValue, message: i18n.t<string>("required_field") },
                                        ({ getFieldValue }) => ({
                                            validator(_, value: Moment) {
                                                return validateDateFields(value, getFieldValue("activationDate"));
                                            },
                                        }),
                                    ]}
                                >
                                    <DatePicker
                                        className="gs-date-picker"
                                        picker={"month"}
                                        format={"MM/YYYY"}
                                        onChange={handleDateChange}
                                    />
                                </Form.Item>
                            </Col>
                        )}

                        {isTaxValue && (
                            <Col span={12}>
                                <Form.Item
                                    name="tax"
                                    label={"Imposto"}
                                    rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                                >
                                    <Select
                                        options={listTax}
                                        showSearch
                                        placeholder={i18n.t<string>("select")}
                                        allowClear
                                        {...selectProps}
                                    />
                                </Form.Item>
                            </Col>
                        )}

                        <Col span={12}>
                            <Form.Item
                                name="rate"
                                label={"Taxa (%)"}
                                rules={[
                                    { required: true, message: i18n.t<string>("required_field") },
                                    () => ({
                                        validator(_, value: any) {
                                            if (value > 100) {
                                                return Promise.reject(
                                                    new Error("A taxa não pode ser maior do que 100%")
                                                );
                                            }

                                            if (value === 0) {
                                                return Promise.reject(
                                                    new Error("A taxa deve ser maior que 0% para gerar valores")
                                                );
                                            }

                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                                initialValue={100}
                            >
                                <InputNumber
                                    className="tax input-order"
                                    style={{ width: "100%" }}
                                    placeholder={"Taxa"}
                                    min={0}
                                    max={100}
                                    controls={false}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            </Form>
        </Modal>
    );
}
