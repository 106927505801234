import i18n from "util/base/i18n";
import { useProjectionModelingContext } from "../context/ProjectionModelingContext";

export default function ProjectionTitle() {
    const { isNewProjection } = useProjectionModelingContext();

    return (
        <>
            {!isNewProjection && (
                <div className="projection-title-content">
                    <div className="page-title-content">
                        <h1>{i18n.t("projection_modeling")}</h1>
                    </div>
                </div>
            )}
        </>
    );
}
