import { Icon } from "@iconify/react";
import { Button, Checkbox, DatePicker, Divider, Popover, Radio, Space, Spin, Tooltip } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import moment from "moment";
import { Key, useCallback, useEffect, useRef, useState } from "react";
import StringUtil from "util/StringUtil";
import i18n, { languages } from "util/base/i18n";
import { Options } from "util/types/types";
import { FilterHeader } from "./FilterHeader";
import { IFilterBase } from "./IFilter";
import useOptionFetch from "./hook/useOptionFetch";
import "./styles.sass";

const { RangePicker } = DatePicker;
const CheckboxGroup = Checkbox.Group;

export function FilterBase({
    id,
    value,
    setValue,
    type,
    title,
    searchPlaceholder,
    hasSearch = true,
    popoverStyles,
    buttonStyles,
    headerStyles,
    isFetching,
    allFilterOptions,
    hasPagination,
    datepicker,
    hasSelectNone,
    defaultCheckboxSelected,
    footer,
    disabled = false,
}: IFilterBase) {
    const [search, setSearch] = useState("");
    const [optionAmount, setOptionAmount] = useState(100);
    const [checkedList, setCheckedList] = useState<Options[]>([]);
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false);
    const [radioValue, setRadioValue] = useState<Key>("");
    const [isPopoverVisible, setIsPopoverVisible] = useState(false);
    const [isScrollbarVisible, setIsScrollbarVisible] = useState<boolean>(false);
    const { optionsFromFetch, hasMore } = useOptionFetch(
        search,
        optionAmount,
        allFilterOptions,
        hasSelectNone,
        isScrollbarVisible
    );
    const radioGroupRef = useRef(null);
    const observer = useRef(null);

    useEffect(() => {
        const filteredOptions = search
            ? allFilterOptions?.filter((option) => option?.label?.toLowerCase().includes(search.toLowerCase())) ?? []
            : hasPagination
            ? allFilterOptions ?? []
            : optionsFromFetch ?? [];

        const isCheckedAll: boolean =
            filteredOptions.length > 0 && value?.length > 0 && filteredOptions.length === value.length;
        setCheckAll(isCheckedAll);

        if (isCheckedAll) {
            setIndeterminate(false);
        } else {
            const isInderminate: boolean = !!value?.filter((flexField) => flexField?.value !== 0)?.length;
            setIndeterminate(isInderminate);
        }
    }, [allFilterOptions, optionsFromFetch, value, hasPagination, search]);

    useEffect(() => {
        if (footer && footer.footerValue && footer.footerValue.itens.find((item) => item.value === search)) {
            setSearch("");
        }
    }, [footer && footer.callbackFooterWithSearchInputValue]);

    useEffect(() => {
        if (isPopoverVisible && radioValue !== "") {
            isScrollVisible(radioValue);
        }
    }, [isPopoverVisible]);

    useEffect(() => {
        if (type === "checkbox") {
            setCheckedList(value);
        }
        if (type === "radio") {
            setCheckedList(value);
            if (value.length > 0 && value[0]) {
                setRadioValue(value[0].value);
            }
        }
    }, [value]);

    useEffect(() => {
        if (!defaultCheckboxSelected) return;
        setCheckedList(allFilterOptions.filter((option) => option.checked && !checkedList.includes(option)));
        if (defaultCheckboxSelected.length === optionsFromFetch.length + 1) setCheckAll(true);
    }, [optionsFromFetch]);

    const lastOptionElementRef = useCallback(
        (node) => {
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasMore) {
                    setOptionAmount((prevOptionAmount) => prevOptionAmount + 100);
                }
            });
            if (node) observer.current.observe(node);
        },
        [hasMore]
    );

    const onCheckAllChange = (e: CheckboxChangeEvent) => {
        const filteredOptions = search
            ? allFilterOptions.filter((option) => option.label.toLowerCase().includes(search.toLowerCase()))
            : hasPagination
            ? allFilterOptions
            : optionsFromFetch;

        setCheckedList(e.target.checked ? filteredOptions : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };

    const onChangeCheckbox = (list: Key[]) => {
        const filteredOptions = search
            ? allFilterOptions.filter((option) => option.label.toLowerCase().includes(search.toLowerCase()))
            : hasPagination
            ? allFilterOptions
            : optionsFromFetch;

        const currentCheckedList = filteredOptions.filter((item) => checkedList.some((i) => i.value === item.value));
        let updatedCheckedList = [];

        if (currentCheckedList.length > list.length) {
            const itemToRemove = currentCheckedList.find((item) => !list.includes(item.value));
            updatedCheckedList = checkedList.filter((item) => item.value !== itemToRemove.value);
        } else {
            updatedCheckedList = [
                ...checkedList,
                ...filteredOptions.filter((item) => list.includes(item.value)),
            ].reduce((acc, val) => {
                if (val.value !== 0 && !acc.some((item) => item.value === val.value)) {
                    acc.push(val);
                }
                return acc;
            }, []);
        }
        setCheckedList(updatedCheckedList);
        setIndeterminate(!!list.length && list.length < filteredOptions.length);
        setCheckAll(list.length === filteredOptions.length);
    };

    const moveItemToTop = (array: Options[], value: string | number): Options[] => {
        if (hasSelectNone) {
            const index = array.findIndex((item) => item.value === 0);

            if (index !== -1) {
                const item = array.splice(index, 1)[0];
                array.unshift(item);
            }
        }

        const index = array.findIndex((item) => item.value === value);
        if (index !== -1) {
            const item = array.splice(index, 1)[0];
            array.unshift(item);
        }

        return array;
    };

    const onChangeRadio = (e) => {
        const selectedValue: number = e.target.value;

        optionsFromFetch.map((option) => (option.isSelected = option.value === selectedValue));

        const radioSelected = optionsFromFetch.filter((item) => item.value === selectedValue);
        setCheckedList(radioSelected);

        setRadioValue(selectedValue);

        isScrollVisible(selectedValue);
    };

    const isScrollVisible = (selectedValue) => {
        const { clientHeight, scrollHeight } = radioGroupRef.current || {};
        if (!clientHeight || !scrollHeight) return;

        const scrollbarVisible: boolean = scrollHeight > clientHeight;

        setIsScrollbarVisible(scrollbarVisible);

        optionsFromFetch.map((option) => (option.isSelected = option.value === selectedValue));

        if (scrollbarVisible) {
            moveItemToTop(optionsFromFetch, selectedValue);
        }
    };

    function onChangeDate(date: moment.Moment) {
        setValue([date]);
    }

    function onChangeRangeDate(date: [moment.Moment, moment.Moment]) {
        setValue([...date]);
    }

    function onOpenPopover(isOpen: boolean) {
        if (!isOpen) {
            setIsPopoverVisible(false);
            setValue(checkedList);
            if (hasPagination) {
                setOptionAmount(100);
            }
        }
    }

    const checkboxList = (
        <div style={popoverStyles} className="filter-list-container">
            {isFetching ? (
                <Spin spinning={isFetching} tip={"Buscando dados..."} />
            ) : (
                <>
                    <FilterHeader
                        headerStyles={headerStyles}
                        type={type}
                        hasSearch={hasSearch}
                        searchPlaceholder={searchPlaceholder}
                        setSearch={setSearch}
                        indeterminate={indeterminate}
                        checkAll={checkAll}
                        onCheckAllChange={onCheckAllChange}
                        showCheckAll={optionsFromFetch.length > 1}
                    />
                    {optionsFromFetch.length > 0 ? (
                        <CheckboxGroup
                            className="filter-list"
                            value={checkedList.map((item) => item?.value)}
                            onChange={onChangeCheckbox}
                        >
                            {optionsFromFetch.map((value, index) => {
                                if (optionsFromFetch.length === index + 1) {
                                    return (
                                        <div ref={lastOptionElementRef}>
                                            <Checkbox key={value.value} value={value.value}>
                                                {value.label}
                                            </Checkbox>
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div>
                                            <Checkbox key={value.value} value={value.value}>
                                                {value.label}
                                            </Checkbox>
                                        </div>
                                    );
                                }
                            })}
                        </CheckboxGroup>
                    ) : (
                        <>
                            <p style={{ fontSize: 14, textAlign: "center", margin: "15px auto 5px" }}>
                                {i18n.t("no_data_found")}
                            </p>
                            {footer && (
                                <>
                                    <Divider />
                                    <div style={footer.style ? footer.style : null}>
                                        <>
                                            <Button
                                                style={{
                                                    border: "none",
                                                    boxShadow: "none",
                                                }}
                                                icon={
                                                    <Icon
                                                        icon={footer.iconFooter.toString()}
                                                        onClick={(e) =>
                                                            footer.setCallbackFooterWithSearchInputValue({
                                                                footerProps: footer.footerValue,
                                                                value: search,
                                                            })
                                                        }
                                                    ></Icon>
                                                }
                                            />
                                            {footer.titleFooter}
                                        </>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </>
            )}
        </div>
    );

    const radioList = (
        <div style={popoverStyles} className="filter-list-container">
            {isFetching ? (
                <Spin spinning={isFetching} tip={"Buscando dados..."} />
            ) : (
                <>
                    <FilterHeader
                        headerStyles={headerStyles}
                        type={type}
                        hasSearch={hasSearch}
                        searchPlaceholder={searchPlaceholder}
                        setSearch={setSearch}
                        indeterminate={indeterminate}
                        checkAll={checkAll}
                        onCheckAllChange={onCheckAllChange}
                        showCheckAll={optionsFromFetch.length > 1}
                    />
                    <Radio.Group
                        className="filter-list"
                        ref={radioGroupRef}
                        onChange={onChangeRadio}
                        value={radioValue}
                    >
                        {optionsFromFetch.length > 0 ? (
                            optionsFromFetch.map((value, index) => {
                                if (optionsFromFetch.length === index + 1) {
                                    return (
                                        <div ref={lastOptionElementRef}>
                                            <Radio key={value.value.toString()} value={value.value}>
                                                {value.label}
                                            </Radio>
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div key={value.value.toString()}>
                                            <Radio value={value.value} checked={index === 0 && !radioValue}>
                                                {value.label}
                                            </Radio>

                                            {index === 0 && isScrollbarVisible && (
                                                <hr style={{ margin: "8px auto 4px", borderTop: "1px solid #ccc" }} />
                                            )}
                                        </div>
                                    );
                                }
                            })
                        ) : (
                            <p style={{ fontSize: 14, textAlign: "center", margin: "15px auto 5px" }}>
                                {i18n.t("no_data_found")}
                            </p>
                        )}
                    </Radio.Group>
                </>
            )}
        </div>
    );
    if (type === "datepicker") {
        function disabledPeriod(current: moment.Moment) {
            if (!datepicker.period) {
                return false;
            }
            return !datepicker.period.some((period) => {
                return period[0].startOf("year") < current && current < period[1].endOf("year");
            });
        }

        function disabledDate(current: moment.Moment) {
            if (!datepicker.period) {
                return false;
            }
            let isDisable = false;
            switch (datepicker.picker) {
                case "month":
                    isDisable = !datepicker.period[0].some((period) => current.month() === period.month());
                    break;
                case "year":
                    isDisable = !datepicker.period[0].some((period) => current.year() === period.year());
                    break;
                default:
                    isDisable = !datepicker.period[0].some((period) => current.isSame(period));
                    break;
            }
            return isDisable;
        }

        return (
            <div
                style={buttonStyles}
                className="open-filter-button"
                onClick={() => setIsPopoverVisible((state) => !state)}
            >
                <Space direction="vertical" size={0}>
                    <p className="filter-name">{StringUtil.capitalizeFirstLetter(title)}</p>
                    {datepicker.rangePicker ? (
                        <RangePicker
                            suffixIcon={null}
                            onChange={onChangeRangeDate}
                            value={value as [moment.Moment, moment.Moment]}
                            format={datepicker.format}
                            picker={datepicker.picker}
                            disabledDate={disabledPeriod}
                            locale={languages[i18n.language]}
                            allowClear={datepicker.allowClear ? true : false}
                        />
                    ) : (
                        <DatePicker
                            defaultValue={datepicker.defaultToday ? moment() : null}
                            onChange={onChangeDate}
                            disabledDate={disabledDate}
                            locale={languages[i18n.language]}
                            format={datepicker.format}
                            open={isPopoverVisible}
                            suffixIcon={null}
                            picker={datepicker.picker}
                            allowClear={datepicker.allowClear ? true : false}
                        />
                    )}
                </Space>
                <span></span>
            </div>
        );
    }

    return (
        <Popover
            id={`budget-container-${id}`}
            visible={isPopoverVisible}
            onVisibleChange={onOpenPopover}
            trigger="click"
            placement="bottom"
            content={type === "checkbox" ? checkboxList : radioList}
        >
            <div
                style={buttonStyles}
                className={`open-filter-button ${disabled ? "disabled-button" : ""}`}
                onClick={() => {
                    if (disabled) {
                        setIsPopoverVisible(false);
                        setRadioValue("");
                        return;
                    }
                    setIsPopoverVisible((state) => !state);
                }}
            >
                <Space direction="vertical" size={2}>
                    <Space>
                        <p className="filter-name">{StringUtil.capitalizeFirstLetter(title)}</p>
                        {isFetching ? <Spin spinning={isFetching} size="small" /> : null}
                    </Space>
                    <div className="filter-values-container">
                        {checkedList.length === 1 && checkedList[0] && checkedList[0].value !== 0 ? (
                            <p className="current-filter-value">{checkedList[0].label}</p>
                        ) : checkedList.length > 1 ? (
                            <>
                                <p className="current-filter-value">{checkedList[0].label}</p>
                                <Tooltip
                                    color={"rgba(0,0,0,0.9"}
                                    placement="right"
                                    title={checkedList.map((item, index) => {
                                        if (index === 0) return null;
                                        return (
                                            <p key={item.value} style={{ margin: 0 }}>
                                                {item.label}
                                            </p>
                                        );
                                    })}
                                >
                                    <span className="additional-items">{`+${checkedList.length - 1}`}</span>
                                </Tooltip>
                            </>
                        ) : (
                            <p className="current-filter-value"></p>
                        )}
                    </div>
                </Space>
                <Icon className="open-filter-icon" icon="akar-icons:chevron-down" />
            </div>
        </Popover>
    );
}