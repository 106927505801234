import { Input, Table } from "antd";
import { IValuesTableProps } from "../IModalFilter";
import { ColumnsType } from "antd/lib/table";
import i18n from "util/base/i18n";
import { Key, useEffect, useState } from "react";
import { TableRowSelection } from "antd/lib/table/interface";
import StringUtil from "util/StringUtil";
import { cloneDeep } from "lodash";
import { ICurrencyFilter } from "module/budget/pages/revenue/reportView/components/Filters/IFilters";
const { Search } = Input;
const { getWithoutSpecials } = StringUtil;
export default function ValuesTable({
	data,
	selectedObject,
	setSelectedObject,
	flexFieldSelected
}: IValuesTableProps) {
	const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
	const [dataFiltered, setDataFiltered] = useState<ICurrencyFilter[]>([]);
	const [searchValue, setSearchValue] = useState<string>("");
	const rowSelection: TableRowSelection<ICurrencyFilter> = { selectedRowKeys, onChange };
	const columns: ColumnsType<ICurrencyFilter> = [
		{
			title: i18n.t("external_code"),
			dataIndex: "externalCode",
			key: "externalCode"
		},
		{
			title: i18n.t("description"),
			dataIndex: "label",
			key: "label"
		}
	];

	function onChange(keys: number[]) {
		const updatedObject = cloneDeep(selectedObject)
		updatedObject[flexFieldSelected] = keys.map(key => Number(key));
		setSelectedObject(updatedObject)
	}

	function handleSearch(e) {
		const value: string = e.target.value;
		setSearchValue(value);
		let filteredData: ICurrencyFilter[] = [];
		filteredData = data.filter(item => {
			const labelMatch = getWithoutSpecials(item.label).includes(getWithoutSpecials(value));
			const codeMatch = getWithoutSpecials(item.externalCode).includes(getWithoutSpecials(value));
			return labelMatch || codeMatch;
		});
		setDataFiltered(filteredData);
	}

	useEffect(() => {
		setDataFiltered(data);
	}, [data])

	useEffect(() => {
		if (!selectedObject) return;
		const newKeys: number[] = selectedObject[flexFieldSelected];
		setSelectedRowKeys(newKeys);
		setSearchValue("");
	}, [flexFieldSelected, selectedObject])

	return (
		<div className="values-table-content">
			<Search
				placeholder={i18n.t('search')}
				onChange={handleSearch}
				value={searchValue}
			/>
			<Table
				className="gs-table"
				bordered
				columns={columns}
				dataSource={dataFiltered}
				rowKey={"value"}
				rowSelection={rowSelection}
				pagination={{
					hideOnSinglePage: true
				}}
				scroll={{
					y: "200px"
				}}
			/>
		</div>
	)
}
