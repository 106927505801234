import { TopButtons } from "components/topButtons/TopButtons";
import { IApportionmentListSection } from "../../IApportionment";
import { Filters } from "./components/Filters";
import { ApportionmentList } from "./components/ApportionmentList";
import { IImportExportMenu, IImportModal } from "components/importExportMenu/IImportExportMenu";
import i18n from "util/base/i18n";

export function ApportionmentListSection({
    apportionmentList,
    onClickNewApportionment,
    businessUnitOptions,
    costCenterOptions,
    flexFieldsOriginOptions,
    isFetchingOriginAccountOptions,
    originAccountOptions,
    setFlexFieldsOriginOptions,
    setIsFetchingOriginAccountOptions,
    setOriginAccountOptions,
    onOpenApportionmentInfo,
    isFetchingApportionments,
    fetchApportionments,
    openCalendarPeriod,
    selectedRowKeys,
    setSelectedRowKeys,
    onDelete,
}: IApportionmentListSection) {

    const importProps: IImportExportMenu = {
        buttonType: "3dots",
        importProps: [{
            importUrl: "/expenses/apportionment/import",
            templateUrl: "/expenses/apportionment/template?user={user}&client={client}&locale={locale}",
            type: "excel",
            title: i18n.t("apportionment_import"),
            hasImportOption: true,
        }]
    }

    return (
        <div className="gs-flex align-center flex-col w-full">
            <TopButtons
                mainButtonTitle="Nova Alocação"
                handleNew={() => onClickNewApportionment()}
                handleDelete={onDelete}
                isDeletable={selectedRowKeys.length > 0}
                importExportOptions={importProps}
                //handleSearch={() => { }}
            />
            <Filters
                openCalendarPeriod={openCalendarPeriod}
                businessUnitOptions={businessUnitOptions}
                costCenterOptions={costCenterOptions}
                flexFieldsOriginOptions={flexFieldsOriginOptions}
                isFetchingOriginAccountOptions={isFetchingOriginAccountOptions}
                originAccountOptions={originAccountOptions}
                setFlexFieldsOriginOptions={setFlexFieldsOriginOptions}
                setIsFetchingOriginAccountOptions={setIsFetchingOriginAccountOptions}
                setOriginAccountOptions={setOriginAccountOptions}
                fetchApportionments={fetchApportionments}
            />

            <ApportionmentList
                apportionmentList={apportionmentList}
                onOpenApportionmentInfo={onOpenApportionmentInfo}
                isFetchingApportionments={isFetchingApportionments}
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
            />
        </div>
    );
}
